import logo from '../images/logo.png'
import map_logo from '../images/map2.png'
import entance_logo from '../images/entrance_logo.jpeg'
import no_data_logo from '../images/no_data.png'
import support_us from '../images/support_us.png'
import procedure from '../images/procedure.webp'
import interior from '../images/interior.png'
import how_we_different from '../images/how_we_different.png'
import { TbBuildingSkyscraper } from "react-icons/tb";
import { GoProjectSymlink } from "react-icons/go";
import { FiUsers } from "react-icons/fi";
import { RiInputField } from "react-icons/ri";

// other business banner 
import real_estate_banner from '../images/other_business/real_estate.avif'
import gallery_logo1 from '../images/gallery_logo.png'


import cert1 from '../images/cert/cert1.jpg'
import cert2 from '../images/cert/cert2.jpg'
import cert3 from '../images/cert/cert3.jpg'
import cert4 from '../images/cert/cert4.jpg'


// other business service icon 
import speedyProcess from '../images/other_business_step/SpeedyProcess.png'
import dedicatedExpert from '../images/other_business_step/Dedicatedexpert.png'
import dynamicDatabase from '../images/other_business_step/Dynamicdatabase.png'
import clearCommunication from '../images/other_business_step/ClearCommunication.png'
import resumeDatabase from '../images/other_business_step/80k.png'
import emergingIndividual from '../images/other_business_step/EmergingIndividuals.png'
import fastestGrowing from '../images/other_business_step/FastestGrowing.png'
import serviceOffered from '../images/other_business_step/ServicesOfferred.png'

// bg 
import tree from '../images/tree1.svg'
import leaf from '../images/red_leaf.svg'

// flow 1 
import wedesign from '../images/flow1/wedesign.svg'
import webudget from '../images/flow1/budget.svg'
import weplan from '../images/flow1/plan.svg'
import wemanage from '../images/flow1/weManage.svg'
import weexecute from '../images/flow1/weExecute.png'

// service icon
import s1ico from '../images/service_icon/s1ico.png'
import s2ico from '../images/service_icon/s2ico.png'
import s3ico from '../images/service_icon/s3ico.png'
import s4ico from '../images/service_icon/s4ico.png'

// service banners
import service1 from '../images/services/service1.jpeg'
import service2 from '../images/services/service2.jpg'
import service3 from '../images/services/service3.jpg'
import service4 from '../images/services/service4.jpg'

// service main 1
import service5 from '../images/services/service5.jpg'
import service6 from '../images/services/service6.jpg'
import service7 from '../images/services/service7.jpg'
import service8 from '../images/services/service8.jpg'
import service9 from '../images/services/service9.jpg'

// service main 2
import service10 from '../images/services/service10.jpg'
import service11 from '../images/services/service11.jpg'
import service12 from '../images/services/service12.jpeg'
import service13 from '../images/services/service13.webp'

// belief
import transparency from '../images/belief/transparency.png'
import dedicated from '../images/belief/dedication.png'
import experts from '../images/belief/experts.png'
import quality from '../images/belief/quality.png'

// banners 
import banner1 from '../images/banner/b1.jpeg'
import banner2 from '../images/banner/b2.jpg'
import banner3 from '../images/banner/b3.jpg'
import banner4 from '../images/banner/b4.jpg'
import banner5 from '../images/banner/b5.jpg'
import banner6 from '../images/banner/b6.jpg'
import banner7 from '../images/banner/b7.jpg'

// actions 
import industry_experts from '../images/flow/industry_experts.png'
import dedicated_training from '../images/flow/dedicated_training.png'
import timely_action from '../images/flow/timely_action.png'

// clients
import Image2 from '../images/clients/2_Image.jpg' 
import Image3 from '../images/clients/3_Image.jpg' 
import Image5 from '../images/clients/5_Image.jpg' 
import Image7 from '../images/clients/7_Image.jpg' 
import Image8 from '../images/clients/8_Image.jpg' 
import Image9 from '../images/clients/9_Image.jpg' 
import Image10 from '../images/clients/10_Image.jpg' 
import Image11 from '../images/clients/11_Image.jpg' 
import Image13 from '../images/clients/13_Image.jpg' 
import Image14 from '../images/clients/14_Image.jpg' 
import Image15 from '../images/clients/15_Image.jpg' 
import Image16 from '../images/clients/16_Image.jpg' 
import Image17 from '../images/clients/17_Image.jpg' 
import Image18 from '../images/clients/18_Image.jpg' 
import Image20 from '../images/clients/20_Image.jpg' 
import Image21 from '../images/clients/21_Image.jpg' 
import Image22 from '../images/clients/22_Image.jpg' 
import Image23 from '../images/clients/23_Image.jpg' 
import Image24 from '../images/clients/24_Image.jpg' 
import Image25 from '../images/clients/25_Image.jpg' 
import Image26 from '../images/clients/26_Image.jpg' 
import Image27 from '../images/clients/27_Image.jpg' 
import Image28 from '../images/clients/28_Image.jpg' 
import Image29 from '../images/clients/29_Image.jpg' 
import Image30 from '../images/clients/30_Image.jpg' 
import Image31 from '../images/clients/31_Image.jpg' 
import Image32 from '../images/clients/32_Image.jpg' 
import Image33 from '../images/clients/33_Image.jpg' 
import Image34 from '../images/clients/34_Image.jpg' 
import Image35 from '../images/clients/35_Image.jpg' 
import Image36 from '../images/clients/36_Image.jpg' 
import Image37 from '../images/clients/37_Image.jpg' 
import Image38 from '../images/clients/38_Image.jpg' 
import Image39 from '../images/clients/39_Image.jpg' 
import Image40 from '../images/clients/40_Image.jpg' 
import Image41 from '../images/clients/41_Image.jpg' 
import Image42 from '../images/clients/42_Image.jpg' 
import Image43 from '../images/clients/43_Image.jpg' 
import Image44 from '../images/clients/44_Image.jpg' 
import Image45 from '../images/clients/45_Image.jpg' 
import Image46 from '../images/clients/46_Image.jpg' 
import Image47 from '../images/clients/47_Image.jpg' 
import Image48 from '../images/clients/48_Image.jpg' 
import Image49 from '../images/clients/49_Image.jpg' 
import Image50 from '../images/clients/50_Image.jpg' 
import Image51 from '../images/clients/51_Image.jpg' 
import Image52 from '../images/clients/52_Image.jpg' 
import Image53 from '../images/clients/53_Image.jpg' 
import Image54 from '../images/clients/54_Image.jpg' 
import Image55 from '../images/clients/55_Image.jpg' 
import Image56 from '../images/clients/56_Image.jpg' 
import Image57 from '../images/clients/57_Image.jpg' 
import Image58 from '../images/clients/58_Image.jpg' 
import Image60 from '../images/clients/60_Image.jpg' 
import Image61 from '../images/clients/61_Image.jpg' 
import Image62 from '../images/clients/62_Image.jpg' 
import Image63 from '../images/clients/63_Image.jpg' 
import Image64 from '../images/clients/64_Image.jpg' 
import Image65 from '../images/clients/65_Image.jpg' 
import Image66 from '../images/clients/66_Image.webp' 
import Image67 from '../images/clients/67_Image.png' 
import Image68 from '../images/clients/68_Image.jpeg' 
import Image69 from '../images/clients/69_Image.png' 
import Image70 from '../images/clients/70_Image.png' 
import Image71 from '../images/clients/71_Image.webp' 
import Image72 from '../images/clients/72_Image.svg' 
import Image74 from '../images/clients/74_Image.png' 
import Image75 from '../images/clients/75_Image.png' 

// teams
// import TeamImage1 from '../images/team/1_Image.jpg'
// import TeamImage2 from '../images/team/2_Image.jpg'
// import TeamImage3 from '../images/team/3_Image.jpg'
// import TeamImage4 from '../images/team/4_Image.jpg'
// import TeamImage5 from '../images/team/5_Image.jpg'
// import TeamImage6 from '../images/team/6_Image.jpg'
// import TeamImage7 from '../images/team/8_Image.jpg'
// import TeamImage8 from '../images/team/11_Image.jpg'
// import TeamImage9 from '../images/team/14_Image.jpg'
// import TeamImage10 from '../images/team/17_Image.jpg'
// import TeamImage11 from '../images/team/18_Image.jpg'

import TeamImage1 from '../images/team/1_Image.jpg'
import TeamImage2 from '../images/team/21_Image.jpg'
import TeamImage3 from '../images/team/31_Image.png'
import TeamImage4 from '../images/team/411_Image.jpg'
import TeamImage5 from '../images/team/51_Image.jpg'
import TeamImage6 from '../images/team/61_Image.jpg'
import TeamImage7 from '../images/team/71_Image.jpg'
import TeamImage8 from '../images/team/81_Image.jpg'
import TeamImage9 from '../images/team/911_Image.jpg'
import TeamImage10 from '../images/team/101_Image.jpg'
import TeamImage11 from '../images/team/18_Image.jpg'
import TeamImage12 from '../images/team/111_Image.jpg'


import fprojectBanner from '../images/fprojects.jpg'
import fprojectBanner1 from '../images/about_right.jpg'
import fprojectBannerGif from '../images/3d_model.gif'


import aboutBottom from '../images/contact_us_about.svg'
import call1 from '../images/call1.webp'
import call2 from '../images/call2.webp'

export const industrySectionArr = [
    {   
        icon:TbBuildingSkyscraper,
        value:150,
        label:'Clients Served',
        description:'(Interior Design & MEP Consultation)'
    },
    {
        icon:GoProjectSymlink,
        value:2.6,
        label:'Million Sqft',
        description:'(worth 1,060,797 SQ FT)'
    },
    {
        icon:FiUsers,
        value:80,
        label:'Employees',
        description:'(Fidelitus Corp)'
    },
    {
        icon:RiInputField,
        value:4,
        label:'In the Industry',
        description:''
    }
]



export const teamArr = [
    {
        name:'Achuth Gowda',
        designation:'Founder & Managing Director',
        img:TeamImage1,
        linked_in_link:'https://www.linkedin.com/in/achuth-gowda-mrics-88b9a26/'
    },
    {
        name:'Malthesh Sigase',
        designation:'Director - Projects',
        img:TeamImage2,
        linked_in_link:'https://www.linkedin.com/in/malthesh-sigase-121601168/'
    },
    {
        name:'Paniraj H V',
        designation:'Director - Projects',
        // img:TeamImage3,
        linked_in_link:'https://www.linkedin.com/in/paniraj-gowda-halige-b40b5916/'
    },
    {
        name:'Adarsh H S',
        designation:'Director - Projects',
        img:TeamImage4,
        linked_in_link:'https://www.linkedin.com/in/adarsh-hs-80b75524/'
    },
    {
        name:'Sanjana Singh',
        designation:'Chief Relationship Officer',
        img:TeamImage5,
        linked_in_link:'https://www.linkedin.com/in/sanjanaa-siingh-05752831/'
    },
    {
        name:'Ashray H C Gowda',
        designation:'Asst Manager - Client Relations',
        img:TeamImage6,
        linked_in_link:'https://www.linkedin.com/in/ashray-gowda-huigere-59962754/'
    },
    {
        name:'Chanchal Chaddha',
        designation:'Sr. Architect',
        img:TeamImage7,
        linked_in_link:'https://www.linkedin.com/in/chanchal-chaddha-77194a188/'
    },
    {
        name:'Pratheeksha C R',
        designation:'Architect',
        img:TeamImage8,
        linked_in_link:'https://www.linkedin.com/in/pratheeksha-c-r-a55b9bb5/'
    },
   
    {
        name:'Venu Gopal K P',
        designation:'Manager - QS',
        img:TeamImage10,
        linked_in_link:'https://www.linkedin.com/in/venu-gopal-427ba0162/'
    },
    {
        name:'Venkat Reddy',
        designation:'General Manager - MEP',
        img:TeamImage12,
        linked_in_link:''
    },
    {
        name:'Thilak M Biradar',
        designation:'Manager - Procurement',
        img:TeamImage9,
        linked_in_link:'https://www.linkedin.com/in/thilak-biradar-b1534618a/'
    },
    {
        name:'Gowtham',
        designation:'VP Projects',
        // img:TeamImage12,
        linked_in_link:''

    },
    {
        name:'Somashekhara S M',
        designation:'Asst Project Manager',
        // img:TeamImage11,
        linked_in_link:''
    },
    {
        name:'Gyaneshwar',
        designation:'Asst Project Manager',
        // img:TeamImage11,
        linked_in_link:''
    },
  
  
  
]

export const clientsArr = [
    Image2,
    Image3,
    Image5,
    Image7,
    // Image8,
    Image9,
    Image10,
    Image11,
    Image13,
    Image14,
    Image15,
    Image16,
    Image17,
    Image18,
    Image20,
    Image21,
    Image22,
    Image23,
    Image24,
    Image25,
    Image26,
    Image27,
    Image28,
    Image29,
    Image30,
    Image31,
    Image32,
    Image33,
    Image34,
    Image35,
    Image36,
    Image37,
    Image38,
    Image39,
    Image40,
    Image41,
    Image42,
    Image43,
    Image44,
    Image45,
    Image46,
    Image47,
    Image48,
    Image49,
    Image50,
    Image51,
    Image52,
    Image53,
    Image54,
    Image55,
    Image56,
    Image57,
    Image58,
    Image60,
    Image61,
    Image62,
    Image63,
    Image64,
    Image65,
    Image66,
    Image67,
    Image68,
    Image69,
    Image70,
    Image71,
    Image72,
    Image74,
    Image75,
]

export const flowActionArr = [
    {
        img:industry_experts,
        heading:'Industry Experts',
        color:'#fff1f0',
        description:'With an experienced and highly skilled team of in-house architects, interior designers, MEP & civil engineers & also key project/account managers, we can ensure every project progresses smoothly in various locations in PAN India.'
    },
    {
        img:dedicated_training,
        heading:'Dedicated Manager',
        color:'#edffe8',
        description:'For every client of ours, we provide a dedicated team of in-house technical and civil engineers, key project managers, architects and interior designers in Bangalore, so that all our projects flow smoothly under trusted guidance.'
    },
    {
        img:timely_action,
        heading:'Timely Action',
        color:'#e8efff',
        description:'Time and money are important and we help you save both. To be one of India’s reputed IPC, we ensure settling all your projects on time and give you the best return on investment is what we believe in.'
    }
]

export const servicesArr = [
    {   
        id:1,
        label:'Commercial Interior/ Industrial Design and Architectural Services',
        image:s1ico,
        path:'/services/commercial-interior-design-and-architecture'
    },
    {
        id:2,
        label:'MEP / HVAC and Networking Services',
        image:s2ico,
        path:'/services/mep-hvac-and-networking'
    },
    {
        id:3,
        label:'Project Evaluation and Estiamtion',
        image:s3ico,
        path:'/services/project-evaluation-and-estimation'
    },
    {
        id:4,
        label:'Turnkey Project Execution and General Contracting Services',
        image:s4ico,
        path:'services/turnkey-project-execution-and-general-contracting'
    }
]

export const beliefArr = [
    {
        id:1,
        value:'Transparency',
        img:transparency
    },
    {
        id:2,
        value:'Dedicated Client Manager',
        img:dedicated
    },
    {
        id:3,
        value:'Experts on Board',
        img:experts
    },
    {
        id:4,
        value:'Commitment to Quality',
        img:quality
    }
]

export const flow1Arr = [
    {
        id:1,
        image:wedesign,
        heading:'We Design',
        description:'From completed office space to  home interior, and storage to decor, our top interior designers create spaces that match your vision.'
    },
    {
        id:2,
        image:webudget,
        heading:'We Budget',
        description:'From completed office space to  home interior, and storage to decor, our top interior designers create spaces that match your vision.'
    },
    {
        id:3,
        image:weplan,
        heading:'We Plan & Save',
        description:'From completed office space to  home interior, and storage to decor, our top interior designers create spaces that match your vision.'
    },
    {
        id:4,
        image:weexecute,
        heading:'We Execute',
        description:'We follow a meticulous planning approach with detail-driven designs for interiors of your homes.'
    },
    {
        id:5,
        image:wemanage,
        heading:'We Deliver',
        description:'Our top interior designers spearhead quality assurance by extending support after the execution of your site.'
    }
]

export const ourService  = [
    {
        id:1,
        name:'Commercial Interior / Industrial Design and Architectural Services',
        path:'/services/commercial-interior-design-and-architecture'
    },
    {
        id:2,
        name:'MEP / HVAC and Networking Services',
        path:'/services/mep-hvac-and-networking'
    },
    {
        id:3,
        name:'Project Evaluation and Estimation',
        path:'/services/project-evaluation-and-estimation'
    },
    {
        id:4,
        name:'Turnkey Project Execution and General Contracting Services',
        path:'/services/turnkey-project-execution-and-general-contracting'
    },
]

export const otherServiceArr = [
    {
        id:1,
        image:speedyProcess,
        heading:'Speedy Process',
        description:'With our vast experience in this industry, we assure timely service'
    },
    {
        id:3,
        image:dedicatedExpert,
        heading:'Dedicated expert',
        description:'An expert manager will be guiding you in every step for your requirements'
    },
    {
        id:3,
        image:dynamicDatabase,
        heading:'Dynamic database',
        description:'Your needs are met with the highest standards with our unmatched database'
    },
    {
        id:4,
        image:clearCommunication,
        heading:'Clear Communication',
        description:'The whole process will go smoothly, as we believe in crystal clear communication'
    }
]

export const otherService1Arr = [
    {
        id:1,
        image:resumeDatabase,
        heading:'Talent Acquisition & Management',
        description:'Permanent & Temporary Recruitment solutions for all kinds of industries'
    },
    {
        id:2,
        image:emergingIndividual,
        heading:'Learning & Development',
        description:'We provide a range of Learning & Developing programs at Institutional and Corporate levels'
    },
    {
        id:3,
        image:fastestGrowing,
        heading:'Organization Transformation',
        description:'Let us be your extended HR team to transform your business portfolio for the better'
    },
    {
        id:4,
        image:serviceOffered,
        heading:'Startup Labs',
        description:'Want to launch a successful business? Let us build your core system'
    }
]

export const trustedClientArr = [Image3,Image5,Image13,Image20,Image21,Image38]

export const bannerArr = [banner5,banner6,banner7,banner4]
export const bannerDescriptionArr = [
    {   
        id:1,
        heading:'Where Dimensions Take Shape',
        description:'Meet your dreams through innovation and design.',
        tag:'Fidelitus Projects',
        path:'/about',
        color:'white',
    },
    {
        id:2,
        heading:'Giving Meaning to a Piece of Land',
        description:'Detail Oriented & Contemporary Design/ Architecture Services.',
        tag:'Achitecture & Design',
        path:'/services/commercial-interior-design-and-architecture',
        color:'white',
    },
    {
        id:3,
        heading:'Optimise Your Financial Resources with Us',
        description:'Experienced financial mentors at your service.',
        tag:'Quantification & Budgeting',
        path:'/services/project-evaluation-and-estimation',
        color:'white'
    },
    {
        id:4,
        heading:'Best Interior Design with standard infrastructure',
        description:'Experienced interior design which create an unmatchable design.',
        tag:'Interior Design',
        path:'/services/project-evaluation-and-estimation',
        color:'white'
    }
]

export const appLogo = logo
export const mapLogo = map_logo
export const entranceLogo = entance_logo 
export const noDataLogo = no_data_logo
export const supportUs = support_us
export const brigadeTechPark = fprojectBanner
export const fprojectBannerData = fprojectBanner1
export const fprojectBannerZif = fprojectBannerGif
export const treeImg = tree
export const redLeafImg = leaf 
export const procedureImg = procedure
export const interiorImg = interior
export const howWeDifferent = how_we_different

export const realEstateBanner = real_estate_banner
export const aboutBottomImg = aboutBottom
export const call1Img = call1
export const call2Img = call2

export const certificatesArr = [cert1,cert2,cert3,cert4]

export const service1Img = service1
export const service2Img = service2
export const service3Img = service3
export const service4Img = service4
export const service5Img = service5
export const service6Img = service6
export const service7Img = service7
export const service8Img = service8
export const service9Img = service9
export const service10Img = service10
export const service11Img = service11
export const service12Img = service12
export const service13Img = service13

export const gallerylogo = gallery_logo1

export const address = 'Fidelitus Projects Private Limited,Brigade Software Park,B Block,West Wing,No 42,27th Cross,Bsk 2nd Stage,Bangalore - 560070'
export const mobile = '+91 9845040163'
export const mobile1 = '+91 9845073132'
export const department = 'Fidelitus Projects'

export const email = 'contact@fidelitusprojects.com'

export const base_url = 'https://fidelitustech.com/api/'
export const image_base_url = 'https://fidelitustech.com/'
export const image_base_url1 = 'https://fcplfmsbucket.s3.ap-south-1.amazonaws.com/'


export const transaction_website_url = "https://fidelitustransactions.com"
export const project_website_url = "https://fidelitusprojects.web.app/"
export const fms_website_url = "https://fidelitusfms.web.app/"
export const hrlabs_website_url = "https://fidelitushrlabs.com/"
export const fidelitus_gallery_website_url = "https://fidelitusgallery.com/"


export const face_book_link = 'https://www.facebook.com/Fidelitus/'
export const linked_in_link = 'https://in.linkedin.com/company/fidelituscorp-property-services-private-ltd'
export const you_tube_link = 'https://www.youtube.com/watch?v=y-3CAl8M9yA'
export const whatsapp_link = 'https://api.whatsapp.com/send?phone=919845062227&text=Hi I would like to connect with you'