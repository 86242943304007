import React from 'react'
import { address, email, mobile, mobile1, ourService, service11Img, service2Img, service6Img, } from '../constants/AppConstant'
import { useLocation, useNavigate } from 'react-router-dom'
import { MdPhoneCallback } from "react-icons/md";
import { BsMailbox2 } from "react-icons/bs";
import { FaLocationDot } from "react-icons/fa6";

function MEPHvac() {

  const {pathname} = useLocation()
  const navigator = useNavigate()

  return (
    <div>
        <div className='relative flex items-center justify-center bg-mep_bg bg-center bg-cover h-[200px]  md:h-[340px] 2xl:h-[500px]'>
            <div className='z-10'>
              <h6 className='text-[25px] md:text-[30px] 2xl:text-[35px] text-white text-center tracking-[10px] font_thicker opacity-80 2xl:text-[35px]'>MEP / HVAC and Networking Services</h6>
              <h6 className='text-center font_normal text-white opacity-100 text-[14px] 2xl:text-[18px]'> <span onClick={()=>navigator('/')} className='relative cursor-pointer after:bg-[#fff] after:absolute after:h-[2px] after:w-0 after:bottom-0 after:left-0  text-white opacity-100  hover:after:w-full after:transition-all after:duration-300'>Home</span> / MEP / HVAC Network Services </h6>
              <h6 className='text-[13px] mt-1  text-white text-center font_normal opacity-80 2xl:text-[16px]'>Would like to know more about our MEP / HVAC & Network Services. </h6>
            </div>
            <div className='bg-black opacity-60 absolute top-0 left-0 w-full h-[200px]  md:h-[340px] 2xl:h-[500px]'>
            </div> 
         </div>

        <div className='flex-col-reverse flex md:flex-row w-[90%] md:w-[80%] mx-auto py-[5%]'>

        <div className='md:w-[30%] mt-10 md:mt-0'>
          
          <div className='md:w-[90%] border border-slate-100 p-6'>
          <div className='border-b-[2px] pb-2 mb-5 border-slate-100'>
          <h6  data-aos="fade-right" className='font_bold text-[15px] 2xl:text-[18px]'>Our Services</h6>  
          </div>
          {ourService?.map((s)=>(
            <h6 data-aos-delay="300" data-aos="fade-bottom" onClick={()=>navigator(s?.path)} className={`font_bold cursor-pointer text-[15px] 2xl:text-[18px] my-2 p-4  ${s?.path === pathname ? 'bg-[#027d9b] text-white' : 'bg-slate-50'}`}>{s?.name} </h6>
          ))}
          </div>

          <div className='md:w-[90%] border border-slate-100 p-6 mt-5'>
          <div className='border-b-[2px] pb-2 mb-5 border-slate-100'>
          <h6  data-aos="fade-right" className='font_bold text-[15px] 2xl:text-[18px]'>Contact</h6>  
          </div>

          <div data-aos="fade-bottom" data-aos-delay="100" className='flex  bg-slate-50 my-2 p-4 items-start'>
            <MdPhoneCallback className='text-[#027d9b]' size={20} /> 
            <div className='ml-2'>
              <h6 className='font_bold text-[14px] 2xl:text-[17px]'>Phone Number</h6>
              <h6 className='font_normal text-[13px] 2xl:text-[16px]'>{mobile} / {mobile1}</h6>
            </div>
          </div>

          <div data-aos="fade-bottom" data-aos-delay="200" className='flex  bg-slate-50 my-2 p-4 items-start'>
            <BsMailbox2 className='text-[#027d9b]' size={20} /> 
            <div className='ml-2'>
              <h6 className='font_bold text-[14px] 2xl:text-[17px]'>Email Address</h6>
              <h6 className='font_normal text-[13px] 2xl:text-[16px]'>{email}</h6>
            </div>
          </div>

          <div data-aos="fade-bottom" data-aos-delay="300" className='flex  bg-slate-50 my-2 p-4 items-start'>
            <FaLocationDot className='text-[#027d9b]' size={24} /> 
            <div className='ml-2'>
              <h6 className='font_bold text-[14px] 2xl:text-[17px]'>Location</h6>
              <h6 className='font_normal text-[13px] 2xl:text-[16px]'>{address}</h6>
            </div>
          </div>
         
          </div>

        </div>
        <div className='md:w-[70%]'>
        <div data-aos="fade-left" className='w-[100%] md:h-[400px] overflow-hidden 2xl:h-[650px]'>
        <video autoPlay={true} loop controls={false} muted={true} src={`https://fidecrmfiles.s3.amazonaws.com/project_videos/mep_electrical.mp4`} />  
        {/* <img className='md:h-[400px] w-[100%] object-cover overflow-hidden hover:scale-110 duration-300 2xl:h-[650px]' src={service11Img} />     */}
        </div>
         <h6 data-aos="fade-bottom" className='text-[18px] 2xl:text-[21px] mt-5 font_bold'>MEP / HVAC and Networking Services</h6>
         
         <h6 data-aos="fade-bottom" className='font_normal text-[14px] 2xl:text-[17px] mb-2'>If you hear about HVAC contractors in Bangalore, you would probably know about MEP consultants. Still, to make it as clear as possible, HVAC stands for Heating, Ventilation, and Air Conditioning. Whereas, MEP stands for Mechanical, Electrical, and Plumbing, which makes HVAC solely a part of MEP. These services are the lifelines of any project â€“ whether for home interiors or warehouse construction â€“ it is essential they are developed with utmost accuracy and perfection to ensure the proper functioning of a facility.</h6> 
         <h6 data-aos="fade-bottom" className='font_normal text-[14px] 2xl:text-[17px] mb-2'>As mentioned, MEP stands for Mechanical, Electrical, and Plumbing. Therefore, it refers to such aspects of building design and construction. Our MEP engineers design and select these systems in a crucial way for planning, decision-making, accurate documentation, performance and cost estimation, construction, and operating or maintaining the resulting facilities.</h6> 
         <h6 data-aos="fade-bottom" className='font_normal text-[14px] 2xl:text-[17px] mb-2'>On the other hand, the focus of our HVAC contractors in Bangalore is to ensure thermal comfort and acceptable air quality for enclosed environments. Our HVAC engineers ensure that air temperature and quality are provided under conditions that are comfortable for people within the environment and suitable for the operation of the equipment within that area.</h6> 
         <h6 data-aos="fade-bottom" className='font_normal text-[14px] 2xl:text-[17px] mb-2'>Our engineering services ensure smooth functioning of architects, contractors, and building ownersâ€™ working processes. Our assistance lets your construction and renovation projects escalate their performance with minimized cost as your experts analyze the individual building system by considering the entire context of the project.</h6> 
         <h6 data-aos="fade-bottom" className='font_normal text-[14px] 2xl:text-[17px] mb-2'>We provide competent designs & methodical approaches by taking various factors into account, such as climate, building design, orientation, space usage, and zoning, to optimize energy utilization.</h6> 
         <h6 data-aos="fade-bottom" className='font_normal text-[14px] 2xl:text-[17px] mb-2'>Whether you have a new project or are looking to reconstruct an existing structure, we provide integrated solutions to all problems, including lighting, plumbing, firefighting, and engineering services. Our engineers are:</h6> 
         <ul data-aos="fade-bottom" className='list-disc ml-2'>
            <li className='font_normal text-[14px] 2xl:text-[17px] mb-2'>Extremely talented and experienced MEP Experts and Civil Engineers working for you</li>
            <li className='font_normal text-[14px] 2xl:text-[17px] mb-2'>Focused on minimizing costs and maximizing energy efficiency using the collaborative design process</li>
            <li className='font_normal text-[14px] 2xl:text-[17px] mb-2'>Customized MEP & HVAC services tailored to your needs</li>
         </ul>
         <h6 data-aos="fade-bottom" className='font_normal text-[14px] 2xl:text-[17px] mb-2'>At Fidelitus Projects, our MEP consultants and HVAC contractors in Bangalore provide innovative and reliable services to build a position of strength and prominence in our areas of operation. Visit Fidelitus Projects in Bangalore for a free consultation, or book a free consultation online to sit back, relax, and watch your dream home come to life</h6> 
   
         </div>
         </div>
    </div>
  )
}

export default MEPHvac