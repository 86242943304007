import React from 'react'
import {FiAlertCircle} from 'react-icons/fi'


function ErrorComponent({error}) {
  return (
    <>
    {(error !== null && error !== '') &&
    <div className='flex items-center pt-1'>
        <FiAlertCircle size={12} className='text-red-600' />
        <span className='text-red-600 text-[11px] 2xl:text-[14px] ml-1'>{error}</span>
    </div>}
    </>
  )
}

export default ErrorComponent