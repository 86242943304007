import React,{ useEffect, useState } from 'react'

import {  base_url } from '../constants/AppConstant'
import axios from 'axios'
import { HiOutlineLocationMarker } from "react-icons/hi";
import { MdOutlineDateRange } from "react-icons/md";
import { HiChevronRight } from "react-icons/hi";
import moment from 'moment'
import { useNavigate } from 'react-router-dom'


function Carrier() {

  const navigate =  useNavigate();
 

  const [datas,setdatas] = useState({})
  const [page,setpage] = useState(1)
  
  useEffect(()=>{
    getData()
  },[])

  async function getData(){
    const headers = {headers:{
      'Content-Type':'multipart/form-data'
    }}
    
    await axios.get(`${base_url}project_website/job_opening/get?step=1&page=${page}&text=`,headers)
    .then((res)=>{
      setdatas(res?.data)
    }).catch((err)=>{
      // console.log("err?.data",err?.response)
    })
  }

  console.log("data",datas)
 

  return (
    <div className="w-full min-h-screen relative z-0 w-screen">

{/* g-gradient-to-b from-[] to-[]  */}
        <div className='relative  overflow-hidden h-[200px]  md:h-[340px] grid place-items-center  bg-[#eee] bg-career_bg  bg-top  bg-cover bg-repeat 2xl:h-[500px]'>
      

        <div className='w-[100%] absolute opacity-50 z-10 h-[200px]  md:h-[340px] 2xl:h-[500px]  bg-black'>
        </div>
        <div  className='z-10'>
        <h1 className='text-[30px] text-white text-center tracking-[10px] font_thicker opacity-80 2xl:text-[35px]'>Career </h1>

        <h6 className='text-center tracking-wider z-30 text-gray-400 font_normal text-[14px] mb-2 2xl:text-[18px]'> <span onClick={()=>navigate('/')} className='text-white relative cursor-pointer after:bg-[#fff] after:absolute after:h-[1px] after:w-0 after:bottom-0 after:left-0  hover:after:w-full after:transition-all after:duration-300'>Home</span> / Career</h6>
        <h6 className='text-center tracking-wider z-30 text-[#fff]  font_normal text-[12px] 2xl:text-[16px]'>Be a part of Wonderful team for your career success</h6>
        </div>


        </div>


        <div className='w-[80vw] mx-auto py-10'>
          <div>
            <h6 className='font_bold text-[13px] 2xl:text-[16px]'>Our Active Jobs  ({datas?.pagination?.total})</h6>
          </div>

          <div className='grid grid-cols-1 md:grid-cols-3 mt-5 gap-4'>
            {datas?.datas?.map((d)=>(
              <div data-aos="fade-up" onClick={()=>navigate(`/career_detail`,{state:d})} key={d?._id} className='hover:scale-105 bg-white duration-200 border cursor-pointer p-[10px]'>
                <h6 className='font_bold text-[12px] text-orange-600 2xl:text-[16px] hover:underline cursor-pointer capitalize'>{d?.designation}</h6>
                <h6 className='font_normal mt-[5px] text-[13px] 2xl:text-[16px]'>No Of Opening : {d?.no_of_position}</h6>
                <div className='md:flex items-start justify-between  mt-2'>
                  <div className='flex flex-wrap items-center min-w-[50%] max-w-[50%]'>
                    <HiOutlineLocationMarker className='bg-slate-100 p-[2px] rounded-full ' />
                    {d?.location?.split(':')?.map((d1,i)=><span key={i} className='bg-slate-100 text-[10px] 2xl:text-[13px] capitalize mb-[2px] font_normal font-[600] ml-[10px] p-[2px] rounded'> {d1}</span>)}
                  </div>  

                  <div className='flex mt-1 md:mt-0 items-center'>
                    <MdOutlineDateRange className='bg-slate-100 p-[2px] rounded-full ' />
                    <span className='text-[12px] 2xl:text-[15px] ml-1 font-[600] font_normal'> Updated : {moment(d?.createdAt)?.format('ll')} </span>
                  </div> 
                </div> 
                <div className='mt-2'>
                <h6 className='font_normal text-[14px] line-clamp-2'><span className='font-[700] font_bold'>Job Description : </span> {d?.job_description}</h6>
                </div>

                <div className='border-t mt-2 h-[20px]'>
                  <span className='flex items-center text-blue-600 text-[12px] 2xl:text-[15px] py-[2px]'>
                    <h6>Read More</h6>
                    <HiChevronRight size={20} />
                  </span>  
                
                </div>  
              </div>  
            ))}
             
          </div>

        </div>  
    </div>
  )
}

export default Carrier