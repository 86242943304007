import React from 'react'
import { aboutBottomImg, beliefArr, brigadeTechPark, call1Img, flowActionArr, fprojectBannerData, industrySectionArr, mobile, teamArr } from '../constants/AppConstant'
import CountUp from 'react-countup';
import { Link } from 'react-router-dom';
import { FaLinkedin } from "react-icons/fa";

function About() {

  function openLinkedin(v){
    window.open(v,'_blank')
  }
  return (
    <div className='relative'>


       
        <div className='relative flex items-center justify-center bg-about_bg1 bg-center bg-cover h-[200px]  md:h-[340px] 2xl:h-[500px]'>
            <div className='z-10'>
              <h6 className='text-[30px] text-white text-center tracking-[10px] font_thicker opacity-80 2xl:text-[35px]'>About Us</h6>
              <h6 className='text-center font_normal text-white opacity-100  text-[14px] 2xl:text-[18px]'> <span onClick={()=>navigator('/')} className='relative cursor-pointer after:bg-[#fff] after:absolute after:h-[2px] after:w-0 after:bottom-0 after:left-0  text-white opacity-100  hover:after:w-full after:transition-all after:duration-300'>Home</span> / About Us </h6>
              <h6 className='text-[13px] mt-1  text-white text-center font_normal 2xl:text-[16px] opacity-80'>Would you like to know more about us?. We would love to introduce ourselves. </h6>
            </div>
            <div className='bg-black opacity-60 absolute top-0 left-0 w-full h-[200px]  md:h-[340px] 2xl:h-[500px]'>
            </div> 
        </div>


        <div className='w-[90%] md:w-[80%]  py-[2%] md:flex items-center mx-auto'>

          <img src={fprojectBannerData} className='md:w-[50%] object-contain md:h-[400px] 2xl:h-[500px]' />

          <div className='md:w-[50%] md:ml-10'>
          
            <h6 className='font_bold text-center md:text-left text-[20px] 2xl:text-[25px] mt-2'>About Us</h6>
            <div className='flex items-center justify-center md:justify-start'>
              <h6 data-aos="fade-right" className='w-[50px] border-b-[2px] rounded-[10px] border-[#027d9b]'></h6>
              <h6 data-aos="fade-up" data-aos-delay="300" className='text-[10px] font_bold text-[#027d9b] 2xl:text-[15px] mx-2 uppercase'>Fidelitus Project</h6>
              <h6 data-aos="fade-left"className='w-[50px] border-b-[2px] rounded-[10px] border-[#027d9b]'></h6>
            </div>
                  {/* <h6 data-aos="fade-left" data-aos-delay="300" className='md:w-[90%] font_normal text-[14px] mt-2'>Fidelitus Projects is a leading Design-Build Commercial and industrial infrastructure provider based in Bengaluru. Our team of experienced industry professionals helps you grab every growth development opportunity in Turnkey commercial interior & industrial infrastructure. </h6>
                  <h6 data-aos="fade-left" data-aos-delay="300" className='md:w-[90%] font_normal text-[14px] mt-2'>Also as a leading interior designing firm in Bangalore, we showcase our expertise not just in the fields of Architecture, Design, and 3-D Rendering, but also in Project Management, Quantification, and Budgeting, Procurement, Turnkey Projects.</h6> */}
                  <h6 data-aos="fade-up" className='font_bold mt-2 text-[16px] 2xl:text-[20px]' >Who we are and What do we do?</h6>
                  <h6 data-aos="fade-up" className='font_normal mt-2 text-[14px] 2xl:text-[18px]'>Fidelitus Projects is a Techno commercial organisation catering to Turnkey Commercial/ industrial Infrastructure and end to end MEP requirements to channel partners across India.</h6>

                  <h6 data-aos="fade-up" className='font_bold mt-2 text-[16px] 2xl:text-[20px]'>How we work ?</h6>
                  <h6 data-aos="fade-up" className='font_normal mt-2 text-[14px] 2xl:text-[18px]'>As a close-knit team of over 80 members, we bring together a wealth of industry experience to provide our clients with comprehensive, turnkey solutions. We take pride in ourselves being a one-stop solution platform, addressing all your needs efficiently and effectively.</h6>
          </div>
        </div>

        <div className='w-[100%] px-[5%] md:px-[10%] bg-slate-100 py-[5%] flex flex-col items-start justify-center mx-auto'>
          
            <div className='z-10 mb-[4%] bg-bottom bg-contain bg-no-repeat'>
                  <h6 data-aos="fade-left" className='text-center text-[20px] 2xl:text-[25px] font_bold'>Our Beliefs</h6>
                  <div className='flex justify-center items-center'>
                    <h6 data-aos="fade-right" className='w-[50px] border-b-[2px] rounded-[10px] border-[#027d9b]'></h6>
                    <h6 data-aos="fade-up" data-aos-delay="300" className='text-[10px] 2xl:text-[20px] font_bold text-[#027d9b] mx-2 uppercase'>Why we Stand Out</h6>
                    <h6 data-aos="fade-left"className='w-[50px] border-b-[2px] rounded-[10px] border-[#027d9b]'></h6>
                  </div>
                  <h6 data-aos="fade-right" className='font_normal my-5 text-center text-[14px] 2xl:text-[18px]'>We believe on the procedure process to provide high <span className='font_bold'> performance-driven</span>, culture</h6>
                  
                  <div>
                  <h6 className='text-[14px] font_normal text-center mt-4 2xl:text-[18px]'>We, at Fidelitus believe in a performance-driven culture, where we go to great lengths to deliver each project with perfection. Our team goes beyond the limited professional contract for giving you the output, worth appreciating. Our core belief dictates that client contentment is our top-most priority. Hence, the bonds that we develop with them help us understand them better and deliver nothing but the best.</h6>
                  </div>

            </div>

            <div className='w-[100%] md:w-[100%] md:mt-0 md:gap-4 mx-auto grid grid-cols-2  md:grid-cols-4'>
                {beliefArr?.map((f,i)=>(
                  <div  data-aos="fade-up" className={`p-4 ${(i !== beliefArr?.length - 1) && `md:border-r border-gray-200 md:border-gray-300  ${(i == 0 || i == 2)  && 'border-r'}  ${(i == 0 || i == 1)  && ' border-b md:border-b-0'} ` }`} style={{background:f?.color}}>
                    <div className='flex justify-center items-center'>
                        <img src={f?.img} className='w-[70px] h-[70px] 2xl:w-auto 2xl:h-[120px] object-contain' />
                    </div>
                    <h6 className='font_bold mt-2 2xl:mt-6 text-center text-[14px] md:text-[13.5px] 2xl:text-[20px]'>{f?.value}</h6>
                  </div>  
                ))}
            </div>



        </div>


        {/* <div className='w-[100%] px-[5%] md:px-[10%]  py-[3%]'>
         <h6 data-aos="fade-up" className='font_bold mt-2 text-[16px]' >Who we are and What do we do?</h6>
         <h6 data-aos="fade-up" className='font_normal mt-2 text-[14px]'>Fidelitus Projects is a Techno commercial organisation catering to Turnkey Commercial/ industrial Infrastructure and end to end MEP requirements to channel partners across India.</h6>

         <h6 data-aos="fade-up" className='font_bold mt-2 text-[16px]'>How we work ?</h6>
         <h6 data-aos="fade-up" className='font_normal mt-2 text-[14px]'>As a close knit team of 70+ members having a consolidated industry experience of over 70+ years, function as a ONE STOP SOLUTION platform for our clients to achieve Turnkey solutions for all their requirements.</h6>


        </div> */}

        {/* <div className='mt-4 py-[2%] border-slate-100'>
        <h6 className='text-center text-[20px] font_bold'>Why Choose Us</h6>
        <div className='flex justify-center items-center'>
              <h6 data-aos="fade-right" className='w-[50px] border-b-[2px] rounded-[10px] border-[#027d9b]'></h6>
              <h6 data-aos="fade-up" data-aos-delay="300" className='text-[10px] font_bold text-[#027d9b] mx-2 uppercase'>WHY WE SUCCEED</h6>
              <h6 data-aos="fade-left"className='w-[50px] border-b-[2px] rounded-[10px] border-[#027d9b]'></h6>
        </div>
        <h6 data-aos="dat-left" className='text-[13px] mt-5 font_normal w-[90%] text-center mx-auto'>With us, you experience the power of ideas, design and craftsmanship come alive.!</h6>

        <div className='w-[100%] md:w-[100%] mt-10 md:mt-10 px-[5%] md:px-[10%] gap-4 mx-auto grid md:grid-cols-3'>
          {flowActionArr?.map((f,i)=>(
            <div data-aos="fade-up"  className={`p-4 bg-white ${(i !== flowActionArr?.length - 1) && ' border-gray-200'}`} style={{background:f?.color}}>
               <div className='flex items-center'>
                  <img src={f?.img} className='w-[50px]  h-[50px] object-contain' />
               <h6 className='font_bold text-center ml-2 mt-[10px] text-[16px]'>{f?.heading}</h6>
               </div>
               <h6 className='font_normal mt-2 text-[12.5px]'>{f?.description}</h6>
            </div>  
          ))}
        </div>
        </div> */}

        {/* <div >
            <div className='w-[90%] md:w-[80%] mt-5 md:mt-0 mx-auto pb-10'>
                <h6 className='text-[14px] font_normal'>Our <span className='font_bold text-[#027d9b]'>Interior design</span> company in <span className='font_bold text-[#027d9b]'>Bangalore</span> showcases expertise in fields such as Architecture, Design and 3-D Rendering, Project Management, Quantification and Budgeting, Procurement, Turnkey Projects, HR Management, Facility Management Services, and Real Estate Transactions.</h6>
                <h6 className='text-[14px] font_normal mt-4'>Having earned a solid reputation through the years, it reflects in the properties we have constructed, but our clients are a testimony to the respect and trust</h6>
                <h6 className='text-[14px] font_normal mt-4'>We, at Fidelitus believe in a performance-driven culture, where we go to great lengths to deliver each project with perfection. Our team goes beyond the limited professional contract to give you the output worth appreciating. Our core belief dictates that client contentment is our topmost priority. Hence, the bonds that we develop with them help us understand them better and deliver nothing but the best. Visit Fidelitus Projects in Bangalore for a free consultation, or book a free consultation online to sit back, relax, and watch your dream home come to life</h6>
            </div>

            
            <div className='bg-slate-100'>
            <div className='grid grid-cols-4 px-[4%] md:px-[10%] py-[4%] md:py-[2%]'>
              {industrySectionArr?.map((i,i1)=>(
                <div data-aos="fade-bottom" data-aos-delay="300" className={`flex flex-col md:flex-row items-center md:justify-center ${i1 !==  industrySectionArr?.length -1 && ' md:border-r border-gray-300' }`}>
                  <i.icon className='text-[25px] md:text-[40px] text-gray-700' />
                  <div className='ml-3'>
                  <h6 className='font_bold mt-1 text-center text-[12px] md:text-[15px] md:text-[20px]'>{<CountUp start={0} end={i?.value} />} + {i1 ===  industrySectionArr?.length-1 && 'Years'} </h6>
                  <h6 className='text-[10px] md:text-[13px] -mt-[4px] font_normal'>{i?.label}</h6>
                </div>  
              </div>  
              ))}
            </div>
          </div>
        </div> */}


        <div className='py-10 md:py-[2%] border-b border-slate-100 md:flex items-center justify-center w-[100%] px-[10%] mx-auto'>

            <div  data-aos="fade-right" className='md:w-[30%] relative md:mr-[5%] h-[400px] 2xl:h-auto'>
              <img src={teamArr[0]?.img} alt={teamArr[0]?.img} className='grayscale-[90%] h-full object-cover w-[100%]'  />
              {teamArr[0]?.linked_in_link !== '' && 
              <FaLinkedin onClick={()=>openLinkedin(teamArr[0]?.linked_in_link)} size={28} className='absolute cursor-pointer bg-white/20 p-[5px] z-10 right-[10px] bottom-[10px]' />}
            </div>

            <div className='mt-10 md:mt-0 md:w-[65%]'>
                <h6 data-aos="fade-up" className='font_bold text-[12px] md:text-[14px] 2xl:text-[25px]'>Achuth Gowda</h6>     
                <h6 data-aos="fade-up" className='text-[13px] font_bold  mb-[-5px] 2xl:text-[16px]'>Founder</h6>     

                <h6 data-aos="fade-up" className='text-[13px] font_normal 2xl:text-[18px]  mt-4'>Under his leadership, Fidelitus Corp has grown to become a leader in commercial real estate transactions and a trusted advisory real estate company in Bangalore. His leadership skills since 2013 has led to the companies growth and branch out into many verticals and grow in each one of them. His business acumen has lead the company to develop its core services as well as increase its revenue from other domains. Achuth Gowda is the recipient of the prestigious Nada Prabhu Kempegowda Award 2020.</h6>     
                <h6 data-aos="fade-up" className='text-[13px] font_normal 2xl:text-[18px] mt-4'>A Founder is the first employee of the organization and when they treat even employee as an EMPLOYER those individuals develop a sense of responsibility & belongingness towards the company and the success derived out of such efforts transcends to a whole new level!</h6>     
            </div>    


           
        </div>

        


        <div className='mt-[5%]'>
      
          <div className='z-10  bg-bottom bg-contain bg-no-repeat'>
          <h6 data-aos="fade-left" className='text-center text-[20px] 2xl:text-[25px]  font_bold'>Director's</h6>
          <div className='flex justify-center items-center'>
            <h6 data-aos="fade-right" className='w-[50px] border-b-[2px] rounded-[10px] border-[#027d9b]'></h6>
            <h6 data-aos="fade-up" data-aos-delay="300" className='text-[10px] 2xl:text-[16px] font_bold text-[#027d9b] mx-2'>LET'S CONNECT</h6>
            <h6 data-aos="fade-left"className='w-[50px] border-b-[2px] rounded-[10px] border-[#027d9b]'></h6>
          </div>
          <h6 data-aos="fade-right" className='text-[14px]  my-5 2xl:text-[18px]  w-[90%] mx-auto font_normal text-center'>We've got an entire team dedicated to supporting you and your business</h6>
          </div>
        
        
        <div className='grid  z-20 grid-cols-2 md:grid-cols-3 my-[5%]   gap-5 2xl:gap-5 w-[80%] mx-auto'>
        {teamArr?.slice(1,4)?.map((t)=>(
          <div data-aos="fade-up" className='relative w-[100%] '>
              {t?.linked_in_link !== '' && 
              <FaLinkedin onClick={()=>openLinkedin(t?.linked_in_link)} size={28} className='absolute cursor-pointer bg-white/20 p-[5px] z-10 right-[10px] bottom-[10px]' />}
              <img src={t?.img} alt={t?.img} className='h-[200px] grayscale-[80%] md:h-[400px] 2xl:h-[650px] object-cover w-[100%] mix-blend-plus-lighter'  />
              <div className='backdrop-filter  bg-white/40 backdrop-blur-md p-2 w-[90%] mx-[5%] bottom-2 absolute'>
                  <h6 className='font_bold text-[12px] md:text-[14px] 2xl:text-[20px]'>{t?.name}</h6>
                  <h6 className='text-[11px] md:text-[13px] font_normal  mb-[-5px] 2xl:text-[20px]'>{t?.designation}</h6>
              </div> 
          </div>  
        ))}
        </div>

        <div className='z-10  bg-bottom bg-contain bg-no-repeat'>
          <h6 data-aos="fade-left" className='text-center text-[20px] 2xl:text-[25px]  font_bold'>Our Army</h6>
          <div className='flex justify-center items-center'>
            <h6 data-aos="fade-right" className='w-[50px] border-b-[2px] rounded-[10px] border-[#027d9b]'></h6>
            <h6 data-aos="fade-up" data-aos-delay="300" className='text-[10px] font_bold 2xl:text-[16px]  text-[#027d9b] mx-2'>LET'S CONNECT</h6>
            <h6 data-aos="fade-left"className='w-[50px] border-b-[2px] rounded-[10px]  border-[#027d9b]'></h6>
          </div>
          {/* <h6 data-aos="fade-right" className='text-[14px]  my-5  w-[90%] mx-auto font_normal text-center'>We've got an entire team dedicated to supporting you and your business</h6> */}
          </div>

        <div className='grid  z-20 grid-cols-2 md:grid-cols-5 my-[5%]  gap-5 w-[80%] mx-auto'>
        {teamArr?.slice(4)?.map((t)=>(
          <div data-aos="fade-up" className='relative  w-[100%] '>
            {/* grayscale-[90%]  */}
               
              {t?.linked_in_link !== '' && 
              <FaLinkedin onClick={()=>openLinkedin(t?.linked_in_link)} size={24} className='absolute cursor-pointer bg-white/20 p-[5px] z-10 right-[10px] bottom-[10px]' />}
              <img src={t?.img} alt={t?.img} className='h-[200px] grayscale-[80%]  md:h-[250px] 2xl:h-[420px] object-cover w-[100%] mix-blend-plus-lighter'  />

              <div className='backdrop-filter  bg-white/40 backdrop-blur-md p-2 w-[90%] mx-[5%] bottom-2 absolute'>
                  <h6 className='font_bold text-[12px] md:text-[14px] 2xl:text-[20px]'>{t?.name}</h6>
                  <h6 className='text-[11px] md:text-[13px] font_normal  2xl:text-[16px] mb-[-5px]'>{t?.designation}</h6>
              </div> 
          </div>  
        ))}
        </div>
        </div>


        <div className='bg-slate-100 relative min-h-[200px] mt-[12%] p-10 px-[10%]'>
        
        <div className='w-[70%] md:w-[40%]'>
          <h6 className='font_bold text-[18px] w-[80%] 2xl:text-[24px]'>Experience Team to Support our Customer</h6>
          <h6 className='font_normal text-[14px] w-[100%] z-40 2xl:text-[18px]'>We are ready to assist you, For any query related about our services please give us a call!</h6>
          <Link to={`tel:${mobile}`} target='_blank'>
          <h6  data-aos="fade-left" data-aos-delay="600" className='font_bold z-10 text-[12px] 2xl:text-[15px] border mt-2 w-[100px] text-center p-[5px] border-black hover:bg-black hover:text-white cursor-pointer duration-300'>Contact Us</h6>
          </Link>
        </div>


        {/* <img src={call1Img} className='h-[280px] 2xl:h-[400px] bottom-[0px] absolute z-[0] right-[-10%] md:right-[10%] mix-blend-multiply;' /> */}
        </div>




      </div>

  )
}

export default About