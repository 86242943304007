import React, { useEffect, useState } from 'react';
import { base_url, blogArr, supportUs } from '../constants/AppConstant';
import { useNavigate } from 'react-router-dom';
import { IoSearch } from "react-icons/io5";
import moment from 'moment';
import axios from 'axios';
import { message,Pagination } from 'antd'
import { GetBlogServices } from '../services/BlogServices';

function Blogs() {
  
  const navigator = useNavigate()
  const [singleData,setsingleData] = useState({})
  const [datas,setdatas] = useState([])
  const [page,setpage] = useState(1)



  // useEffect(()=>{
  //   setdatas(blogArr)
  //   setsingleData(blogArr[0])
  // },[])

  useEffect(()=>{
    getData()
  },[page])

  async function getData(){
    const response = await GetBlogServices(page,search?.text)
    setdatas(response?.data)
    if(response?.data?.datas?.length > 0){
      setsingleData(response?.data?.datas[0])
    }
  }


  const [data,setdata] =  useState({name:'',mobile:'',email:'',requirment:'',service:''})


  const [search,setsearch] = useState('')

  async function handleSubmit(e){
    if (e.key === 'Enter') {
      if(search?.length > 0){
        const response = await GetBlogServices(page,search)
        setdatas(response?.data)
        if(response?.data?.datas?.length > 0){
          setsingleData(response?.data?.datas[0])
        }else{
          setsingleData(null)
        }

        // let sendData =  datas.filter((f)=>f.heading.toLowerCase().includes(search.toLowerCase()))
        // setdatas(sendData)
      }else{
        const response = await GetBlogServices(page,'')
        setdatas(response?.data)
        if(response?.data?.datas?.length > 0){
          setsingleData(response?.data?.datas[0])
        }else{
          setsingleData(null)
        }
      }
    }
  }
  

  async function openDetail(v){
    navigator(`/blogs/detail/${v?._id}`)
  }


  async function submitForm(){
      if(data?.email !== ''){ 
        const sendData = {...data}
        await axios.post(`${base_url}clients/create`,sendData)
        .then((res)=>{
          if(res?.status === 201){
            message.success('Blog Subscribed Successfully')
            setdata({name:'',mobile:'',email:'',requirment:'',department_website:'',service:''})
          }
        }).catch((err)=>{      
        }) 
      }
  }
  
  

   
   
  return (
    <div className='w-screen overflow-x-hidden'>
        <div className='relative flex items-center justify-center bg-no-repeat bg-blogs_bg bg-bottom bg-cover h-[200px]  md:h-[340px] 2xl:h-[500px]'>
           <div className='z-10'>
            <h6 className='text-[30px] text-white text-center tracking-[10px] font_thicker opacity-80 2xl:text-[35px]'>Our Blogs</h6>
            <h6 className='text-center font_normal text-white opacity-100 text-[14px] 2xl:text-[18px]'> <span onClick={()=>navigator('/')} className='relative cursor-pointer after:bg-[#fff] after:absolute after:h-[2px] after:w-0 after:bottom-0 after:left-0  text-white opacity-100  hover:after:w-full after:transition-all after:duration-300'>Home</span> / Blogs </h6>
            <h6 className='text-[13px] mt-1  text-white text-center font_normal opacity-80 2xl:text-[16px]'>Would love to hear some of the blogs related to Interior and Business growth. </h6>
           </div>
           <div className='bg-black opacity-60 absolute top-0 left-0 w-full h-[200px]  md:h-[340px] 2xl:h-[500px]'>
           </div> 
        </div>


        <div className='w-[90%] 2xl:w-[70%] md:w-[80%] mx-auto py-[4%]'>
            
        {/* <h6 className='font_bold mb-8 text-black opacity-100 text-[13px] '> <span onClick={()=>navigator('/')} className='relative cursor-pointer after:bg-[#027d9b] after:absolute after:h-[2px] after:w-0 after:bottom-0 after:left-0  text-[#027d9b] font-[700] opacity-100  hover:after:w-full after:transition-all after:duration-300'>Home</span> / Blogs </h6> */}


           <h6 data-aos="fade-right" className='font_bold w-[100%] capitalize text-[24px] text-center mx-auto 2xl:text-[27px]'> our Blogs</h6>  
           <h6 data-aos="fade-left" className='font_bold w-[100%] capitalize text-[15px] 2xl:text-[18px] text-gray-400 text-center mx-auto'> A center  for all our resources & insights</h6>  

           <div  data-aos="fade-bottom" className='bg-gray-100 relative md:w-[50%] mx-auto mt-[20px] mb-[50px] md:mb-[100px] rounded-[10px] overflow-hidden'>
                <IoSearch className='absolute left-[10px] top-[15px]' />
                <input value={search} onChange={e=>setsearch(e.target.value)} onKeyDown={handleSubmit} type="text" className='bg-gray-100 focus:outline-none p-[10px] pl-[35px] placeholder:text-[12px] 2xl:placeholder:text-[15px]  text-[14px] 2xl:text-[17px] w-full'  placeholder='Search for blogs by topic or keywords...' /> 
           </div>



            {(singleData !== null && singleData?._id !== undefined) &&
            <div className='md:flex'>
              <div onClick={()=>openDetail(singleData)}  data-aos="fade-right" className='md:w-[50%] rounded-[10px] overflow-hidden md:h-[300px]  2xl:h-[450px]'>
                <img src={`${process.env.REACT_APP_AWS_IMAGE_URL}${singleData?.blog_image}`} className='w-[100%] h-full object-cover' />
              </div> 
              <div onClick={()=>openDetail(singleData)} className='mt-5 md:mt-0 md:w-[40%] md:ml-[5%]'>
                  <div data-aos="fade-left" className='flex items-center my-2'>
                       <h6  className={`font_bold text-[12px] 2xl:text-[15px]`} style={{ color: `${singleData?.color}`}}>{singleData?.tags?.map((c)=>c.name)}</h6>
                       <h6 className={`w-[6px] h-[6px] rounded-full mx-2.5`} style={{ backgroundColor: `${singleData?.color}`}}></h6>
                       <h6  className='font_bold text-[12px] 2xl:text-[15px]' style={{ color: `${singleData?.color}`}}>{singleData?.min_read}</h6>
                  </div> 

                  <h6 data-aos="fade-left" data-aos-delay="300"  className={`font_bold text-[35px] 2xl:text-[40px]`} >{singleData?.title}</h6>

                  <div data-aos="fade-bottom" data-aos-delay="600" className='flex items-center '>
                    <div className='flex items-center my-2'>
                    {(singleData?.written_by?.profile_photo !== null && singleData?.written_by?.profile_photo !== undefined) ?
                      <img src={`${process.env.REACT_APP_AWS_IMAGE_URL}${singleData?.written_by?.profile_photo}`} className='w-[30px] h-[30px] 2xl:w-[35px] 2xl:h-[35px] object-contain bg-slate-100 rounded-full' /> 
                      :
                      <h6 className='w-[24px] h-[24px] flex items-center justify-center text-[11px] 2xl:text-[14px] font_bold text-white rounded-full' style={{background:singleData?.color === undefined ? '#fafafa' : singleData?.color}}>{singleData?.written_by?.name?.slice(0,1)}</h6>
                    }
                    <h6 className='font_bold text-[11px] 2xl:text-[14px] ml-2'>{singleData?.written_by?.name}</h6>
                    </div>
                    <h6 className='font_bold ml-4 text-gray-400 text-[11px] 2xl:text-[14px]'>{moment(singleData?.date)?.format('LL')}</h6>
                  </div> 
              </div>
            </div>}

            {datas?.datas?.length > 1 &&  
            <div className='grid md:grid-cols-3 gap-8 md:gap-14 mt-14'>
             {datas?.datas?.slice(1,4)?.map((b)=>(
              <div data-aos="fade-right" onClick={()=>openDetail(b)} key={b?.id} className='relative group border-box overflow-hidden mb-10 cursor-pointer'>
                     <div className='h-[200px] 2xl:h-[250px] rounded-[10px] overflow-hidden'>
                     <img src={`${process.env.REACT_APP_AWS_IMAGE_URL}${b?.blog_image}`} className='w-[100%] overflow-hidden group-hover:scale-110 duration-300 h-[200px] 2xl:h-[250px] object-stretch'  />
                     </div>  
                     <div className='px-2'>
                     <div className='flex items-center my-2'>
                       <h6 className={`font_bold text-[12px] 2xl:text-[15px]`} style={{ color: `${b?.color}`}}>{b?.tags?.map((c)=>c?.name)}</h6>
                       <h6 className={`w-[6px] h-[6px] rounded-full mx-2.5`} style={{ backgroundColor: `${b?.color}`}}></h6>
                       <h6 className='font_bold text-[12px] 2xl:text-[15px]' style={{ color: `${b?.color}`}}>{b?.min_read}</h6>
                     </div> 

                     <h6 className='font_bold line-clamp-2 text-[14px] 2xl:text-[17px] mt-2'>{b?.title}</h6>
                     <h6 className='line-clamp-3 mt-2 text-[12px] 2xl:text-[15px] font_normal text-gray-500'>{b?.motive}</h6>
                     <div className='flex items-center justify-between'>
                       <div className='flex items-center my-2'>
                       {(b?.written_by?.profile_photo !== null && b?.written_by?.profile_photo !== undefined) ?
                          <img src={`${process.env.REACT_APP_AWS_IMAGE_URL}${b?.written_by?.profile_photo}`} className='w-[30px] h-[30px] 2xl:w-[35px] 2xl:h-[35px] object-contain bg-slate-100 rounded-full' /> 
                          :
                          <h6 className='w-[24px] h-[24px] flex items-center justify-center text-[11px] 2xl:text-[14px] font_bold text-white rounded-full' style={{background:b?.color === undefined ? '#fafafa' : b?.color}}>{b?.written_by?.name?.slice(0,1)}</h6>
                        }
                       <h6 className='font_bold text-[11px] 2xl:text-[14px] ml-2'>{b?.written_by?.name}</h6>
                       </div>
                       <h6 className='font_bold text-gray-400 text-[11px] 2xl:text-[14px]'>{moment(b?.date)?.format('LL')}</h6>
                     </div> 
                     </div>
              </div>))}   
            </div>}

            {datas?.datas?.length > 5 &&  
            <>
            <h6 className='font_bold text-[15px] 2xl:text-[18px] mb-8 mt-16'>All Blog Posts</h6>
            <div className='grid md:grid-cols-3 gap-8 md:gap-14 mt-2'>
             {datas?.datas?.slice(5)?.map((b)=>(
              <div data-aos="fade-bottom" onClick={()=>openDetail(b)} key={b?.id} className='relative group border-box overflow-hidden cursor-pointer'>
                     <div className='h-[200px] 2xl:h-[250px] rounded-[10px] overflow-hidden'>
                     <img src={`${process.env.REACT_APP_AWS_IMAGE_URL}${b?.blog_image}`} className='w-[100%] overflow-hidden group-hover:scale-110 duration-300 h-[200px] 2xl:h-[250px] object-stretch'  />
                     </div>  
                     <div className='px-2'>
                     {/* <h6>{b?.blog_image}</h6> */}
                     <div className='flex items-center my-2'>
                       <h6 className={`font_bold text-[12px] 2xl:text-[15px]`} style={{ color: `${b?.color}`}}>{b?.tags?.map((c)=>c?.name)}</h6>
                       <h6 className={`w-[6px] h-[6px] rounded-full mx-2.5`} style={{ backgroundColor: `${b?.color}`}}></h6>
                       <h6 className='font_bold text-[12px] 2xl:text-[15px]' style={{ color: `${b?.color}`}}>{b?.min_read}</h6>
                     </div> 

                     <h6 className='font_bold line-clamp-2 text-[14px] 2xl:text-[17px] mt-2'>{b?.title}</h6>
                     <h6 className='line-clamp-3 mt-2 text-[12px] 2xl:text-[15px] font_normal text-gray-500'>{b?.motive}</h6>
                     <div className='flex items-center justify-between'>
                       <div className='flex items-center my-2'>
                       {(b?.written_by?.profile_photo !== null && b?.written_by?.profile_photo !== undefined) ?
                          <img 
                          src={`${process.env.REACT_APP_AWS_IMAGE_URL}${b?.written_by?.profile_photo}`}
                          className='w-[30px] h-[30px] 2xl:w-[35px] 2xl:h-[35px] object-contain bg-slate-100 rounded-full' /> 
                          :
                          <h6 className='w-[24px] h-[24px] 2xl:w-[29px] 2xl:h-[29px] flex items-center justify-center text-[11px] 2xl:text-[13px] font_bold text-white rounded-full' style={{background:b?.color === undefined ? '#fafafa' : b?.color}}>{b?.written_by?.name?.slice(0,1)}</h6>
                        }
                       <h6 className='font_bold text-[11px] 2xl:text-[13px] ml-2'>{b?.written_by?.name}</h6>
                       </div>
                       <h6 className='font_bold text-gray-400 text-[11px] 2xl:text-[13px]'>{moment(b?.date)?.format('LL')}</h6>
                     </div> 
                     </div>
              </div>))}   
            </div>
            </>}

            {(datas?.pagination?.total > datas?.pagination?.limit) &&
            <div className='mx-auto flex items-center justify-center my-[5%] w-full'>
            <Pagination size='small' onChange={(v)=>setpage(v)} total={datas?.pagination?.total} pageSize={datas?.pagination?.limit} />
            </div>}

            <div className='bg-[#def9ff] md:flex items-center px-5 md:px-20 py-4 my-[5%] rounded-[10px]'>

              <div className='md:w-[60%]'>
              <h6 className='text-[#027d9b] w-[70%] font_bold text-[19px] 2xl:text-[22px]'>If you would love to hear about our latest blogs and updates</h6>
              <h6 className='text-gray-400 font_bold text-[13px] 2xl:text-[16px]'>Get blogs and our latest updates by just subscribe</h6>

              <div className='mt-2'>
                <input type="email" value={data?.email} onChange={(e)=>setdata({...data,email:e.target.value})}  className='rounded focus:outline-none pl-4 text-[14px] 2xl:text-[17px] p-[5px] placeholder:text-[12px]'  placeholder='Enter your mail id' />
                <button onClick={submitForm} className='ml-2 bg-[#027d9b] text-white rounded text-[13px] 2xl:text-[16px] px-3 py-[5px]'>submit</button>
              </div>
              </div> 
              <div className='mt-10 md:mt-0 w-[40%]'>
              <img src={supportUs} className='animate-bounce w-[100%] h-[200px]  2xl:text-[250px] object-contain'  />
              </div>
            </div>

           
        </div>
    </div>

  )
}

export default Blogs