import React from 'react'
import { appLogo, email, face_book_link, linked_in_link, mobile, treeImg, whatsapp_link, you_tube_link } from '../constants/AppConstant'
import { TiSocialFacebook } from "react-icons/ti";
import { FaLinkedinIn,FaTwitter } from "react-icons/fa6";
import { FaYoutube } from "react-icons/fa";
import { RiWhatsappFill } from "react-icons/ri";
import { useNavigate } from 'react-router-dom';

function Footer() {

    const navigator = useNavigate()

    const more = [
        {   
            id:1,
            icon:'',
            name:'Privacy Policy',
            path:'/',
        },
        {   
            id:2,
            icon:'',
            name:'Contact Us',
            path:'/',
        },
        {   
            id:3,
            icon:'',
            name:'Careers',
            path:'/',
        },

    ]    

    const menu = [
        {   
            id:1,
            icon:'',
            name:'Home',
            path:'/',
        },
        {
            id:2,
            icon:'',
            name:'About',
            path:'/about',
        },
        {
            id:3,
            icon:'',
            name:'Services',
            path:'/services',
        },
        {
            id:4,
            icon:'',
            name:'Projects',
            path:'/projects',
        },
        {
            id:5,
            icon:'',
            name:'Contact',
            path:'/contact',
        },
        {
            id:6,
            icon:'',
            name:'Blogs',
            path:'/blogs',
        }
    ]   
    
    async function openPage(v){
        navigator(v)
    } 

    // const otherService = [
    // {   
    //     id:1,
    //     icon:'',
    //     name:'Real Estate Transaction',
    //     path:'/whats-more/real-estate-transaction',
    // },
    // {
    //     id:2,
    //     icon:'',
    //     name:'HR Labs',
    //     path:'/whats-more/hr-labs',
    // },
    // {
    //     id:3,
    //     icon:'',
    //     name:'Facility Management Service',
    //     path:'/whats-more/facility-management-services',
    // },
    // {
    //     id:4,
    //     icon:'',
    //     name:'Fidelitus Gallery',
    //     path:'/whats-more/fidelitus-gallery',
    // }
    // ]

    const supportUs = [
        {id:1,icon:TiSocialFacebook,path:face_book_link,size:15},
        {id:2,icon:FaLinkedinIn,path:linked_in_link,size:15},
        {id:3,icon:FaYoutube,path:you_tube_link,size:14},
        {id:4,icon:RiWhatsappFill,path:whatsapp_link,size:14},
        // {id:5,icon:FaTwitter,path:'',size:16}
    ]

  return (
    <div className='bg-[#1a1f25] relative w-screen overflow-x-hidden'>
        <img src={treeImg} className='absolute right-0 bottom-0' />

        <div className='grid md:grid-cols-4 gap-4  py-20 mx-[7%] md:mx-[10%]'>
            
            <div className='w-[100%]'>
                <img alt="appLogo" className='w-[120px] h-[40px] 2xl:w-auto 2xl:h-[100px] ' src={appLogo} />
                <h6 className='text-[#e67816] font_bold text-[12px] 2xl:text-[18px] my-[4px] 2xl:my-[8px] font-[800] uppercase'><span className='text-[#027d9b]'>Fidelitus</span> Projects</h6>
                <h6 className='text-[12px] my-2 font-[500] 2xl:text-[18px] text-gray-400 font-sans'>Brigade Software Park,No. 42, Ground Floor, B Block, 27th Cross, BSK 2nd Stage,</h6> 
                <h6 className='text-[12px] mb-2 font-[500] 2xl:text-[18px] text-gray-400 font-sans'>Bangalore – 560070, Karnataka.</h6>
                
                <h6 className='text-[11px] 2xl:text-[15px] text-white'>GSTIN: 29AACCF2465K1ZD</h6>
                <h6 className='text-[11px] 2xl:text-[15px] text-white'>RERA: PRM/KA/RERA/1251/310/AG/170829/000355</h6>

                {/* <div >
                    <h6 className='font_bold text-gray-400 mt-2 text-[11px]'>Contact Us</h6>
                    <h6 className='text-[12px] font-normal mb-[5px] font-[600] text-white'>{mobile}</h6>
                    <h6 className='font_bold text-gray-400 text-[11px]'>Write to us at</h6>
                    <h6 className='text-[12px] font-normal mb-[5px] font-[600] text-white'>{email}</h6>
                </div> */}
            </div>

            <div className='w-[100%]'>
                <h6 className='text-[14px] 2xl:text-[18px] font-[800] text-gray-100 mb-5 border-l-4 pl-2 border-l-[#027d9b]'>Quick Links</h6>
                <div className='pl-4'>
                {menu?.map((m,i)=>(
                    <h6 key={i} onClick={()=>openPage(m?.path)} className='font_normal text-[12px] 2xl:text-[14px] font-sans mb-[5px] font-[600] text-gray-400 hover:text-[#fff] cursor-pointer'>{m?.name}</h6>
                ))}
                </div>
            </div>

            {/* <div className='w-[100%]'>
                <h6 className='text-[14px] font-[800] text-gray-100 mb-5 border-l-4 pl-2 border-l-[#027d9b]'>Other Services</h6>
                <div className='pl-4'>
                {otherService?.map((m)=>(
                    <h6 onClick={()=>openPage(m?.path)} className='font_normal text-[12px] font-sans mb-[5px] font-[600] text-gray-400 hover:text-[#fff] cursor-pointer'>{m?.name}</h6>
                ))}
                </div>
            </div> */}

            <div className='w-[100%]'>
                <h6 className='text-[14px] font-[800] text-gray-100 mb-5 border-l-4 pl-2 border-l-[#027d9b]'>Follow Us</h6>
                <h6 className='text-[12px] font-normal mb-[5px] 2xl:text-[15px] font-[600] text-gray-400 font_normal'>Join our social media platform to get updates for each projects!.</h6>
                <div className='flex mt-2 items-center'>
                {supportUs?.map((s,i)=>(
                   <span key={i} className='hover:bg-white p-[4px] text-gray-300 hover:text-[#027d9b] scale-110 duration-300 rounded mr-2'>
                   <a href={s?.path} target='_blank'>
                    <s.icon  size={s?.size}   />
                    </a>

                    </span>
                ))}
                </div>
            </div>    

            <div className='w-[100%]'>
            <div >
                    <h6 className='font_bold text-gray-400 mt-2 text-[11px] 2xl:text-[14px]'>Contact Us</h6>
                    <h6 className='text-[12px] font-normal mb-[5px] font-[600] 2xl:text-[18px] text-white'>{mobile}</h6>
                    <h6 className='font_bold text-gray-400 text-[11px] 2xl:text-[14px]'>Write to us at</h6>
                    <h6 className='text-[12px] font-normal mb-[5px] font-[600] 2xl:text-[18px] text-white'>{email}</h6>
                </div>
            </div>    
        </div>


        {/* <div className=' py-[2%] my-[3%] mx-[10%] text-white border-gray-700 border-t-[1px]'> 

            <h6 className='text-center font_bold mt-5 md:mt-0 text-[15px]'>You Can Meet US</h6>

            <div className='grid gap-4 py-5 md:px-[5%] md:grid-cols-3'>

                <div className='border-b md:border-b-0 md:border-r p-4 border-slate-600'>
                    <h6 className='font_bold text-[14px]'>Contact Us</h6>
                    <h6 className='text-[12px] font-normal mb-[5px] font-[600] text-gray-400 mt-1 hover:text-white'>+91 9964833590</h6>
                    <h6 className='font_bold text-[14px]'>Write to us at</h6>
                    <h6 className='text-[12px] font-normal mb-[5px] font-[600] text-gray-400 mt-1 hover:text-white'>info@fidelitusprojects.com</h6>
                </div>

                <div className='border-b md:border-b-0 md:border-r  p-4 border-slate-600'>
                   <h6 className='font_bold text-[14px]'>Address</h6>
                   <h6 className='text-[12px] font-normal mb-[5px] font-[600] text-gray-400 mt-1 hover:text-white'>Fidelitus Corp, Brigade Software Park, No. 42, Ground Floor, B Block, 27th Cross, BSK 2nd Stage, Bangalore - 560070, Karnataka</h6>
                </div>

                <div className=' p-4 border-slate-600'>
                   <h6 className='font_bold text-[14px]'>More</h6>
                   <div className='pl-0 mt-1'>
                    {more?.map((m)=>(
                        <h6 onClick={()=>openPage(m?.path)} className='font_normal text-[12px] font-sans mb-[5px] font-[600] text-gray-400 hover:text-[#fff] cursor-pointer'>{m?.name}</h6>
                    ))}
                   </div>
                </div>

            </div>

        </div> */}


        {/* <div className='flex items-center'>
            <div>
                 <h6>info.support@fidelitusprojects.com</h6>
            </div>
        </div> */}


        <h6 className='text-[10px] p-[6px] 2xl:text-[12px] font-[600] text-gray-300 text-center bg-[#242b32]'>Copyright © 2024-25 <span className='text-[#027d9b]'>Fidelitus Projects</span> , Inc. All rights reserved.</h6>
    </div>
  )
}

export default Footer