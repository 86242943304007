import React from 'react'
import { service12Img, service3Img, service5Img, service6Img, service7Img, service9Img } from '../constants/AppConstant'
import { address, email, mobile, mobile1, ourService, } from '../constants/AppConstant'
import { useLocation, useNavigate } from 'react-router-dom'
import { MdPhoneCallback } from "react-icons/md";
import { BsMailbox2 } from "react-icons/bs";
import { FaLocationDot } from "react-icons/fa6";


function ProjectEvaluation() {
  const {pathname} = useLocation()
  const navigator = useNavigate()

  return (
    <div>
        <div className='relative flex items-center justify-center bg-evaluation_bg bg-center bg-cover h-[200px]  md:h-[340px] 2xl:h-[500px]'>
            <div className='z-10'>
              <h6 className='text-[25px] md:text-[30px] text-white text-center tracking-[10px] font_thicker opacity-80 2xl:text-[35px]'>Project Evaluation & Estimation</h6>
              <h6 className='text-center font_normal text-white opacity-100 text-[14px] 2xl:text-[18px]'> <span onClick={()=>navigator('/')} className='relative cursor-pointer after:bg-[#fff] after:absolute after:h-[2px] after:w-0 after:bottom-0 after:left-0  text-white opacity-100  hover:after:w-full after:transition-all after:duration-300'>Home</span> / Project Evaluation & Estimation </h6>
              <h6 className='text-[13px] mt-1  text-white text-center font_normal opacity-80 2xl:text-[16px]'>Would like to know more about our Project Evaluation & Estimation. </h6>
            </div>
            <div className='bg-black opacity-60 absolute top-0 left-0 w-full h-[200px]  md:h-[340px] 2xl:h-[500px]'>
            </div> 
         </div>

        <div className='flex-col-reverse flex md:flex-row w-[90%] md:w-[80%] mx-auto py-[5%]'>

        <div className='md:w-[30%] mt-10 md:mt-0'>
          
          <div className='md:w-[90%] border border-slate-100 p-6'>
          <div className='border-b-[2px] pb-2 mb-5 border-slate-100'>
          <h6  data-aos="fade-right" className='font_bold text-[15px] 2xl:text-[18px]'>Our Services</h6>  
          </div>
          {ourService?.map((s)=>(
            <h6 data-aos-delay="300" data-aos="fade-bottom" onClick={()=>navigator(s?.path)} className={`font_bold cursor-pointer text-[15px] 2xl:text-[17px] my-2 p-4  ${s?.path === pathname ? 'bg-[#027d9b] text-white' : 'bg-slate-50'}`}>{s?.name} </h6>
          ))}
          </div>

          <div className='md:w-[90%] border border-slate-100 p-6 mt-5'>
          <div className='border-b-[2px] pb-2 mb-5 border-slate-100'>
          <h6  data-aos="fade-right" className='font_bold text-[15px] 2xl:text-[18px]'>Contact</h6>  
          </div>

          <div data-aos="fade-bottom" data-aos-delay="100" className='flex  bg-slate-50 my-2 p-4 items-start'>
            <MdPhoneCallback className='text-[#027d9b]' size={20} /> 
            <div className='ml-2'>
              <h6 className='font_bold text-[14px] 2xl:text-[17px]'>Phone Number</h6>
              <h6 className='font_normal text-[13px] 2xl:text-[16px]'>{mobile} / {mobile1}</h6>
            </div>
          </div>

          <div data-aos="fade-bottom" data-aos-delay="200" className='flex  bg-slate-50 my-2 p-4 items-start'>
            <BsMailbox2 className='text-[#027d9b]' size={20} /> 
            <div className='ml-2'>
              <h6 className='font_bold text-[14px] 2xl:text-[17px]'>Email Address</h6>
              <h6 className='font_normal text-[13px] 2xl:text-[16px]'>{email}</h6>
            </div>
          </div>

          <div data-aos="fade-bottom" data-aos-delay="300" className='flex  bg-slate-50 my-2 p-4 items-start'>
            <FaLocationDot className='text-[#027d9b]' size={24} /> 
            <div className='ml-2'>
              <h6 className='font_bold text-[14px] 2xl:text-[17px]'>Location</h6>
              <h6 className='font_normal text-[13px] 2xl:text-[16px]'>{address}</h6>
            </div>
          </div>
         
          </div>

        </div>

        <div className='md:w-[70%]'>
        <div data-aos="fade-left" className='w-[100%] md:h-[400px] 2xl:h-[650px] overflow-hidden'>
        <video autoPlay={true} muted={true} loop controls={false}  src={`https://fidecrmfiles.s3.amazonaws.com/project_videos/project_evaluation.mp4`} />  
        {/* <img className='w-[100%] md:h-[400px] 2xl:h-[650px] object-cover overflow-hidden hover:scale-110 duration-300'  src={service12Img} />   */}
        </div>
        <h6 data-aos="fade-bottom" className='text-[18px] 2xl:text-[21px] mt-5 font_bold'>Project Evaluation and Estimation</h6>
         
         <h6 data-aos="fade-bottom" className='font_normal text-[14px] 2xl:text-[17px] mb-2'>A PEB service is the total calculated value of construction of Pre-Engineered Buildings that a vendor provides to the customer. Such offers include the total value of the project's construction along with details like the material type and cost of accessories, sheet purlin, cost of transportation, GST, design parameters and so on. Most PEB companies in Bangalore quote their offers differently.</h6> 
         <h6 data-aos="fade-bottom" className='font_normal text-[14px] 2xl:text-[17px] mb-2'>Whether you are interested in reaching an investment decision at the conceptual stage, negotiating or finalizing an implementation contract, or implementing cost-control measures, we're here to support you. With our highly accurate project estimates, we can assist the builder in predicting the cost and management cost accurately.</h6> 
         <h6 data-aos="fade-bottom" className='font_normal text-[14px] 2xl:text-[17px] mb-2'>In addition to providing estimates, we also assist contractors and engineers with carefully considering all the specifications of your projects. This includes feasibility analysis, materials, equipment, design, labour, insurance, taxes, inspections, land purchase, holding, and improvement.</h6> 
         <h6 data-aos="fade-bottom" className='font_normal text-[14px] 2xl:text-[17px] mb-2'>Based on all the requirements, our experts comprehensively analyze the scope, time, cost, and quality and use multiple tools and apps to deliver an accurate estimation.</h6> 
         <h6 data-aos="fade-bottom" className='font_normal text-[14px] 2xl:text-[17px] mb-2'>There are six key areas of a project that benefit from the use of our project evaluation and estimating techniques:</h6> 
         <ul data-aos="fade-bottom" className='list-disc ml-2'>
            <li className='font_normal text-[14px] 2xl:text-[17px] mb-2'>Cost Planning at the Concept, Schematic and Detailed design stage :
            <br></br>
            <h6 className='font_normal text-[12px] 2xl:text-[15px] mb-2'>The Cost Planning phase begins with a designer meeting with the client to discuss the project's vision and overall goals. This discussion includes essential items such as project scope, the purpose of the construction and its functionality.</h6>
            </li>
            <li className='font_normal text-[14px] 2xl:text-[17px] mb-2'>Preparation of Bills of Quantities :
            <br></br>
            <h6 className='font_normal text-[12px] 2xl:text-[15px] mb-2'>While planning a construction project, you will need a bill of quantities (BOQ). Our experts create a BOQ that itemizes the specific materials and labour required for the project so you can get accurate bids.</h6>
            </li>
            <li className='font_normal text-[14px] 2xl:text-[17px] mb-2'>Preparation of Project Documents :
            <br></br>
            <h6 className='font_normal text-[12px] 2xl:text-[15px] mb-2'>Upon finalizing a project, our team forms a contract with the client for the construction work. The documentation requires that both parties observe certain legal formalities.</h6>
            </li>
            <li className='font_normal text-[14px] 2xl:text-[17px] mb-2'>Project evaluation & negotiation.
            <br></br>
            <h6 className='font_normal text-[12px] 2xl:text-[15px] mb-2'>Evaluation & negotiation in project management is a delicate phase. Our experts navigate unrealistic sponsor expectations or convince a team member to pick up the pace, and it's our job to find the best solution for your projects.</h6>
            </li>
            <li className='font_normal text-[14px] 2xl:text-[17px] mb-2'>On-site measurement Support
            <br></br>
            <h6 className='font_normal text-[12px] 2xl:text-[15px] mb-2'>We think beyond equipment: Our teams combine expertise in measurement and modelling with deep knowledge of operations. This means that we choose measurements and recommend correct, reliable, cost-effective, practical strategies for your operational situation.</h6>
            </li>
            <li className='font_normal text-[14px] 2xl:text-[17px] mb-2'>As-Built Bills of Quantities for Final Account Settlement :
            <br></br>
            <h6 className='font_normal text-[12px] 2xl:text-[15px] mb-2'>Under the terms and conditions of the contract, our experts provide all documents necessary for the final account preparation to ascertain the final account sum for the completion of projects.</h6>
            <br></br>
            <h6 className='font_normal text-[12px] 2xl:text-[15px] mb-2'>As project managers and owner's representatives, we are proud to be recognized as a trusted business partner on all construction projects, from concept to closeout.</h6>
            </li>    
         </ul>
         </div>
         </div>

    </div>
  )
}

export default ProjectEvaluation