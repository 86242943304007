import './App.css'
import Aos from 'aos';
import "aos/dist/aos.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import Footer from "./components/Footer";
import Navbar from "./components/Navbar";
import About from './pages/About';
import Home from "./pages/Home";

import {
  createBrowserRouter,
  RouterProvider
} from "react-router-dom";
import { useEffect,useState } from 'react';
import { Toaster } from 'react-hot-toast';
import Contact from './pages/Contact';
import Service from './pages/Services';
import Projects from './pages/Projects';
import Blogs from './pages/Blogs';
import NoData from './pages/NoData';
import Clients from './pages/Clients';
import Splash from './pages/Splash';
import BlogDetail from './pages/BlogDetail';
import ProjectDetail from './pages/ProjectDetail';
import RealEstate from './pages/RealEstate';
import HRManagement from './pages/HRManagement';
import FMSService from './pages/FMSService';
import CommercialInterior from './pages/CommercialInterior';
import MEPHvac from './pages/MEPHvac';
import ProjectEvaluation from './pages/ProjectEvaluation';
import TurnkeyProjectExecution from './pages/TurnkeyProjectExecution';
import FidelitusGallery from './pages/FidelitusGallery';
import { LuArrowUpSquare } from "react-icons/lu";

import ScrollToTop from 'react-scroll-up';
import Carrier from './pages/Carrier';
import CarrierDetail from './pages/CarrierDetail';

function App() {

  const [loader,setloader] = useState(true)

  const router = createBrowserRouter([
    {
      path: "/",
      element: (
        <div>
          <Navbar />
          <Home/> 
          <Footer/>
        </div>
      ),
    },
    {
      path: "/home",
      element: (
        <div>
          <Navbar />
          <Home/> 
          <Footer/>
        </div>
      ),
    },
    {
      path: "/about",
      element: <div>
        <Navbar />
          <About/> 
        <Footer/>
      </div>,
    },
    {
      path: "/services",
      element: <div>
        <Navbar />
          <Service /> 
        <Footer/>
      </div>,
    },
    {
      path: "/contact",
      element: <div>
        <Navbar />
          <Contact />
        <Footer/>
      </div>,
    },
    {
      path: "/projects",
      element: <div>
        <Navbar />
          <Projects />
        <Footer/>
      </div>,
    },
    {
      path: "/projects/detail/:id",
      element: <div>
        <Navbar />
          <ProjectDetail />
        <Footer/>
      </div>,
    },
    {
      path: "/clients",
      element: <div>
        <Navbar />
          <Clients />
        <Footer/>
      </div>,
    },
    {
      path: "/blogs",
      element: <div>
        <Navbar />
          <Blogs />
        <Footer/>
      </div>,
    },
    {
      path: "/blogs/detail/:id?",
      element: <div>
        <Navbar />
          <BlogDetail />
        <Footer/>
      </div>,
    },
    {
      path:"/whats-more/real-estate-transaction",
      element: <div>
      <Navbar />
        <RealEstate />
      <Footer/>
    </div>,
    },
    {
      path:"/whats-more/hr-labs",
      element: <div>
      <Navbar />
        <HRManagement />
      <Footer/>
    </div>,
    },
    {
      path:"/whats-more/facility-management-services",
      element: <div>
      <Navbar />
        <FMSService />
      <Footer/>
    </div>,
    },
    {
      path:"/whats-more/fidelitus-gallery",
      element: <div>
      <Navbar />
        <FidelitusGallery />
      <Footer/>
    </div>,
    },
    {
      path:"/services/commercial-interior-design-and-architecture",
      element: <div>
      <Navbar />
        <CommercialInterior />
      <Footer/>
    </div>,
    },
    {
      path:"/services/mep-hvac-and-networking",
      element: <div>
      <Navbar />
        <MEPHvac />
      <Footer/>
    </div>,
    },
    {
      path:"/services/project-evaluation-and-estimation",
      element: <div>
      <Navbar />
        <ProjectEvaluation />
      <Footer/>
    </div>,
    },
    {
      path:"/services/turnkey-project-execution-and-general-contracting",
      element: <div>
      <Navbar />
        <TurnkeyProjectExecution />
      <Footer/>
    </div>,
    },
    {
      path: "/career",
      element: <div>
        <Navbar />
          <Carrier />
        <Footer/>
      </div>,
    },
    {
      path: "/career_detail",
      element: <div>
        <Navbar />
          <CarrierDetail />
        <Footer/>
      </div>,
    },
    {
      path: "*",
      element: <div>
        <Navbar />
          <NoData />
        <Footer/>
      </div>,
    }
    
  ]);

  useEffect(()=>{
    setloader(true)
    setTimeout(() => {
      setloader(false)
    }, 2000);
    Aos.init();
  },[])



  return (
    <div className='relative w-[100vw]  overflow-x-hidden'>
      <Toaster  position="bottom-center" toastOptions={{
          success: {
            style:{
              background:'black',
              color:'white',
              padding:'4px 7px',
              fontSize:'12px',
              borderRadius:'2px',
              iconTheme: {
                fontSize:'4px'
              }
            }
          },
          error: {
            style:{
              background:'black',
              color:'white',
              padding:'4px 7px',
              fontSize:'12px',
              borderRadius:'2px',
              iconTheme: {
                fontSize:'4px'
              }
            }
          }
        }}  />
        {loader ?
        <Splash /> :
        <RouterProvider router={router} />}

      {/* <h1>Hello</h1> */}
        

        <ScrollToTop showUnder={260}>
            <div className='bg-[#027d9b] group p-[10px] rounded z-50'>
             <LuArrowUpSquare size={20} className='z-30 group-hover:animate-spin text-white opacity-100' />
            </div>
        </ScrollToTop>

        {/* <div className='bg-[#027d9b] fixed shadow-lg right-4 bottom-4 px-2 h-[34px] p-[4px] flex items-center w-max text-white rounded'>
        <BiMessageRoundedDetail size={20} />
        </div> */}
    </div>
  );
}

export default App;
