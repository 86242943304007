import { PublicApiClient } from "../utils/ApiConfig";

export const GetBlogServices = (page=1,text='') => {
    return PublicApiClient.get(`/project_website/blogs/get?page=${page}&text=${text}`);
  };
  

  export const GetBlogDetailServices = (id,page=1,text='') => {
    return PublicApiClient.get(`/project_website/blogs/get/${id}?page=${page}&text=${text}`);
  };
  