import React,{useEffect, useState, useRef } from 'react'
import { bannerArr, bannerDescriptionArr, base_url, blogArr, brigadeTechPark, certificatesArr, clientsArr, department, entranceLogo, flow1Arr, flowActionArr, fprojectBannerZif, howWeDifferent, industrySectionArr, interiorImg, mapLogo, procedureImg, projectArr, redLeafImg, servicesArr, trustedClientArr } from '../constants/AppConstant'
import { BsStarFill,BsStarHalf } from "react-icons/bs";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import { IoIosArrowBack,IoIosArrowForward } from 'react-icons/io';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import Slider from "react-slick";
import {BiError} from 'react-icons/bi'
import {BsArrowLeftCircle,BsArrowRightCircle} from 'react-icons/bs'
import { MdArrowRightAlt } from "react-icons/md";
import {Steps,Modal,Select} from 'antd'
import CountUp from 'react-countup';
import axios from 'axios';
import {message} from 'antd'
import { GetBlogServices } from '../services/BlogServices';
import { GetVideoServices } from '../services/VideoServices';
import toast from 'react-hot-toast';
import videoPlay from '../images/video1.mp4'

function Home() {

  const videoRef = useRef(null);

  function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
    <BsArrowLeftCircle 
    className={`${className} -mt-0  md:-mt-2 `}
    style={{ ...style, display: "block",color:'#ddd',}}
    onClick={onClick} />
    );
  }
  
  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <BsArrowRightCircle 
          className={`${className} -mt-0 md:-mt-2 `}
          style={{ ...style, display: "block",color:'#ddd',}}
          onClick={onClick} />
    );
  }

  useEffect(() => {
    const video = videoRef.current;
    if (video) {
      // Attempt to play the video
      video.play().catch(error => {
       toast.error("Oops we couldn't play the video for you!")

        // Handle error (e.g., show a play button)
        // console.log('Autoplay prevented. Showing play button.');
      });
    }
}, []);

  const [index,setindex] = useState(0)
  const [modal1,setmodal1] = useState(false)  
  const [datas,setdatas] = useState([])  
  const [projects,setprojects] = useState([])  


  const [currentStep,setcurrentStep] = useState(0)
  const navigator = useNavigate()

  const testimonials = [
       {
         value:"I was looking for an office space. I reached Fidelitus and from finalising a prime location to designing a functional office space, they did it all with perfection. ",
         place:"Delhi, India",
         name:"Prajwal Desai"
       },
       {
         value:"While looking for some urgent recruits for a project, we contacted Fidelitus and we had multiple potential candidates in no time. ",
         place:"Banglore, India",
         name:"R.K Kini"
       },
       {
         value:"My experience with Fidelitus as a budgeting client has been commendable. I highly appreciate their transparent and practical approach to managing finances.",
         place:"Banglore, India",
         name:"Kiran S. Patil"
       }
  ]

  const settings = {
    dots: false,
    arrows: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 6,
    slidesToScroll: 6,
    initialSlide: 0,
    centerMode: true,
    cssEase:"linear",
    autoplaySpeed: 2000,
    centerPadding: '0px',
    autoplay:true,
    prevArrow:<SampleNextArrow />,
    nextArrow:<SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 8,
          autoPlay:true
         
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
          autoplay:true

        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
          autoplay:true
        }
      }
    ]
  };

  async function openDetail(v){
    navigator(`/blogs/detail/${v?._id}`)
  }

  const stepsData = [
    {label:'Commercial Interior',value:'Commercial Interior'},
    {label:'Architecural Services',value:'Architecural Services'},
    {label:'MEP / HVAC and Networking Services',value:'MEP / HVAC and Networking Services'},
    {label:'Project Evaluation and Estimation',value:'Project Evaluation and Estimation'},
    {label:'Turnkey Project Execution',value:'Turnkey Project Execution'},
    {label:'General Contracting Services',value:'General Contracting Services'},
    {label:'General Enquiry',value:'General Enquiry'}
  ]

  const steps = [
    {
      title: 'Meet Us',
      description:'',
      description1:"Let us know more about each other. We can meet over a cup of coffee or even online too. We'd like to know what you're looking for"
    },
    {
      title: 'Scope of Work',
      description:'',
      description1:"Now when you have made up your mind, it is the time to finalise the scope of work and talk about the essentials – materials and finishes, etc."

    },
    {
      title: 'Seal the Deal',
      description:'',
      description1:"Once finalised, you can seal the deal by paying a signing amount of 10%. Pay 50% of the order value to kickstart the process and the balance before handover."

    },
    {
      title: 'Execution',
      description:'',
      description1:"While we immediately begin work on your different orders, our quality control team keeps a strict check. We also keep you updated about the execution process."
    },
    {
      title: 'Stamp of Approval',
      description:'',
      description1:"Congratulations! We are done. You can now move into your dream home."
    },

  ];

  const [data,setdata] =  useState({name:'',mobile:'',email:'',requirment:'',service:''})
  const [error,seterror] =  useState({name:'',mobile:'',email:'',requirment:'',service:''})

  const stepsArr = [
    {label:'Commercial Interior',value:'Commercial Interior'},
    {label:'Architecural Services',value:'Architecural Services'},
    {label:'MEP / HVAC and Networking Services',value:'MEP / HVAC and Networking Services'},
    {label:'Project Evaluation and Estimation',value:'Project Evaluation and Estimation'},
    {label:'Turnkey Project Execution',value:'Turnkey Project Execution'},
    {label:'General Contracting Services',value:'General Contracting Services'},
    {label:'General Enquiry',value:'General Enquiry'}
  ]

  function handleChange(e){
    setdata({...data,[e.target.name]:e.target.value})
    seterror({...error,[e.target.name]:''})
  }

  useEffect(()=>{
    getData()
  },[])

  async function getData(){
    const response = await GetBlogServices(1,'')
    const response1 = await GetVideoServices(1,'')
    setdatas(response?.data?.datas)
    setprojects(response1?.data?.datas)
    // if(response?.data?.datas?.length > 0){
    //   setsingleData(response?.data?.datas[0])
    // }
  }

  async function submitForm(){
    if(!data?.name){
      seterror({...error,name:'This Field is required *'})
    }else if(!data?.email){
      seterror({...error,email:'This Field is required *'})
    }else if(!data?.requirment){
      seterror({...error,requirment:'This Field is required *'})
    }else{
     
      const sendData = {...data}
      sendData['requirment'] = `Service : ${data?.service}  Message : ${data?.requirment}`
      sendData['department_website'] = department
      
      await axios.post(`${base_url}clients/create`,sendData)
      .then((res)=>{
        if(res?.status === 201){
          setmodal1(false)
          message.success('Estiamte Request Created!')
          setdata({name:'',mobile:'',email:'',requirment:'',department_website:'',service:''})
          seterror({name:'',mobile:'',email:'',requirment:'',department_website:'',service:''})
        }
      }).catch((err)=>{   
        message.success('Estiamte Request Created!')
        setmodal1(false)

      }) 
    }
  }

  async function openDetail(v){
    navigator(`/blogs/detail/${v?._id}`)
  }
  
  
  return (
    <div className='w-screen overflow-x-hidden'>
           
          {/* request modal */}
          <Modal width={320} open={modal1} footer={false} closable={false}>
            <div>
                <h6 className='text-[15px] 2xl:text-[18px] font-[700]'>Enquiry Form</h6>
                <h6 className='text-[13px] 2xl:text-[16px] leading-[18px] bg-slate-100 p-2 my-2'>Would you like to have a estimate based on the service just complete below survey form.</h6>
              
                <div data-aos="fade-up" className='flex items-center'>
                  <div className='w-[48%] mr-[2%]'>
                    <h6 className='text-[12px] 2xl:text-[15px]  font_bold font-[900]'>Name</h6>
                    <input value={data?.name} name="name" onChange={handleChange} className='border w-full p-1 mt-1 pl-4 text-[14px] 2xl:text-[17px] py-1.5  focus:outline-0' />
                    {error?.name && <h6 className='flex  items-center text-[10px] 2xl:text-[13px] text-red-600 mt-0.5'><BiError className='mr-1'/> {error?.name}</h6>}
                  </div> 
                  <div className='w-[48%] ml-[2%]'>
                    <h6 className='text-[12px] font_bold font-[900]'>Mobile</h6>
                    <input value={data?.mobile} name="mobile" onChange={handleChange} className='border w-full p-1 mt-1 pl-4 text-[14px] 2xl:text-[17px] py-1.5  focus:outline-0' />
                    {error?.mobile && <h6 className='flex items-center text-[10px] 2xl:text-[13px] text-red-600 mt-0.5'><BiError className='mr-1'/> {error?.mobile}</h6>}
                  </div> 
                </div> 

                <div className='w-[100%] mt-[2%]'>
                    <h6 className='text-[12px] font_bold font-[900]'>Email</h6>
                    <input value={data?.email} name="email" type="email" onChange={handleChange} className='border w-full p-1 mt-1 pl-4 text-[14px] 2xl:text-[17px] py-1.5  focus:outline-0' />
                    {error?.email && <h6 className='flex items-center text-[10px] 2xl:text-[13px] text-red-600 mt-0.5'><BiError className='mr-1'/> {error?.email}</h6>}
                </div>  

                <div className='w-[100%] mt-[2%]'>
                    <h6 className='text-[12px] font_bold font-[900]'>Service</h6>
                    <div className='border w-full p-1 mt-1  text-[12px] py-1.5  focus:outline-0' >
                    <Select value={data?.service} options={stepsData} onChange={(v)=>setdata({...data,service:v})} bordered={false} className='w-full h-[18px] 2xl:h-[21px]'/>
                    </div>
                    {error?.email && <h6 className='flex items-center text-[10px] 2xl:text-[13px] text-red-600 mt-0.5'><BiError className='mr-1'/> {error?.email}</h6>}
                </div>  

                <h6 className='text-[12px] font_bold font-[900] mt-1'>Detail info</h6>
                  <textarea value={data?.requirment} name="requirment" onChange={handleChange} className='border w-full p-1 mt-1 pl-4 text-[14px] 2xl:text-[17px] py-1.5  focus:outline-0' />
                  {error?.requirment && <h6 className='flex items-center text-[10px] 2xl:text-[13px] text-red-600 mt-0.5'><BiError className='mr-1'/> {error?.requirment}</h6>}

                  {/* <h6 onClick={submitForm} className='transition w-28 text-center mt-4 delay-300 duration-300 ease-in-out  border border-[#e87818] text-[#e87818] hover:text-[#fff]  py-[5px] px-3 cursor-pointer hover:bg-[#e87818] hover:text-white font-[300] font_thick text-[13px]'>CONTACT US</h6> */}



                <div className='flex items-center justify-end mt-5'>
                    <h6 onClick={()=>setmodal1(!modal1)} className='border cursor-pointer border-slate-300 text-[12px] 2xl:text-[15px] font_bold text-black p-1 px-2 rounded w-max mr-2'>Close</h6>
                    <h6 onClick={submitForm} className='bg-[#027d9b] cursor-pointer text-[12px] 2xl:text-[15px] font_bold text-white p-1 px-2 rounded w-max'>Submit</h6>
                </div>
            </div>
          </Modal>

          {/* carousel */}
          <div className='relative'>
            {/* <Carousel 
              centerMode={true} 
              autoPlay={true} 
              showStatus={false}
              showThumbs={false}
              showArrows={true}
              swipeable={true}
              autoFocus={true}
              interval={5000}
              infiniteLoop={true}
              centerSlidePercentage={100}
              showIndicators={false}
              className='relative -z-0'
            
              onChange={(e)=>{
                setindex(e)
              }}
              
              renderArrowPrev={(clickHandler, hasPrev) => {
                return (
                  <div
                    className={`${hasPrev ? "opacity-100" : "opacity-100"} absolute backdrop-filter  bg-white/60 backdrop-blur-sm left-[20px] rounded bottom-[40%] md:bottom-[40%] z-100   flex justify-center items-center p-2  cursor-pointer z-20`}
                    onClick={clickHandler}
                  >
                    <IoIosArrowBack size={14} className="text-black" />
                  </div>
                );
              }}
              renderArrowNext={(clickHandler, hasPrev) => {
                return (
                  <div
                    className={`${hasPrev ? "opacity-100" : "opacity-100"} absolute backdrop-filter  bg-white/60 backdrop-blur-sm  right-[20px] rounded md:right-4 bottom-[40%] md:bottom-[40%]   flex justify-center items-center p-2  cursor-pointer z-20`}
                    onClick={clickHandler}
                  >
                    <IoIosArrowForward size={14} className="text-black" />
                  </div>
                );
              }}
            
            >
                {bannerArr?.map((c,i)=>(
                    <div className='text-center relative flex justify-end w-[100%]  md:h-[91vh]  overflow-hidden z-1  mx-auto focus:outline-none focus:ring-0'>
                        <img src={c}  className='w-full  relative h-[35vh] md:h-[91vh] object-cover md:object-stretch focus:outline-none focus:ring-0 '/>
                        <div className='w-full  absolute bg-black/40 h-[35vh] md:h-[91vh] object-cover md:object-stretch focus:outline-none focus:ring-0 ' />  
                        <div className='absolute left-[16%] md:left-[14%] top-[12%] md:top-[35%] w-[70%] md:w-[40%]'>
                           <h6 className='bg-[#027d9b] text-[13px] md:text-[18px] w-fit p-[5px] md:p-[10px] text-center font_bold text-white'>{bannerDescriptionArr[i].tag}</h6>
                           <h6 className='font_bold text-white text-left text-[22px] md:text-[44px]' style={{color:bannerDescriptionArr[i]?.color}}>{bannerDescriptionArr[i].heading}</h6>
                           <h6 className='text-[15px] md:text-[17px] font_normal text-left text-white'  style={{color:bannerDescriptionArr[i]?.color}}>{bannerDescriptionArr[i].description}</h6>
                           <div>
                             <h6 onClick={()=>navigator(bannerDescriptionArr[i]?.path)} className='bg-[#e77817] cursor-pointer text-[14px] text-white mt-2 w-max rounded px-[10px] font_bold p-[5px]'>Know More</h6>
                           </div>
                        </div>
                    </div>    
                ))}




              
            </Carousel> */}
            <video  ref={videoRef} id="video"  width="100%"  controls={false}  autoPlay playsInline loop muted className="w-full md:h-[90vh] object-cover">
               <source src={videoPlay} type="video/mp4"/> 
            </video>
          
          </div>
          
        
         
          {/* about us  */}
          <div className='mt-20 md:mt-0'>
            <div className='block md:flex  md:mx-[10%] mt-[6%]'>
              <div data-aos="fade-right" className='md:w-[50%] z-20'>
                <img src={fprojectBannerZif} className='mt-[45px] w-[100%] object-contain ' />
              </div>

              <div className='-mt-[1%] relative  md:pl-[4%] px-[5%] md:px-[4%] py-[10%] w-[100%]  md:py-[2%] relative md:w-[60%] z-10'>
                <div data-aos="fade-top" className='animate-bounce w-[40px] h-[60px] absolute border-[10px] border-[#027d9b] absolute top-[-20px] right-[5%] md:right-[40%]'>
                </div>
                <div>
                  <h6 data-aos="fade-left" className='text-[25px] 2xl:text-[28px] font_bold'>About Us</h6>
                  <h6 data-aos="fade-left" data-aos-delay="300" className='md:w-[90%] font_normal text-[14px] 2xl:text-[17px] mt-2'>Fidelitus Projects is a leading Design-Build / General Contracting Commercial and industrial infrastructure service provider based in Bengaluru. Our team of experienced industry professionals helps you grab every growth development opportunity in Turnkey commercial interior & industrial infrastructure services. </h6>
                  <h6 data-aos="fade-left" data-aos-delay="300" className='md:w-[90%] font_normal text-[14px] 2xl:text-[17px] mt-2'>Also as a leading brand in Bangalore, we showcase our expertise not just in above mentioned fields but also in Project Management, Quantification, Budgeting, Procurement, Turnkey Projects, HR Management, Facility Management Services, Real Estate Transactions.</h6>
                  <h6 onClick={()=>navigator('/about')} data-aos="fade-left" data-aos-delay="600" className='font_bold text-[12px] 2xl:text-[15px] border mt-2 w-[100px] 2xl:w-[120px] text-center p-[5px] border-black hover:bg-black hover:text-white cursor-pointer duration-300'>Know More</h6>
                </div>
              </div>
            </div>

          {/* steps us  */}
          <div className='bg-slate-100 mt-14'>
            <div className='grid grid-cols-4 px-[4%] md:px-[10%] py-[6%] md:py-[2%]'>
            {industrySectionArr?.map((i,i1)=>(
                <div data-aos="fade-bottom" data-aos-delay="300" className={`flex flex-col md:flex-row items-center md:justify-center ${i1 !==  industrySectionArr?.length -1 && ' md:border-r border-gray-300' }`}>
                  <i.icon className='text-[25px]  md:text-[40px] 2xl:text-[50px] text-gray-700' />
                  <div className='ml-3'>
                  <h6 className='font_bold mt-1 text-center text-[12px] md:text-[15px] md:text-[23px] 2xl:text-[30px]'>{<CountUp start={0} end={i?.value} />} + {i1 ===  industrySectionArr?.length-1 && 'Years'} </h6>
                  <h6 className='text-[10px] md:text-[13px] 2xl:text-[18px] -mt-[4px] font_normal'>{i?.label}</h6>
                  </div>  
                </div>  
              ))}
            </div>

            <div className='grid w-[80%] pb-10  md:mt-0 gap-5 mx-auto py-5 md:py-10 grid-cols-2 md:grid-cols-6'>

              <div data-aos="fade-right" className='bg-[#027d9b] shadow-md hover:scale-105 duration-200 text-white p-4 rounded-[10px]'>
                 <h6 className='font_bold text-[14px] 2xl:text-[17px]'>Complete Turnkey Services</h6>
                 <h6 className='text-[11px] 2xl:text-[14px] mt-4 font_normal '>With an emphasis on details, extraordinary designs and exceptional customer service, we bring your dreams to life.</h6>

                 {/* <div className='flex cursor-pointer  items-center mt-4'>
                   <h6 className='font_bold cursor-pointer text-[11px] mr-2'>KNOW MORE</h6>
                   <MdArrowRightAlt />
                 </div> */}
              </div>

              {flow1Arr?.map((f)=>(
                <div data-aos="fade-left" className='bg-white shadow-md hover:scale-105 duration-200 flex flex-col items-center justify-center rounded-[10px] p-2'>
                  <img className='w-[100px] h-[100px] 2xl:w-[130px] 2xl:h-[200px] object-contain' src={f?.image} />
                  <h6 className='mt-1 font_bold 2xl:text-[23px]'>{f?.heading}</h6>
                  {/* <h6 className='text-[13px] font_normal text-center'>{f?.description}</h6> */}
                </div>  
              ))}


            </div>
          </div>

          {/* why us  */}
          <div className='bg-white py-[10%] md:py-[5%]  bg-slate-100 border-t border-slate-100'>
          <h6 className='text-center text-[20px] 2xl:text-[23px] font_bold'>MISSION <span className='text-[#027d9b]'> & </span> VISION</h6>
          <div className='flex justify-center items-center'>
                <h6 data-aos="fade-right" className='w-[50px] border-b-[2px] rounded-[10px] border-[#027d9b]'></h6>
                <h6 data-aos="fade-up" data-aos-delay="300" className='text-[10px] 2xl:text-[13px] font_bold text-[#027d9b] mx-2 uppercase'>WHY WE SUCCEED</h6>
                <h6 data-aos="fade-left"className='w-[50px] border-b-[2px] rounded-[10px] border-[#027d9b]'></h6>
          </div>
          <h6 data-aos="dat-left" className='text-[13px] 2xl:text-[16px] mt-2 font_normal w-[80%] text-center mx-auto'>We aim to delight and surprise our clients, <span className='font_bold'>deliver exceptional service, and positively exceed their expectations</span>  at every step of the project. Our goal is to make the project process memorable and one that our clients want others to experience.</h6>

          {/* <div className='w-[100%] md:w-[100%] mt-10 px-[10%] gap-4 mx-auto grid md:grid-cols-3'>
            {flowActionArr?.map((f,i)=>(
              <div data-aos="fade-up" className={`p-4 shadow bg-white ${(i !== flowActionArr?.length - 1) && ' border-gray-200'}`} style={{background:f?.color}}>
                <div className='flex items-center'>
                    <img src={f?.img} className='w-[50px]  h-[50px] object-contain' />
                <h6 className='font_bold text-center ml-2 mt-[10px] text-[16px]'>{f?.heading}</h6>
                </div>
                <h6 className='font_normal mt-2 text-[12.5px]'>{f?.description}</h6>
              </div>  
            ))}
          </div> */}
          </div>

        

          {/* services */}
          <div className='mt-[2%] py-[5%] bg-slate-100'>
            <h6 className='text-center font_bold text-[25px] 2xl:text-[28px]'>Our <span className='text-[#027d9b]'>Services</span></h6>
            <div className='flex justify-center items-center'>
                <h6 data-aos="fade-right" className='w-[50px] border-b-[2px] rounded-[10px] border-[#027d9b]'></h6>
                <h6 data-aos="fade-up" data-aos-delay="300" className='text-[10px] 2xl:text-[13px] font_bold text-[#027d9b] mx-2 uppercase'>WHAT WE PROVIDE</h6>
                <h6 data-aos="fade-left"className='w-[50px] border-b-[2px] rounded-[10px] border-[#027d9b]'></h6>
            </div>
            <h6 className='text-[13px] 2xl:text-[18px] mt-2 font_normal w-[90%] text-center mx-auto'>The premier services we offer to ensure our clients excellence</h6>
            
            <div className='grid grid-cols-2 md:grid-cols-4 px-[5%] md:px-[10%] gap-2 md:gap-5 py-[2%]'>
            {servicesArr?.map((s)=>(
              <div onClick={()=>navigator(s?.path)} data-aos="fade-right"  className='border bg-white shadow p-2 md:p-0 rounded-[5px] hover:scale-105 border-slate-100 relative p-[4px] flex flex-col md:flex-row items-center px-2 py-4 md:py-5'>
                  <img src={s?.image} className='-ml-5 min-w-[100px] max-w-[200px] h-[50px] 2xl:h-[75px] object-contain' />
                  <h6 className='font_bold  md:-ml-2 mt-2 md:mt-0 text-center  md:text-left text-[13px] 2xl:text-[16px]'>{s?.label}</h6>
                  <img src={redLeafImg} className='absolute right-0 bottom-0' />
              </div>  
            ))}
            </div>
          </div>

          {/* certified  */}
          <div className=' py-10 mt-10 border-b border-slate-100 2xl:border-slate-200  mt-0'>
          <h6 data-aos="fade-right"  className='text-center md:w-[35%] text-[19px] z-20 mt-4 text-[25px] 2xl:text-[28px] mx-auto font_bold'>ISO <span className='text-[#027d9b]'>Certified </span> </h6>
          <div className='flex justify-center items-center'>
                <h6 data-aos="fade-right" className='w-[50px] border-b-[2px] rounded-[10px] border-[#027d9b]'></h6>
                {/* <h6 data-aos="fade-up" data-aos-delay="300" className='text-[10px] font_bold text-[#027d9b] mx-2 uppercase'>WHY WE SUCCEED</h6> */}
                <h6 data-aos="fade-left"className='w-[50px] border-b-[2px] rounded-[10px] border-[#027d9b]'></h6>
          </div>


              <div className='grid w-[80%] md:w-[60%] 2xl:w-[50%] mx-auto grid-cols-2 md:grid-cols-4 gap-10 md:gap-20 2xl:gap-24 my-10'>
                {certificatesArr?.map((c)=>(
                  <img data-aos="fade-up" src={c} key={c}  />
                ))}

              </div>

          </div>

            {/* company located */}
          <div className='my-10 w-[90%] mx-auto bg-contain bg-no-repeat bg-contain bg-left'>
         
         <h6 data-aos="fade-right"  className='text-center md:w-[35%] text-[19px] z-20  text-[25px] 2xl:text-[28px] mx-auto font_bold'>Company <span className='text-[#027d9b]'>Presence </span></h6>
           <div className='flex justify-center items-center'>
               <h6 data-aos="fade-right" className='w-[50px] border-b-[2px] rounded-[10px] border-[#027d9b]'></h6>
               <h6 data-aos="fade-up" data-aos-delay="300" className='text-[10px] 2xl:text-[13px] font_bold text-[#027d9b] mx-2 uppercase'>Our Location</h6>
               <h6 data-aos="fade-left"className='w-[50px] border-b-[2px] rounded-[10px] border-[#027d9b]'></h6>
             </div>

             
             <div className='md:flex mt-10 md:mt-0 items-center justify-center'>
             <div data-aos="fade-right" className='md:w-[50%] min-blend-multiply'>
                 <img src={mapLogo} className='w-[100%] h-[350px] 2xl:h-[500px] object-contain ' />
             </div>


             <div className='md:w-[50%] md:-ml-10 md:-mt-24'>
               <h6 data-aos="fade-up"  className='text-[18px] 2xl:text-[21px] mt-2 mb-5 font_bold'>HeadQuartered In Bangalore</h6>
               <h6 data-aos="fade-up" className='text-[14px] 2xl:text-[15px] mt-1 font_normal'>We Execute Projects across india, Including Tier 2 and 3  Cities.</h6>
               <h6 data-aos="fade-up" className='text-[14px] 2xl:text-[15px] mt-1 font_normal'>Major Projects executed across Banglore, Chennai, Hyderbad, Vizag, Kochi, Delhi, Rajasthan and Pune.</h6>
             </div>
             </div>

          </div>


            
          
          </div>  

        

          {/* we different */}
          <div className='mb-14   py-[10%] md:py-[5%] border-b bg-slate-100 border-slate-100'>
              
          <h6 className='text-center text-[20px] 2xl:text-[23px] font_bold'>HOW ARE <span className='text-[#027d9b]'> WE </span> DIFFERENT</h6>
          <div className='flex justify-center items-center'>
                <h6 data-aos="fade-right" className='w-[50px] border-b-[2px] rounded-[10px] border-[#027d9b]'></h6>
                <h6 data-aos="fade-up" data-aos-delay="300" className='text-[10px] 2xl:text-[13px] font_bold text-[#027d9b] mx-2 uppercase'>WE FOCUS ON EACH STEP</h6>
                <h6 data-aos="fade-left"className='w-[50px] border-b-[2px] rounded-[10px] border-[#027d9b]'></h6>
          </div>
          <div className='md:flex py-5 items-center justify-center'>
            <div className='md:w-[50%]'> 
              <img src={howWeDifferent}  data-aos="fade-right" className=' w-[100%] md:ml-10 mix-blend-multiply h-[280px] 2xl:h-[400px] object-contain' />  
            </div> 

            <div className=' pl-10 md:pl-0 mt-10 md:mt-0 md:w-[50%] md:-ml-10'>
                <h6  data-aos="fade-up" className='mb-4 font_bold text-[15px] 2xl:text-[18px] md:w-[70%]'>DESIGN-BUILD industrial and commercial infrastructure services is what we offer, how are we different? </h6>
                <h6  data-aos="fade-up" className='text-[14px] 2xl:text-[17px] mb-2 font_normal'>As a growing company, we offer : </h6>
                <ul className='list-disc ml-10'>
                  <li  data-aos="fade-up" className='text-[14px] 2xl:text-[17px] mb-2 font_normal'>liberty to experiment outside the said mandates </li>
                  <li  data-aos="fade-up" className='text-[14px] 2xl:text-[17px] mb-2 font_normal'>Best talent acquisition</li>
                  <li  data-aos="fade-up" className='text-[14px] 2xl:text-[17px] mb-2 font_normal'>Tailor-made services that cater to our client's specific wants and needs.  </li>
                </ul>
            </div>
          </div>  
          </div>

          {/* how we work */}
          {/* <div className='py-[10%]  md:py-[5%] px-[5%]  md:px-[10%] md:flex'>
              
              <div className='md:w-[50%] flex items-center justify-center'>
              <img src={procedureImg} className='w-[80%] md:w-[60%]' /> 
              </div>
              <div className='flex flex-col mt-10 md:mt-0 items-center justify-center w-[100%]  md:w-[50%] md:max-w-[50%]'>
              <div className='mb-2'>
                <h6 className='font_bold text-center text-[17px]'>The Way We Work</h6>
                <h6 className='text-[14px] text-center mb-4 font_normal'>Five steps is all it takes to elevate your style of living.</h6>
              </div>
              <div className='-ml-8 md:ml-0'>
              <Steps responsive={false} direction={'horizontal'} current={currentStep} size='small' onChange={(v)=>setcurrentStep(v)} labelPlacement="vertical" items={steps} />
              </div>
              <div className='flex flex-col items-center justify-center mt-5'>
                  <h6 className='text-[14px] font_bold'>{steps[currentStep]?.title}</h6>
                  <h6 className='text-[13px] font_normal w-[90%] md:w-[70%] mx-auto text-center'>{steps[currentStep]?.description1}</h6>
              </div>
              </div>
          </div> */}

 {/* brand  */}
 <div className=' py-10 mt-14 border-b border-slate-100 2xl:border-slate-200  mb-10 '>
          <h6 data-aos="fade-right"  className='text-center md:w-[35%] text-[19px] z-20 mt-4 text-[25px] 2xl:text-[28px] mx-auto font_bold'>Our <span className='text-[#027d9b]'>Brand Partners </span> </h6>
            <div className='flex justify-center items-center'>
                <h6 data-aos="fade-right" className='w-[50px] border-b-[2px] rounded-[10px] border-[#027d9b]'></h6>
                <h6 data-aos="fade-up" data-aos-delay="300" className='text-[10px] 2xl:text-[13px] font_bold text-[#027d9b] mx-2 uppercase'> Our Clients</h6>
                <h6 data-aos="fade-left"className='w-[50px] border-b-[2px] rounded-[10px] border-[#027d9b]'></h6>
              </div>

            <h6 data-aos="fade-left"  className='text-[15px] 2xl:text-[18px] mt-2 font_normal w-[90%] z-20 text-center mx-auto'>The clients who believed in us and helps us to reach what we are!. </h6>
              
            <div className='px-10 md:px-0 w-[90%] my-5 md:w-[80%] mx-auto items-center'>
            <Slider {...settings}>
              {clientsArr?.map((t)=>(
                <div key={t?.id} style={{mixBlendMode:'multiply'}} className='flex relative items-center justify-center focus:outline-none focus:ring-0 '>
                <img src={t} style={{mixBlendMode:'multiply'}} className='focus:outline-none w-[70%] h-[120px] 2xl:h-[150px] object-contain focus:ring-0 ' />
                </div>

              ))}
              </Slider>
            </div>
          </div>
         

          {/* projects */}
          <div className='pb-0'>
          <h6 data-aos="fade-right"  className='text-center md:w-[35%] text-[19px] z-20  text-[25px]  2xl:text-[28px]  mx-auto font_bold'>Latest <span className='text-[#027d9b]'>Projects </span></h6>
            <div className='flex justify-center items-center'>
                <h6 data-aos="fade-right" className='w-[50px] border-b-[2px] rounded-[10px] border-[#027d9b]'></h6>
                <h6 data-aos="fade-up" data-aos-delay="300" className='text-[10px]  2xl:text-[13px]  font_bold text-[#027d9b] mx-2 uppercase'>Completed Project</h6>
                <h6 data-aos="fade-left"className='w-[50px] border-b-[2px] rounded-[10px] border-[#027d9b]'></h6>
              </div>
            <div className='w-[80%] mx-auto  grid md:grid-cols-3 gap-10 my-[5%]'>
            {projects?.slice(0,3)?.map((p)=>(

              <div onClick={()=>navigator(`/projects/detail/${p?._id}`,{state:p})} data-aos="fade-bottom" className='h-[400px] 2xl:h-[500px] cursor-pointer relative overflow-hidden group'>
                <img className='h-[100%] w-[100%] object-cover group-hover:scale-110 duration-300' src={`${process.env.REACT_APP_AWS_IMAGE_URL}${p?.banner}`} />
                <h6 className='backdrop-filter bg-white/20 backdrop-blur-sm top-2 right-2 absolute text-[11px]  2xl:text-[13px]  font_bold p-[4px]'>{p?.project_name}</h6>
                <div className='backdrop-filter absolute bottom-8 w-[90%] left-5 pl-2 py-2 bg-white/20 backdrop-blur-sm  '>
                <h6 className='font_bold text-[14px] 2xl:text-[17px]'>{p?.nature_of_the_project}</h6>
                <h6 className='text-[13px] 2xl:text-[16px] font_bold'>SQFT : {p?.total_sqft}</h6>
                </div>
              </div>  
            ))}
            </div> 
        
        
          </div>

           

        
          {/* testimonials */}
          <div className='py-10 2xl:py-20 bg-[#ffedcf]  relative bg-testimonials_bg bg-center bg-no-repeat  bg-cover'>

            <h6 data-aos="fade-right"  className='text-center md:w-[35%] text-[19px] z-20 mt-4 text-[25px] 2xl:text-[28px] mx-auto font_bold'>Public <span className='text-[#027d9b]'>Cheers </span> for Us!</h6>
            <div className='flex justify-center items-center'>
                <h6 data-aos="fade-right" className='w-[50px] border-b-[2px] rounded-[10px] border-[#027d9b]'></h6>
                <h6 data-aos="fade-up" data-aos-delay="300" className='text-[10px] 2xl:text-[13px] font_bold text-[#027d9b] mx-2 uppercase'>Client Testimonials</h6>
                <h6 data-aos="fade-left"className='w-[50px] border-b-[2px] rounded-[10px] border-[#027d9b]'></h6>
              </div>

            <h6 data-aos="fade-left"  className='text-[15px] 2xl:text-[18px] mt-2 font_normal w-[90%] z-20 text-center mx-auto'>Find out how our customers are spreading the word!. </h6>

              <div className='grid md:grid-cols-3 pt-5 mx-auto w-[80%] gap-5 mx-auto'>
                {testimonials?.map((t)=>(
                    <div className='shadow-md p-5 bg-white  duration-300 hover:border-[#027d9b] hover:scale-105 cursor-pointer'>

                      <h6 className='text-[15px] font_bold 2xl:text-[18px]'>{t?.name}</h6> 
                      <h6 className='text-[13.5px] 2xl:text-[16.5px] mt-2 font_normal'>{t?.value}</h6>

                      <div className='flex items-center'>
                        <BsStarFill size={13} className='mr-1 text-[#ffe46e]' />
                        <BsStarFill size={13} className='mr-1 text-[#ffe46e]' />
                        <BsStarFill size={13} className='mr-1 text-[#ffe46e]' />
                        <BsStarFill size={13} className='mr-1 text-[#ffe46e]' />
                        <BsStarHalf size={13} className='text-[#ffe46e]' />
                      </div>   
                    </div>   
                ))}
            </div>
          </div>

          {/* blogs  */}
          <div className='pt-10   relative  bg-center bg-no-repeat  bg-contain'>
            
            <h6 data-aos="fade-right"  className='text-center md:w-[35%] text-[19px] z-20 mt-4 text-[25px] 2xl:text-[28px] mx-auto font_bold'>Latest <span className='text-[#027d9b]'>Blogs </span> </h6>
            <div className='flex justify-center items-center'>
                <h6 data-aos="fade-right" className='w-[50px] border-b-[2px] rounded-[10px] border-[#027d9b]'></h6>
                <h6 data-aos="fade-up" data-aos-delay="300" className='text-[10px] 2xl:text-[13px] font_bold text-[#027d9b] mx-2 uppercase'>Success Stories</h6>
                <h6 data-aos="fade-left"className='w-[50px] border-b-[2px] rounded-[10px] border-[#027d9b]'></h6>
              </div>

            <h6 data-aos="fade-left"  className='text-[15px] 2xl:text-[18px] mt-2 font_normal w-[90%] z-20 text-center mx-auto'>Read our word of month and latest evolution we made!. </h6>
              <div className='grid md:grid-cols-3 pt-5 mx-auto md:max-h-[380px] 2xl:h-auto w-[90%] md:w-[80%] gap-5 mx-auto mt-5'>
              <div data-aos="fade-right" className='bg-slate-100 md:w-[95%] max-h-[350px] 2xl:h-auto 2xl:max-h-fit rounded-[10px] overflow-hidden'>
                  <div data-aos="fade-bottom" data-aos-delay="300" onClick={()=>openDetail(datas[0])}  className='relative group border-box overflow-hidden mb-10 cursor-pointer'>
                        <div className='h-[200px] 2xl:h-[270px] rounded-[10px] overflow-hidden'>
                        <img src={`${process.env.REACT_APP_AWS_IMAGE_URL}${datas[0]?.blog_image}`} className='w-[100%] overflow-hidden group-hover:scale-110 duration-300 h-[180px] 2xl:h-[270px] rounded-[10px] object-stretch'  />
                        </div>  
                        <div className='px-5 -mt-2 2xl:mt-14'>
                        <div className='flex items-center'>
                          <h6 className={`font_bold text-[12px] 2xl:text-[15px]`} style={{ color: `${datas[0]?.color}`}}>{datas[0]?.tags?.map((t)=>t?.name)}</h6>
                          <h6 className={`w-[6px] h-[6px] rounded-full mx-2.5`} style={{ backgroundColor: `${datas[0]?.color}`}}></h6>
                          <h6 className='font_bold text-[12px] 2xl:text-[15px]' style={{ color: `${datas[0]?.color}`}}>{datas[0]?.min_read}</h6>
                        </div> 

                        <h6 className='font_bold line-clamp-2 text-[14px] 2xl:h-[17px] mt-2'>{datas[0]?.title}</h6>
                        <h6 className='line-clamp-2 mt-2 text-[12px] 2xl:text-[15px] font_normal text-gray-500'>{datas[0]?.motive}</h6>
                        <div className='flex items-center justify-between'>
                          <div className='flex items-center my-2'>

                          {(datas[0]?.written_by?.profile_photo !== null && datas[0]?.written_by?.profile_photo !== undefined) ?
                          <img src={`${process.env.REACT_APP_AWS_IMAGE_URL}${datas[0]?.written_by?.profile_photo}`} className='w-[30px] h-[30px] 2xl:w-[35px] 2xl:h-[35px] object-contain bg-slate-100 rounded-full' /> 
                          :
                          <h6 className='w-[24px] h-[24px] 2xl:w-[29px] 2xl:h-[29px] flex items-center justify-center text-[11px] font_bold text-white rounded-full' style={{background:datas[0]?.color === undefined ? '#fafafa' : datas[0]?.color}}>{datas[0]?.written_by?.name?.slice(0,1)}</h6>
                          }
                          <h6 className='font_bold text-[11px] 2xl:text-[14px] ml-2'>{datas[0]?.written_by?.name}</h6>
                          </div>
                          <h6 className='font_bold text-gray-400 text-[11px] 2xl:text-[14px]'>{moment(datas[0]?.date)?.format('LL')}</h6>
                        </div> 
                        </div>
                  </div>
              </div>    

              <div>
                <div onClick={()=>openDetail(datas[1])} data-aos="fade-up" className='rounded-[10px] group h-[170px] 2xl:h-[250px] overflow-hidden'>
                <img src={`${process.env.REACT_APP_AWS_IMAGE_URL}${datas[1]?.blog_image}`} className='w-[100%] overflow-hidden group-hover:scale-110 duration-300 h-[200px] 2xl:h-[250px] object-stretch'  />
                <h6 className='backdrop-filter bg-white/60 backdrop-blur-sm  absolute right-[5px] rounded-[4px] px-[5px] top-[5px] text-[10px] 2xl:text-[13px] font_bold'>{datas[1]?.tags?.map((t)=>t?.name)}</h6>
                <h6 className='absolute text-[12px] 2xl:text-[15px] mx-2 px-2 line-clamp-2 font_bold absolute bottom-2 backdrop-filter bg-white/0 backdrop-blur-sm lime-clamp-2'>{datas[1]?.title}</h6>
                </div>  

                <div onClick={()=>openDetail(datas[2])} className='flex mt-6 h-[150px] 2xl:h-[220px]'>
                <div data-aos="fade-bottom" data-aos-delay="300" className='relative rounded-[10px] group mr-3 w-[50%] overflow-hidden'>
                <img src={`${process.env.REACT_APP_AWS_IMAGE_URL}${datas[2]?.blog_image}`} className='w-[100%]  overflow-hidden group-hover:scale-110 duration-300 h-[200px] 2xl:h-[220px] object-stretch'  />
                <h6 className='backdrop-filter bg-white/60 backdrop-blur-sm  absolute right-[5px] rounded-[4px] px-[5px] top-[5px] text-[10px] 2xl:text-[13px] font_bold'>{datas[2]?.tags?.map((t)=>t?.name)}</h6>
                <h6 className='absolute text-[12px]  2xl:text-[15px] mx-2 px-2 line-clamp-2 font_bold absolute bottom-2 backdrop-filter bg-white/0 backdrop-blur-sm lime-clamp-2'>{datas[2]?.title}</h6>
                </div>

                <div onClick={()=>openDetail(datas[3])} data-aos="fade-bottom" data-aos-delay="300" className='rounded-[10px] group ml-3 w-[50%] overflow-hidden'>
                <img src={`${process.env.REACT_APP_AWS_IMAGE_URL}${datas[3]?.blog_image}`} className='w-[100%] overflow-hidden group-hover:scale-110 duration-300 h-[200px] 2xl:h-[220px] object-stretch'  />
                <h6 className='backdrop-filter bg-white/60 backdrop-blur-sm  absolute right-[5px] rounded-[4px] px-[5px] top-[5px] text-[10px] 2xl:text-[13px] font_bold'>{datas[3]?.tags?.map((t)=>t?.name)}</h6>
                <h6 className='absolute text-[12px]  2xl:text-[15px] mx-2 px-2 line-clamp-2 font_bold absolute bottom-2 backdrop-filter bg-white/0 backdrop-blur-sm lime-clamp-2'>{datas[3]?.title}</h6>
                </div>
                </div>
              </div>

              <div>
                {datas?.slice(5,8)?.map((b)=>(
                  <div onClick={()=>openDetail(b)} data-aos="fade-bottom" className='group flex mb-2 pb-2 border-b'>
                    <div className='min-w-[130px] max-w-[130px] 2xl:min-w-[160px] 2xl:max-w-[130px] h-[100px]  2xl:h-[150px] rounded-[5px] overflow-hidden '>
                    <img src={`${process.env.REACT_APP_AWS_IMAGE_URL}${b?.blog_image}`} className='w-[100%] h-[100%] group-hover:scale-110 duration-300 ' />
                    </div>
                    <div className='ml-2'>
                      <div className='flex justify-end'>
                      <h6 className='bg-gray-100 w-max flex justify-end text-[10px] 2xl:text-[13px] text-right font_bold text-black rounded-full px-[10px] py-[2px]'>{b?.tags?.map((t)=>t?.name)}</h6>
                      </div>
                      <h6 className='text-[13px] 2xl:text-[16px] font_normal text-gray-700'>{b?.title}</h6> 
                      <h6 className='text-[10px] 2xl:text-[13px] mt-1 font_bold text-gray-800'>{moment(b?.date)?.format('LL')}</h6>
                    </div>  

                  </div>  
                ))}
              </div>
            </div>
          </div>

        

       

          {/* service request  */}
          <div className='relative 2xl:mt-[200px] bg-[#f0fcff]  relative flex flex-col items-center justify-center '>
             
             <div className='md:flex'>
             <div className='z-20'>
             <img src={interiorImg} className='w-[80%] mx-auto md:w-[100%]' />
             </div>
             <div className='ml-[5%]  md:w-[50%] mb-[16%] md:mb-0 mt-[10%]'>
            <h6 className='font_bold text-[20px] 2xl:text-[23px] z-10 text-[#000]'>Looking for interior</h6>
            <h6 className='font_normal z-10 text-[15px] 2xl:text-[18px] mt-2'>Exceptional designs that cater to your aesthetic, functional and aspirational goals.</h6>
            <div className='flex  md:mt-2 z-10'>
            <h6 onClick={()=>setmodal1(!modal1)} className='font_bold text-[12px] 2xl:text-[15px] border mt-2 w-max px-2 text-center p-[5px]  hover:text-white cursor-pointer bg-[#027d9b] border-[#027d9b] text-white rounded-[5px] duration-300'>Get Free Consultation!</h6>
            </div>
            </div>
            </div>



            <div className='min-h-[50px] -mt-12 z-10 w-full bg-[#bceaf5]'>
              <h1 className='opacity-0'>Hello</h1>

            </div>
          </div>

    </div>
  )
}

export default Home