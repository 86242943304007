import React, { useEffect, useState } from 'react'
import { projectArr } from '../constants/AppConstant'
import {  useNavigate, useParams } from 'react-router-dom'
import { IoIosArrowRoundBack } from "react-icons/io";
import toast from 'react-hot-toast';
import { GetVideoDetailServices } from '../services/VideoServices';

function ProjectDetail() {

  const navigator = useNavigate()
  const {id} = useParams()

  const [state,setstate] = useState({})
  const [loader,setloader] = useState(true)

  useEffect(()=>{
    getDetail()
  },[id])


  async function getDetail(){
    const data =  await GetVideoDetailServices(id)
    if(data?.data?.datas?.length > 0){
      setloader(false)
      setstate(data?.data?.datas[0])
    }else{
      toast.error("We couldn't find the video based on your search")
      setTimeout(() => {
         navigator('/')
      }, 2000);
    }
  }

  return (
    <div>
       <div className='flex bg-[#def9ff] px-[5%] py-[14px]'>
        <div className='flex items-center cursor-pointer' onClick={()=>navigator(-1)}>
        <IoIosArrowRoundBack color='#027d9b' />
        <h6 className='text-[11px] ml-2 text-[#027d9b] font_bold'>Back to projects {}</h6>
        </div>
      </div>
     
         {/* <img src={state?.banner} className={`w-screen  h-[90vh] ${(state?.image_type !== undefined && state?.image_type !== null)? 'object-contain' : 'object-cover'}`} />

         <div className='w-[90%] md:w-[80%] mx-auto my-[5%] mb-[10%]'>
            <h6 className='font_bold text-[25px]'>{state?.company}</h6>
            {(state?.service !== '' && state?.service !== undefined) && <h6 className='font_normal text-[14px] mb-4'>{state?.service}</h6>}
            {(state?.project_name !== '' && state?.project_name !== undefined) && <h6 className='font_normal text-[14px] mb-4'>PROJECT NAME : <span className='font_bold'>{state?.project_name}</span> </h6>}
            {(state?.total_sqft !== '' && state?.total_sqft !== undefined) && <h6 className='font_normal text-[14px] mb-4'>TOTAL SFT : <span className='font_bold'>{state?.total_sqft}</span> </h6>}
            {(state?.nature !== '' && state?.nature !== undefined) && <h6 className='font_normal text-[14px] mb-4'>NATURE OF THE PROJECT : <span className='font_bold'>{state?.nature}</span> </h6>}
            {(state?.scope !== '' && state?.scope !== undefined) && <h6 className='font_normal text-[14px] mb-10'>SCOPE : {state?.scope}</h6>}

            {(state?.video !== '' && state?.video !== null && state?.video !== undefined) &&
            <video className='w-full' src={state?.video} controls />}
         </div> */}
         {!loader && 
         <>
         <img src={`${process.env.REACT_APP_AWS_IMAGE_URL}${state?.banner}`} className={`w-screen  h-[90vh] ${(state?.image_type !== undefined && state?.image_type !== null && state?.image_type !== '')? 'object-contain' : 'object-cover'}`} />

         <div className='w-[90%] md:w-[80%] mx-auto my-[5%] mb-[10%]'>
            <h6 className='font_bold text-[25px]'>{state?.client}</h6>
            {(state?.work !== '' && state?.work !== undefined) && <h6 className='font_normal text-[14px] mb-4'>{state?.work}</h6>}
            {(state?.project_name !== '' && state?.project_name !== undefined) && <h6 className='font_normal text-[14px] mb-4'>PROJECT NAME : <span className='font_bold'>{state?.project_name}</span> </h6>}
            {(state?.total_sqft !== '' && state?.total_sqft !== undefined) && <h6 className='font_normal text-[14px] mb-4'>TOTAL SFT : <span className='font_bold'>{state?.total_sqft}</span> </h6>}
            {(state?.nature !== '' && state?.nature !== undefined) && <h6 className='font_normal text-[14px] mb-4'>NATURE OF THE PROJECT : <span className='font_bold'>{state?.nature}</span> </h6>}
            {(state?.scope !== '' && state?.scope !== undefined) && <h6 className='font_normal text-[14px] mb-10'>SCOPE : {state?.scope}</h6>}

            {(state?.video !== '' && state?.video !== null && state?.video !== undefined && (state?.youtube_link == '' || state?.youtube_link == null || state?.youtube_link == undefined) ) &&
            <video className='w-full' src={`${process.env.REACT_APP_AWS_IMAGE_URL}${state?.video}`} controls />}
            
            {(state?.youtube_link !== '' && state?.youtube_link !== null && state?.youtube_link !== undefined ) &&
            <div className='w-[100%] h-[100%] bg-red-100'>
                 <iframe src={state?.youtube_link} width={'100%'} allowFullScreen />
            </div>
             }
            

            <div className='grid grid-cols-2 md:grid-cols-4 gap-5'>
            {(state?.images !== '' && state?.images !== null && state?.images !== undefined && state.images.length > 0) &&
            state?.images?.map((s)=>(
              <img src={`${process.env.REACT_APP_AWS_IMAGE_URL}${s}`} className='w-[100%] h-[150px] object-cover' />
            ))
            }
            </div>
         </div>
         </>}

    </div>

  )
}

export default ProjectDetail