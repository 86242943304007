import React, { useState } from 'react'
import { appLogo, base_url, department} from '../constants/AppConstant'
import { FiMenu } from "react-icons/fi";
import { IoClose } from "react-icons/io5";
import { Drawer,Modal } from 'antd';
import { BsArrowRight } from "react-icons/bs";
import { useNavigate } from 'react-router-dom';
import { FaAngleDown } from "react-icons/fa";
import ScrollToTop from '../pages/ScrollToTop';
import {BiError} from 'react-icons/bi'
import { Select } from 'antd';
import axios from 'axios';
import {message} from 'antd'

function Navbar() {

  const navigator = useNavigate()  
  const [modal,setmodal] = useState(false)  
  const [modal1,setmodal1] = useState(false)  

  const [step,setstep] = useState('')
  const [openDropdown, setOpenDropdown] = useState(null);
  
  const menu = [
    {   
        id:1,
        icon:'',
        name:'Home',
        path:'/',
        menu:[]
    },
    {
        id:2,
        icon:'',
        name:'About',
        path:'/about',
        menu:[]
    },
    {
        id:3,
        icon:'',
        name:'Services',
        path:'na',
        menu:[
            {
                id:1,
                name:'Commercial Interior / Industrial Design and Architectural Services',
                path:'/services/commercial-interior-design-and-architecture'
            },
            {
                id:2,
                name:'MEP / HVAC and Networking Services',
                path:'/services/mep-hvac-and-networking'
            },
            {
                id:3,
                name:'Project Evaluation and Estiamtion',
                path:'/services/project-evaluation-and-estimation'
            },
            {
                id:4,
                name:'Turnkey Project Execution and General Contracting Services',
                path:'/services/turnkey-project-execution-and-general-contracting'
            },
        ]
    },
    {
        id:4,
        icon:'',
        name:'Projects',
        path:'/projects',
        menu:[]
    },
    // {
    //     id:8,
    //     icon:'',
    //     name:"Fidelitus Group",
    //     path:'na',
    //     menu:[
    //         {
    //             id:1,
    //             name:'Real Estate Transaction',
    //             path:transaction_website_url,
    //             new:true
    //             // path:'/whats-more/real-estate-transaction'
    //         },
    //         {
    //             id:2,
    //             name:'HR Labs',
    //             path:hrlabs_website_url,
    //             new:true
    //             // path:'/whats-more/hr-labs'
    //         },
    //         {
    //             id:3,
    //             name:'Facility Management Services',
    //             path:fms_website_url,
    //             new:true
    //             // path:'/whats-more/facility-management-services'
    //         },
    //         {
    //             id:4,
    //             name:'Fidelitus Gallery',
    //             path:fidelitus_gallery_website_url,
    //             new:true
    //             // path:'/whats-more/fidelitus-gallery'
    //         },
    //     ]
    // },
    {
        id:7,
        icon:'',
        name:'Clients',
        path:'/clients',
        menu:[]
    },
    {
      id:7,
      icon:'',
      name:'Career',
      path:'/career',
      menu:[]
  },
    {
        id:5,
        icon:'',
        name:'Contact',
        path:'/contact',
        menu:[]
    },
    {
        id:6,
        icon:'',
        name:'Blogs',
        path:'/blogs',
        menu:[]
    },
  ]   

  async function openPage(v){
    navigator(v)
    setmodal(false)
  }

  async function openPage1(v){
    if(v?.new === true){
      window.open(v?.path,'_blank')
      setOpenDropdown(null)
      setmodal(false)
    }else{
      navigator(v?.path)
      setOpenDropdown(null)
      setmodal(false)
    }
  }

  const [data,setdata] =  useState({name:'',mobile:'',email:'',requirment:'',service:''})
  const [error,seterror] =  useState({name:'',mobile:'',email:'',requirment:'',service:''})

  const steps = [
    {label:'Commercial Interior',value:'Commercial Interior'},
    {label:'Architecural Services',value:'Architecural Services'},
    {label:'MEP / HVAC and Networking Services',value:'MEP / HVAC and Networking Services'},
    {label:'Project Evaluation and Estimation',value:'Project Evaluation and Estimation'},
    {label:'Turnkey Project Execution',value:'Turnkey Project Execution'},
    {label:'General Contracting Services',value:'General Contracting Services'},
    {label:'General Enquiry',value:'General Enquiry'}
  ]

  function handleChange(e){
    setdata({...data,[e.target.name]:e.target.value})
    seterror({...error,[e.target.name]:''})
  }

  async function submitForm(){
    if(!data?.name){
      seterror({...error,name:'This Field is required *'})
    }else if(!data?.email){
      seterror({...error,email:'This Field is required *'})
    }else if(!data?.requirment){
      seterror({...error,requirment:'This Field is required *'})
    }else{
     
      const sendData = {...data}
      sendData['requirment'] = `Service : ${data?.service}  Message : ${data?.requirment}`
      sendData['department_website'] = department
      
      await axios.post(`${base_url}clients/create`,sendData)
      .then((res)=>{
        if(res?.status === 201){
          setmodal1(false)
          message.success('Estiamte Request Created!')
          setdata({name:'',mobile:'',email:'',requirment:'',department_website:'',service:''})
          seterror({name:'',mobile:'',email:'',requirment:'',department_website:'',service:''})
        }
      }).catch((err)=>{   
        message.success('Estiamte Request Created!')
        setmodal1(false)

      }) 
    }
  }

//   console.log("openDropdown here okkk",openDropdown)

  return (
    <div className='relative overflow-visible  min-h-[60px] max-h-[60px] 2xl:min-h-[100px] 2xl:max-h-[100px]'>
    <ScrollToTop />
    <div className='px-[5%] relative min-h-[60px] max-h-[60px] 2xl:min-h-[100px] 2xl:max-h-[100px] z-40 backdrop-filter  bg-white backdrop-blur-sm  flex items-center justify-between  '>
        <div onClick={()=>navigator('/')}>
        <img  alt="appLogo" className='w-[120px] 2xl:w-[300px] object-contain cursor-pointer h-[40px] 2xl:h-[60px]' src={appLogo} />
        </div>
        <div className='flex h-full'>
            <div className='hidden md:flex items-center  justify-center mx-[10px]'>
                {menu?.map((m,i)=>(
                    <div key={i} onMouseLeave={()=>setOpenDropdown(null)} onMouseOver={()=>m?.menu?.length > 0  ? setOpenDropdown(i) : setOpenDropdown(null)} className='group relative z-20'>
                    <h6 onClick={()=>m?.path !== 'na' && openPage(m?.path)} className='cursor-pointer py-[10px] flex items-center duration-300 hover:text-[#027d9b] font-[600] mx-[10px] text-[13px] 2xl:text-[18px] font_bold'>{m?.name}{m?.menu?.length > 0 && <FaAngleDown className='ml-[5px]'/>}</h6>
                    {m?.menu?.length > 0 &&
                        <div className={`${openDropdown === (i) ? 'block' : 'hidden'} absolute top-[40px] ml-[10px] w-[230px] border bg-white`}>
                            {m?.menu?.map((m1)=>(
                            <h6 onClick={()=>{openPage1(m1)}} key={m?.id} className={`capitalize hover:text-[#027d9b] border-b border-slate-100 text-[12px] 2xl:text-[18px]  py-2 font-[500] text-gray-800 cursor-pointer px-4 relative  font_bold `}>{m1?.name}</h6>
                            ))}
                        </div>
                        }

                    </div>
                ))}
            </div>

            <div>
                <button onClick={()=>setmodal1(!modal1)} className='hidden md:block border-[2px] border-[#027d9b] text-[12px] 2xl:text-[15px] text-[#027d9b] bg-[#027d9b] my-[10px] text-white duration-300 px-4 py-[4px] rounded-[4px] font-[700] font_bold'>Get Free Estimate</button>
                {modal ? <IoClose onClick={()=>setmodal(!modal)} className='block md:hidden cursor-pointer' size={24} /> : <FiMenu onClick={()=>setmodal(!modal)} className='block md:hidden cursor-pointer' size={20} />}
            </div>
        </div>
    </div>

    <Drawer  height={'auto'} open={modal} placement='top' closable={false} maskClosable={()=>setmodal(!modal)}>
        <div className='relative'> 
        <img alt="appLogo" className='w-[120px] h-[40px] ' src={appLogo} />
             
             <IoClose onClick={()=>setmodal(!modal)} size={24} className='absolute p-[5px] bg-gray-100 right-0 top-2' />
              
             <div className=' items-center justify-center '>
                {menu?.map((m,i)=>(
                  <div key={i}>  
                    <div onClick={()=>m?.path === 'na' ?  i == step ? setstep('') : setstep(i) : openPage(m?.path)} className={`border-b relative border-slate-100 ${i === 0 && 'mt-5 border-t border-slate-100'}`}>
                    <h6 className='cursor-pointer py-3 duration-300 hover:text-[#027d9b] font-[600] text-[13px] font_bold'>{m?.name}</h6>
                    <BsArrowRight className='animate-bounce absolute right-0 top-4 text-[#027d9b]' />
                    </div>
                    {m?.menu?.length > 0 && step === i && 
                    <div className='ml-1'>
                      {m?.menu?.map((m1)=>(
                       <h6 onClick={()=>openPage1(m1)} className='cursor-pointer py-3 border-b border-slate-100 duration-300 text-gray-500 hover:text-[#027d9b] font-[600] text-[13px] font_bold'>{m1?.name}</h6>
                      ))}
                    </div>}    
                  </div>  
                ))}
            </div>
        </div>
    </Drawer>


    <Modal width={320} open={modal1} footer={false} closable={false}>

        <div>
            <h6 className='text-[15px] font-[700]'>Enquiry Form</h6>
            <h6 className='text-[13px] leading-[18px] bg-slate-100 p-2 my-2'>Would you like to have a estimate based on the service just complete below survey form.</h6>
           
             <div data-aos="fade-up" className='flex items-center'>
              <div className='w-[48%] mr-[2%]'>
                <h6 className='text-[12px] font_bold font-[900]'>Name</h6>
                <input value={data?.name} name="name" onChange={handleChange} className='border w-full p-1 mt-1 pl-4 text-[14px] py-1.5  focus:outline-0' />
                {error?.name && <h6 className='flex  items-center text-[10px] text-red-600 mt-0.5'><BiError className='mr-1'/> {error?.name}</h6>}
              </div> 
              <div className='w-[48%] ml-[2%]'>
                <h6 className='text-[12px] font_bold font-[900]'>Mobile</h6>
                <input value={data?.mobile} name="mobile" onChange={handleChange} className='border w-full p-1 mt-1 pl-4 text-[14px] py-1.5  focus:outline-0' />
                {error?.mobile && <h6 className='flex items-center text-[10px] text-red-600 mt-0.5'><BiError className='mr-1'/> {error?.mobile}</h6>}
              </div> 
            </div> 

             <div className='w-[100%] mt-[2%]'>
                <h6 className='text-[12px] font_bold font-[900]'>Email</h6>
                <input value={data?.email} name="email" type="email" onChange={handleChange} className='border w-full p-1 mt-1 pl-4 text-[14px] py-1.5  focus:outline-0' />
                {error?.email && <h6 className='flex items-center text-[10px] text-red-600 mt-0.5'><BiError className='mr-1'/> {error?.email}</h6>}
             </div>  

             <div className='w-[100%] mt-[2%]'>
                <h6 className='text-[12px] font_bold font-[900]'>Service</h6>
                <div className='border w-full p-1 mt-1  text-[12px] py-1.5  focus:outline-0' >
                <Select value={data?.service} options={steps} onChange={(v)=>setdata({...data,service:v})} bordered={false} className='w-full h-[18px]'/>
                </div>
                {error?.email && <h6 className='flex items-center text-[10px] text-red-600 mt-0.5'><BiError className='mr-1'/> {error?.email}</h6>}
             </div>  

             <h6 className='text-[12px] font_bold font-[900] mt-1'>Detail info</h6>
              <textarea value={data?.requirment} name="requirment" onChange={handleChange} className='border w-full p-1 mt-1 pl-4 text-[14px] py-1.5  focus:outline-0' />
              {error?.requirment && <h6 className='flex items-center text-[10px] text-red-600 mt-0.5'><BiError className='mr-1'/> {error?.requirment}</h6>}

              {/* <h6 onClick={submitForm} className='transition w-28 text-center mt-4 delay-300 duration-300 ease-in-out  border border-[#e87818] text-[#e87818] hover:text-[#fff]  py-[5px] px-3 cursor-pointer hover:bg-[#e87818] hover:text-white font-[300] font_thick text-[13px]'>CONTACT US</h6> */}



            <div className='flex items-center justify-end mt-5'>
                <h6 onClick={()=>setmodal1(!modal1)} className='border cursor-pointer border-slate-300 text-[12px] font_bold text-black p-1 px-2 rounded w-max mr-2'>Close</h6>
                <h6 onClick={submitForm} className='bg-[#027d9b] cursor-pointer text-[12px] font_bold text-white p-1 px-2 rounded w-max'>Submit</h6>
            </div>
        </div>

    </Modal>

    
    </div>
  )
}

export default Navbar