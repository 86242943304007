import React,{ useEffect, useState } from 'react'

import {  base_url, image_base_url, image_base_url1 } from '../constants/AppConstant'
import axios from 'axios'
import moment from 'moment'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { Modal } from 'antd'
import ErrorComponent from '../constants/ErrorComponent'
import {IoClose} from 'react-icons/io5'
import toast, { Toaster } from 'react-hot-toast';

function CarrierDetail() {
 
  const {state} = useLocation()
  console.log("state here",state)

  const [modal,setmodal] = useState(false)

  const navigate =  useNavigate();
  const [data,setdata] = useState({name:'',current_company:'',experience:'',email:'',mobile:'',address:'',current_address:'',current_ctc:'',expected_ctc:'',notice_period:'',resume:''})
  const [error,seterror] = useState({name:'',current_company:'',experience:'',email:'',mobile:'',address:'',current_address:'',current_ctc:'',expected_ctc:'',notice_period:'',resume:''})

  
 

  function handleChange(e){
    setdata({...data,[e.target.name]:e.target.value})
    seterror({...error,[e.target.name]:''})
  }  


  async function uploadimg(v){
     setdata({...data,file:v})
     seterror({...error,file:''})

     const headers = {headers:{
      'Content-Type':'multipart/form-data'
    }}

    const fd = new FormData()
    fd.append('file',v)
    
    await axios.post(`${base_url}project_website/job_apply/upload_file`,fd,headers)
    .then((res)=>{
      console.log("res?.data",res?.data)
      setdata({...data,resume:res?.data?.data})
      seterror({...error,resume:''})
    }).catch((err)=>{
      // console.log("err?.data",err?.response)
    })

  }


  async function submitform(){
    if(!data?.name){
      seterror({...error,name:'This Field is required *'})
    }else if(!data?.mobile){
      seterror({...error,mobile:'This Field is required *'})
    }else if(!data?.email){
      seterror({...error,email:'This Field is required *'})
    }else if(!data?.experience){
      seterror({...error,experience:'This Field is required *'})
    }else if(!data?.notice_period){
      seterror({...error,notice_period:'This Field is required *'})
    }else if(!data?.resume){
      seterror({...error,resume:'This Field is required *'})
    }else{
      const fd = new FormData()
      const sendData = {...data}

      if(state?._id !== undefined){
       sendData['job_opening'] = state?._id
      }
       

      const headers = {headers:{
        'Content-Type':'application/json'
      }}

      const response = await axios.post(`${base_url}project_website/job_apply/create`,sendData,headers)
      if(response?.status === 201){
        setmodal(false)
        setdata({name:'',current_company:'',experience:'',email:'',mobile:'',address:'',current_address:'',current_ctc:'',expected_ctc:'',notice_period:'',resume:''})
        seterror({name:'',current_company:'',experience:'',email:'',mobile:'',address:'',current_address:'',current_ctc:'',expected_ctc:'',notice_period:'',resume:''})
        toast.success("Your Profile shared successfully!")
      }else{
        setmodal(false)
        toast.error("Oops we couldn't accept any profile right now please try later!")
      }
    }
  }

 

  return (
    <div className="w-full min-h-screen relative z-0 w-screen">
      <Modal open={modal} onCancel={()=>setmodal(!modal)}  footer={false} maskClosable={true} closable={true}>
        <h6 className='text-[16px] 2xl:text-[19px] font_bold'>Apply For this Job</h6>
        <h6 className='font_normal text-[13px] 2xl:text-[16px] mt-[5px]'>Complete the below form and submit your form and if your profile got shortlisted you will get a call from our team experts</h6>
         

         <h6 className='text-[11px] 2xl:text-[14px] font-[600] mb-1 mt-5'>Name*</h6>
         <input name="name" value={data?.name} onChange={handleChange} placeholder='Enter name' className='placeholder:text-[12px] 2xl:text-[16px] border px-[4px] py-[4px] w-[100%] focus:ring-0 focus:outline-none text-[13px]' />
         <ErrorComponent error={error?.name} /> 

         <div className='flex mt-[10px]'>
            <div className='mr-[10px] w-[50%]'>
              <h6 className='text-[11px] 2xl:text-[14px] font-[600] mb-1'>Mobile*</h6>
              <input name="mobile" value={data?.mobile} onChange={handleChange} placeholder='Enter mobile' className='placeholder:text-[12px] 2xl:text-[16px] border px-[4px] py-[4px] w-[100%] focus:ring-0 focus:outline-none text-[13px]' />
              <ErrorComponent error={error?.mobile} /> 
            </div>
            
            <div className='ml-[10px] w-[50%]'>
              <h6 className='text-[11px] 2xl:text-[14px] font-[600] mb-1'>Email*</h6>
              <input name="email" value={data?.email} onChange={handleChange} placeholder='Enter email' className='placeholder:text-[12px] 2xl:text-[16px] border px-[4px] py-[4px] w-[100%] focus:ring-0 focus:outline-none text-[13px]' />
              <ErrorComponent error={error?.email} /> 
            </div>
          </div> 

        

          <div className='flex mt-[10px]'>
            <div className='mr-[10px] w-[50%]'>
              <h6 className='text-[11px] 2xl:text-[14px] font-[600] mb-1'>Experience (Yrs)*</h6>
              <input name="experience" value={data?.experience} onChange={handleChange} placeholder='Enter experience' className='placeholder:text-[12px] 2xl:text-[16px] border px-[4px] py-[4px] w-[100%] focus:ring-0 focus:outline-none text-[13px]' />
              <ErrorComponent error={error?.experience} /> 
            </div>
            <div className='ml-[10px] w-[50%]'>
              <h6 className='text-[11px] 2xl:text-[14px] font-[600] mb-1'>Notice Period*</h6>
              <input name="notice_period" value={data?.notice_period} onChange={handleChange} placeholder='Enter notice period' className='placeholder:text-[12px] 2xl:text-[16px] border px-[4px] py-[4px] w-[100%] focus:ring-0 focus:outline-none text-[13px]' />
              <ErrorComponent error={error?.notice_period} /> 
            </div>
          </div> 

         

          <div className='flex mt-[10px]'>
            <div className='mr-[10px] w-[50%]'>
              <h6 className='text-[11px] 2xl:text-[14px] font-[600] mb-1'>Current CTC (Lakhs)*</h6>
              <input name="current_ctc" value={data?.current_ctc} onChange={handleChange} placeholder='Enter current ctc' className='placeholder:text-[12px] 2xl:text-[16px] border px-[4px] py-[4px] w-[100%] focus:ring-0 focus:outline-none text-[13px]' />
              <ErrorComponent error={error?.current_ctc} /> 
            </div>
            <div className='ml-[10px] w-[50%]'>
              <h6 className='text-[11px] 2xl:text-[14px] font-[600] mb-1'>Expected CTC (Lakhs)*</h6>
              <input name="expected_ctc" value={data?.expected_ctc} onChange={handleChange} placeholder='Enter expected ctc' className='placeholder:text-[12px] 2xl:text-[16px] border px-[4px] py-[4px] w-[100%] focus:ring-0 focus:outline-none text-[13px]' />
              <ErrorComponent error={error?.expected_ctc} /> 
            </div>
          </div> 

          <h6 className='text-[11px] 2xl:text-[14px] font-[600] mb-1 mt-2'>Previous Company</h6>
          <input name="current_company" value={data?.current_company} onChange={handleChange} placeholder='Enter previous company (if any)' className='placeholder:text-[12px] 2xl:text-[16px] border px-[4px] py-[4px] w-[100%] focus:ring-0 focus:outline-none text-[13px]' />
          <ErrorComponent error={error?.current_company} /> 


          <h6 className='text-[11px] 2xl:text-[14px] font-[600] mb-1 mt-2'>Address</h6>
          <textarea name="address" value={data?.address} onChange={handleChange} placeholder='Enter address (if any)' className='placeholder:text-[12px] 2xl:text-[15px] border px-[4px] py-[4px] w-[100%] focus:ring-0 focus:outline-none text-[13px]' />
          <ErrorComponent error={error?.address} /> 

          <h6 className='text-[11px] 2xl:text-[14px] font-[600] mb-1 mt-2'>Current Address</h6>
          <textarea name="current_address" value={data?.current_address} onChange={handleChange} placeholder='Enter current address (if any)' className='placeholder:text-[12px] 2xl:text-[15px] border px-[4px] py-[4px] w-[100%] focus:ring-0 focus:outline-none text-[13px]' />
          <ErrorComponent error={error?.current_address} /> 


          <h6 className='text-[11px] 2xl:text-[14px] font-[600] mb-1 mt-2'>Upload Resume *</h6>

          {(data?.resume === '' || data?.resume == null) ?
            <form onClick={()=>document.querySelector('.input-field').click()} className={`p-4 2xl:py-10 flex flex-col items-center justify-center cursor-pointer border-dashed border border-slate-400 `}>
                {/* <FiUpload size={24} /> */}
                <span className='font-bold text-[10px] 2xl:text-[13px] mt-1 text-center'>Click To Upload Photo</span>
                <span className='font-bold text-[7px] 2xl:text-[10px] mt-0 text-center'>Supported Type JPEG,PNG,SVG,WEBP,PDF,XLSX,PPT</span>
                <input type='file' onChange={({target:{files}})=>{
                  files[0] && uploadimg(files[0])
                }} accept="*" className='input-field' hidden />
            </form> 
            :
            <div className='p-2 border relative flex flex-col  cursor-pointer'>
                  <IoClose className='absolute top-3 right-2' onClick={()=>setdata({...data,resume:''})}/>
                  <h6 className='text-[12px] 2xl:text-[15px] truncate w-48 ml-0'>{data?.resume.name !== undefined ? data?.resume?.name : data?.resume}</h6>
            </div>              
          }
          <ErrorComponent error={error?.resume} /> 



        <div className='flex justify-end mt-[20px]'>
          <h6 onClick={()=>setmodal(!modal)} className='bg-slate-100 w-[90px] mr-[10px] rounded text-center px-[10px] 2xl:text-[16px] py-[5px] hover:bg-slate-200 cursor-pointer font_normal'>Cancel</h6>
          <h6 onClick={submitform} className='bg-orange-500 hover:bg-orange-600 rounded text-center px-[10px] py-[5px] 2xl:text-[16px] cursor-pointer font_normal'>Apply</h6>
        </div>
      
      </Modal>

      <div className='relative  overflow-hidden h-[200px]  md:h-[340px] grid place-items-center  bg-[#eee] bg-career_bg  bg-top  bg-cover bg-repeat 2xl:h-[500px]'>
      

      <div className='w-[100%] absolute opacity-50 z-10 h-[200px]  md:h-[340px] 2xl:h-[500px]  bg-black'>
      </div>
      <div  className='z-10'>
      <h1 className='text-[30px] text-white text-center tracking-[10px] font_thicker opacity-80 2xl:text-[35px]'>Career </h1>

      <h6 className='text-center tracking-wider z-30 text-gray-400 font_normal text-[14px] mb-2 2xl:text-[18px]'> <span onClick={()=>navigate('/')} className='text-white relative cursor-pointer after:bg-[#fff] after:absolute after:h-[1px] after:w-0 after:bottom-0 after:left-0  hover:after:w-full after:transition-all after:duration-300'>Home</span> / Career</h6>
      <h6 className='text-center tracking-wider z-30 text-[#fff]  font_normal text-[12px] 2xl:text-[16px]'>Be a part of Wonderful team for your career success</h6>
      </div>


      </div>
         


        <div className='w-[100vw]  mx-auto '>
         
         <div className='bg-gray-100 md:flex items-center px-[10vw] py-10'> 
          <div className='md:w-[70%]'>
          <div>
             <h6 className='text-[25px] capitalize font_thicker'>{state?.job_title}</h6>
             <h6 className='text-[12.5px] 2xl:text-[15.5px] font-[500] font_normal text-gray-500'>JOB ID : <span className='uppercase border-r pr-[10px] border-slate-500'> {state?._id?.slice(0,8)}</span> Posted On : {moment(state?.createdAt)?.format('LL')}</h6>
          </div>  
          </div>
          <h6 onClick={()=>setmodal(true)} className='bg-orange-500 mt-4 md:mt-0 text-center rounded shadow text-[13px] 2xl:text-[16px] cursor-pointer hover:bg-orange-600 font_normal w-[120px] capitalize font-[600] p-[5px] py-[10px]'>Apply Now</h6>
         </div>

         <div className='w-[80vw] mx-auto py-10'>
          
         {(state?.job_description !== undefined && state?.job_description !== '') &&
          <>
          <h2 className='text-[15px] mb-2 2xl:text-[18px] text-gray-600 mt-[20px] uppercase font_normal font-[200]'>Description</h2>
          <h6 className='font_normal capitalize text-[13px] 2xl:text-[16px]'>{state?.job_description}</h6>
          </>}

          {(state?.skills !== undefined && state?.skills !== '') &&
          <>
          <h2 className='text-[15px] 2xl:text-[18px] mb-2 text-gray-600 mt-[20px] uppercase font_normal font-[200]'>Skills</h2>
          <h6 className='font_normal capitalize text-[13px] 2xl:text-[16px]'>{state?.skills}</h6>
          </>}
          <h2 className='text-[15px] 2xl:text-[18px] mb-2 text-gray-600 mt-[20px] uppercase font_normal font-[200]'> NO OF OPENING / CONTACT PERSON  </h2>
          <h6 className='font_normal capitalize text-[13px] 2xl:text-[16px] ml-[5px] -ml-[1px]'> {state?.no_of_position} Opening / {state?.contact_person}  </h6>

          {(state?.location !== undefined && state?.location !== '') &&
          <>
          <h2 className='text-[15px] 2xl:text-[18px] mb-2 text-gray-600 mt-[20px] uppercase font_normal font-[200]'>JOB LOCATION </h2>
          <div className='flex flex-wrap items-center min-w-[100%] max-w-[100%] '>
             {state?.location?.split(':')?.map((d1,i)=><span key={i} className='bg-slate-100 text-[13px] 2xl:text-[16px] capitalize mb-[2px] font_normal font-[600] mr-[10px] p-[4px] px-[8px] rounded'> {d1}</span>)}
          </div>  
          </>}

           {state?.file !== undefined && <>
           <h2 className='text-[13px] 2xl:text-[16px] mb-2 text-gray-600 mt-[20px] font_normal font-[200]'>If You have any query about Job description you can download the attachment for more info. </h2>
           <Link  to={`${image_base_url1}${state?.file}`} target='_blank'>
           <h6 className='text-blue-500 font_normal text-[13px] 2xl:text-[16px] hover:underline font-[900] cursor-pointer'>Download JD</h6>
           </Link>
           </>}
         </div>



        </div>  


    </div>
  )
}

export default CarrierDetail