import React from 'react'
import { appLogo } from '../constants/AppConstant'

function Splash() {
  return (
    <div className='flex flex-col h-screen items-center justify-center'>
        <img src={appLogo} alt="appLogo" className='w-[100%] h-[100px] md:h-[150px] 2xl:[175px] object-contain' />
    </div>
  )
}

export default Splash