import React from 'react'
import CommercialInterior from './CommercialInterior'
import MEPHvac from './MEPHvac'
import ProjectEvaluation from './ProjectEvaluation'
import TurnkeyProjectExecution from './TurnkeyProjectExecution'

function Service() {
  return (
    <div>
      <h1>Service</h1> 
      <CommercialInterior />
      <MEPHvac />
      <ProjectEvaluation />
      <TurnkeyProjectExecution />
    </div>

  )
}

export default Service