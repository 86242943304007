import React from 'react'
import { address, email, mobile, mobile1, ourService, service10Img, service1Img, service5Img } from '../constants/AppConstant'
import { useLocation, useNavigate } from 'react-router-dom'
import { MdPhoneCallback } from "react-icons/md";
import { BsMailbox2 } from "react-icons/bs";
import { FaLocationDot } from "react-icons/fa6";

function CommercialInterior() {

  const {pathname} = useLocation()
  const navigator = useNavigate()

  return (
    <div>
       <div className='relative flex items-center justify-center bg-infrastructure_bg bg-center bg-cover h-[200px]  md:h-[340px] 2xl:h-[500px]'>
            <div className='z-10'>
              <h6 className='text-[25px] md:text-[30px] text-white text-center tracking-[10px] font_thicker opacity-80 2xl:text-[35px]'>Interior & Architecure Design</h6>
              <h6 className='text-center font_normal text-white opacity-100 text-[14px] 2xl:text-[18px]'> <span onClick={()=>navigator('/')} className='relative cursor-pointer after:bg-[#fff] after:absolute after:h-[2px] after:w-0 after:bottom-0 after:left-0  text-white opacity-100  hover:after:w-full after:transition-all after:duration-300'>Home</span> / Interior & Architecture design </h6>
              <h6 className='text-[13px] mt-1  text-white text-center font_normal opacity-80 2xl:text-[16px]'>Would like to know more about our interior & architecture design. </h6>
            </div>
            <div className='bg-black opacity-60 absolute top-0 left-0 w-full h-[200px]  md:h-[340px] 2xl:h-[500px]'>
            </div> 
         </div>



        <div className='flex-col-reverse flex md:flex-row w-[90%] md:w-[80%] mx-auto py-[5%]'>
        <div className='md:w-[30%] mt-10 md:mt-0'>
          
          <div className='md:w-[90%] border border-slate-100 p-6'>
          <div className='border-b-[2px] pb-2 mb-5 border-slate-100'>
          <h6  data-aos="fade-right" className='font_bold text-[15px] 2xl:text-[18px]'>Our Services</h6>  
          </div>
          {ourService?.map((s)=>(
            <h6 data-aos-delay="300" data-aos="fade-bottom" onClick={()=>navigator(s?.path)} className={`font_bold cursor-pointer text-[15px] 2xl:text-[18px] my-2 p-4  ${s?.path === pathname ? 'bg-[#027d9b] text-white' : 'bg-slate-50'}`}>{s?.name} </h6>
          ))}
          </div>

          <div className='md:w-[90%] border border-slate-100 p-6 mt-5'>
          <div className='border-b-[2px] pb-2 mb-5 border-slate-100'>
          <h6  data-aos="fade-right" className='font_bold text-[15px] 2xl:text-[18px]'>Contact</h6>  
          </div>

          <div data-aos="fade-bottom" data-aos-delay="100" className='flex  bg-slate-50 my-2 p-4 items-start'>
            <MdPhoneCallback className='text-[#027d9b]' size={20} /> 
            <div className='ml-2'>
              <h6 className='font_bold text-[14px] 2xl:text-[17px]'>Phone Number</h6>
              <h6 className='font_normal text-[13px] 2xl:text-[16px]'>{mobile} / {mobile1}</h6>
            </div>
          </div>

          <div data-aos="fade-bottom" data-aos-delay="200" className='flex  bg-slate-50 my-2 p-4 items-start'>
            <BsMailbox2 className='text-[#027d9b]' size={20} /> 
            <div className='ml-2'>
              <h6 className='font_bold text-[14px] 2xl:text-[17px]'>Email Address</h6>
              <h6 className='font_normal text-[13px] 2xl:text-[16px]'>{email}</h6>
            </div>
          </div>

          <div data-aos="fade-bottom" data-aos-delay="300" className='flex  bg-slate-50 my-2 p-4 items-start'>
            <FaLocationDot className='text-[#027d9b]' size={24} /> 
            <div className='ml-2'>
              <h6 className='font_bold text-[14px] 2xl:text-[17px]'>Location</h6>
              <h6 className='font_normal text-[13px] 2xl:text-[16px]'>{address}</h6>
            </div>
          </div>
         
          </div>

        </div>

        <div className='md:w-[70%]'>
        <div data-aos="fade-left" className='w-[100%] md:h-[400px] 2xl:h-[650px] overflow-hidden'>
        <video autoPlay={true} loop controls={false} muted={true} src={`https://fidecrmfiles.s3.amazonaws.com/project_videos/commercial_interior.mp4`} />  
        {/* <img className='w-[100%] md:h-[400px] 2xl:h-[650px] object-cover overflow-hidden hover:scale-110 duration-300' src={service10Img} />     */}
        </div>
        <h6 data-aos="fade-bottom"  className='text-[18px] mt-5 text-[17px] 2xl:text-[20px] mb-4 font_bold'>Commercial Interior/ Industrial Design & Architecture</h6>
         
        <h6 data-aos="fade-bottom" className='font_normal text-[15px] 2xl:text-[18px] mb-2'>"The details are not the details. They make the design." - Charles Eames. Creating tailored spaces that anticipate users' needs relies on scale, functionality, ventilation and comfort. However, the past few decades have proved that the visual appeal of a project is also a fundamental aspect and can make or break the interior space.</h6> 
        <h6 data-aos="fade-bottom" className='font_normal text-[15px] 2xl:text-[18px] mb-2'>Being at the forefront of commercial interior designers in Bangalore for over a decade, Fidelitus is a firm that showcases its expertise in architectural construction for Corporate Interior Offices. We offer turnkey solutions for commercial interiors and various other solutions to suit a client's requirements.</h6> 

        <h6 data-aos="fade-bottom" className='text-[18px] 2xl:text-[21px] font_bold'>Our Take On Industrial Design & Architecture</h6>

        <h6 data-aos="fade-bottom" className='font_normal text-[15px] 2xl:text-[18px] mb-2'>During the course of our work, we deal with several renowned international architects. This gives us the opportunity to exchange knowledge, learn from their experiences and specialize in all aspects of commercial interiors and industrial building designs.</h6> 
        <h6 data-aos="fade-bottom" className='font_normal text-[15px] 2xl:text-[18px] mb-2'>The work we have completed in industrial architecture design allows us to position ourselves as one of the top Industrial Architects in Bangalore, Karnataka & India. We have successfully delivered multiple industrial projects and have always excelled in the required project deliverables such as cost, time, and quality. We provide customized solutions to all our clients enabling us to convert their ideas into reality.</h6> 
        <h6 data-aos="fade-bottom" className='font_normal text-[15px] 2xl:text-[18px] mb-2'>With extensive exposure in the field of Industrial Building Architectural Design, we provide our clients with workable and practical design solutions. At Fidelitus Projects, we help you achieve your desired budget while getting the highest quality product on time by recommending the best-in-class options.</h6> 
        <h6 data-aos="fade-bottom" className='font_normal text-[15px] 2xl:text-[18px] mb-2'>Fidelitus projects are also branching out to civil construction, warehouses, PEB structures, and Logistics parks as a new addition. As the leading commercial interior designer in Bangalore, Fidelitus has catered to over 250+ clients with over one million square feet of projects completed. Visit Fidelitus Projects in Bangalore for a free consultation, or book a free consultation online to sit back, relax, and watch your dream home come to life</h6> 

        </div> 
        </div> 
    </div>
  )
}

export default CommercialInterior