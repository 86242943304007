import React from 'react'
import { service13Img } from '../constants/AppConstant'
import { address, email, mobile, mobile1, ourService, } from '../constants/AppConstant'
import { useLocation, useNavigate } from 'react-router-dom'
import { MdPhoneCallback } from "react-icons/md";
import { BsMailbox2 } from "react-icons/bs";
import { FaLocationDot } from "react-icons/fa6";


function TurnkeyProjectExecution() {
  const {pathname} = useLocation()
  const navigator = useNavigate()

  return (
    <div>
        <div className='relative flex items-center justify-center bg-construction_bg bg-center bg-cover h-[200px]  md:h-[340px] 2xl:h-[500px]'>
            <div className='z-10'>
              <h6 className='text-[25px] md:text-[30px] text-white text-center tracking-[10px] font_thicker opacity-80 2xl:text-[35px]'>Project Exceution & Contracting</h6>
              <h6 className='text-center font_normal text-white opacity-100 text-[14px] 2xl:text-[18px]'> <span onClick={()=>navigator('/')} className='relative cursor-pointer after:bg-[#fff] after:absolute after:h-[2px] after:w-0 after:bottom-0 after:left-0  text-white opacity-100  hover:after:w-full after:transition-all after:duration-300'>Home</span> / Project Execution & Contracting </h6>
              <h6 className='text-[13px] mt-1  text-white text-center font_normal opacity-80 2xl:text-[16px]'>Would like to know more about our Turnkey Project Execution & General Contracting. </h6>
            </div>
            <div className='bg-black opacity-60 absolute top-0 left-0 w-full h-[200px]  md:h-[340px] 2xl:h-[500px]'>
            </div> 
         </div>

        <div className='flex-col-reverse flex md:flex-row w-[90%] md:w-[80%] mx-auto py-[5%]'>

        <div className='md:w-[30%] mt-10 md:mt-0'>
          
          <div className='md:w-[90%] border border-slate-100 p-6'>
          <div className='border-b-[2px] pb-2 mb-5 border-slate-100'>
          <h6  data-aos="fade-right" className='font_bold text-[15px] 2xl:text-[18px]'>Our Services</h6>  
          </div>
          {ourService?.map((s)=>(
            <h6 data-aos-delay="300" data-aos="fade-bottom" onClick={()=>navigator(s?.path)} className={`font_bold cursor-pointer text-[15px] 2xl:text-[18px]  my-2 p-4  ${s?.path === pathname ? 'bg-[#027d9b] text-white' : 'bg-slate-50'}`}>{s?.name} </h6>
          ))}
          </div>

          <div className='md:w-[90%] border border-slate-100 p-6 mt-5'>
          <div className='border-b-[2px] pb-2 mb-5 border-slate-100'>
          <h6  data-aos="fade-right" className='font_bold text-[15px] 2xl:text-[18px]'>Contact</h6>  
          </div>

          <div data-aos="fade-bottom" data-aos-delay="100" className='flex  bg-slate-50 my-2 p-4 items-start'>
            <MdPhoneCallback className='text-[#027d9b]' size={20} /> 
            <div className='ml-2'>
              <h6 className='font_bold text-[14px] 2xl:text-[17px]'>Phone Number</h6>
              <h6 className='font_normal text-[13px] 2xl:text-[16px]'>{mobile} / {mobile1}</h6>
            </div>
          </div>

          <div data-aos="fade-bottom" data-aos-delay="200" className='flex  bg-slate-50 my-2 p-4 items-start'>
            <BsMailbox2 className='text-[#027d9b]' size={20} /> 
            <div className='ml-2'>
              <h6 className='font_bold text-[14px] 2xl:text-[17px]'>Email Address</h6>
              <h6 className='font_normal text-[13px] 2xl:text-[16px]'>{email}</h6>
            </div>
          </div>

          <div data-aos="fade-bottom" data-aos-delay="300" className='flex  bg-slate-50 my-2 p-4 items-start'>
            <FaLocationDot className='text-[#027d9b]' size={24} /> 
            <div className='ml-2'>
              <h6 className='font_bold text-[14px] 2xl:text-[17px]'>Location</h6>
              <h6 className='font_normal text-[13px] 2xl:text-[16px]'>{address}</h6>
            </div>
          </div>
         
          </div>

        </div>

        <div className='md:w-[70%]'>
        <div data-aos="fade-left" className='w-[100%] md:h-[400px] 2xl:h-[650px] overflow-hidden'>

        
        <video autoPlay={true} loop controls={false} muted={true} src={`https://fidecrmfiles.s3.amazonaws.com/project_videos/civil_structural.mp4`} />  
        {/* <img className='md:h-[400px] object-cover overflow-hidden 2xl:h-[650px] hover:scale-110 duration-300' src={service13Img} />     */}
        </div>
        <h6 data-aos="fade-bottom" className='text-[18px] 2xl:text-[21px] mt-5 font_bold'>Turnkey Project Execution and General Contracting Services</h6>
         
        <h6 data-aos="fade-bottom" className='text-[16px] 2xl:text-[19px] font_bold'>One-stop solution</h6>
        <h6 data-aos="fade-bottom" className='font_normal text-[14px] 2xl:text-[17px] mb-2'>An integrated solution where one contractor controls and manages the entire execution process. You can monitor the timelines, and receive updates from one point of contact, which is less stressful and effective utilization of services.</h6> 
        
        <h6 data-aos="fade-bottom" className='text-[16px]  2xl:text-[19px] font_bold'>On-time delivery</h6>
        <h6 data-aos="fade-bottom" className='font_normal text-[14px] 2xl:text-[17px] mb-2'>As a turnkey service provider, we would come up with a defined time schedule that helps us to manage, execute, deliver and be in complete control of our subcontractors contributing to the project which enables us to deliver the project well within the agreed schedule.</h6> 
        
        <h6 data-aos="fade-bottom" className='text-[16px]  2xl:text-[19px] font_bold'>Cost-effective Budget</h6>
        <h6 data-aos="fade-bottom" className='font_normal text-[14px] 2xl:text-[17px] mb-2'>A project management team in turnkey services will manage all the spendings on the project within the budget and It makes costs easier to track and budgets easier to analyze as we have a team of in-house vendors who feed us with real-time updates on the existing market prices.</h6> 
        </div>
    </div>
    </div>
  )
}

export default TurnkeyProjectExecution