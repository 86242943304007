import React,{useState} from 'react'
import { Select } from 'antd';
import { TiSocialFacebook } from "react-icons/ti";
import { FaLinkedinIn,FaTwitter } from "react-icons/fa6";
import { FaYoutube } from "react-icons/fa";
import { RiWhatsappFill } from "react-icons/ri";
import {BiError} from 'react-icons/bi'
import { IoIosArrowRoundForward } from "react-icons/io";

import vendor_img from '../images/contact_section/shop (1).png'
import interior_img from '../images/contact_section/interior-design (1).png'
import { useNavigate } from 'react-router-dom';
import axios from 'axios'
import { base_url, department, email, mobile } from '../constants/AppConstant';
import {message} from 'antd'


function Contact() {

  const [data,setdata] =  useState({name:'',mobile:'',email:'',requirment:'',service:''})
  const [error,seterror] =  useState({name:'',mobile:'',email:'',requirment:'',service:''})

  const navigator = useNavigate()
  const steps = [
    {label:'Commercial Interior',value:'Commercial Interior'},
    {label:'Architecural Services',value:'Architecural Services'},
    {label:'MEP / HVAC and Networking Services',value:'MEP / HVAC and Networking Services'},
    {label:'Project Evaluation and Estimation',value:'Project Evaluation and Estimation'},
    {label:'Turnkey Project Execution',value:'Turnkey Project Execution'},
    {label:'General Contracting Services',value:'General Contracting Services'},
    {label:'General Enquiry',value:'General Enquiry'}
  ]


  function handleChange(e){
    setdata({...data,[e.target.name]:e.target.value})
    seterror({...error,[e.target.name]:''})
  }

  const supportUs = [
    {id:1,icon:TiSocialFacebook,path:'',size:14},
    {id:2,icon:FaLinkedinIn,path:'',size:14},
    {id:3,icon:FaYoutube,path:'',size:14},
    {id:4,icon:RiWhatsappFill,path:'',size:14},
    {id:5,icon:FaTwitter,path:'',size:14}
  ]


 

  async function openForm(v){
    if(v == 1){
      window.open('https://docs.google.com/forms/d/e/1FAIpQLSfaq_Zw5D1uB8hQHyMFq8ofLqvphC4NuFFTHBzyVDjGudqZEA/viewform','_blank')
    }

    if(v == 2){
      window.open('https://docs.google.com/forms/d/e/1FAIpQLSeCEZF7pBsVQdCysWYapVJDEp6Jy96l9eZ_672In1x_KEDEhA/viewform','_blank')
    }

  }

  async function submitForm(){
    if(!data?.name){
      seterror({...error,name:'This Field is required *'})
    }else if(!data?.email){
      seterror({...error,email:'This Field is required *'})
    }else if(!data?.requirment){
      seterror({...error,requirment:'This Field is required *'})
    }else{
     
      const sendData = {...data}
      sendData['requirment'] = `Service : ${data?.service}  Message : ${data?.requirment}`
      sendData['department_website'] = department
      
      await axios.post(`${base_url}clients/create`,sendData)
      .then((res)=>{
        if(res?.status === 201){
          message.success('Form Submitted Successfully')
          setdata({name:'',mobile:'',email:'',requirment:'',department_website:'',service:''})
          seterror({name:'',mobile:'',email:'',requirment:'',department_website:'',service:''})
        }
      }).catch((err)=>{      
      }) 
    }
  }



  return (
    <div className='w-screen overflow-x-hidden'>
        <div className='relative flex items-center justify-center bg-entrance_bg bg-blogs_bg bg-center bg-cover h-[200px]  md:h-[340px] 2xl:h-[500px]'>
            <div className='z-10'>
              <h6 className='text-[30px] text-white text-center tracking-[10px] font_thicker opacity-80 2xl:text-[35px]'>Contact Us</h6>
              <h6 className='text-center font_normal text-white opacity-100 text-[14px] 2xl:text-[18px]'> <span onClick={()=>navigator('/')} className='relative cursor-pointer after:bg-[#fff] after:absolute after:h-[2px] after:w-0 after:bottom-0 after:left-0  text-white opacity-100  hover:after:w-full after:transition-all after:duration-300'>Home</span> / Contact Us </h6>
              <h6 className='text-[13px] mt-1  text-white text-center font_normal opacity-80 2xl:text-[16px]'>We Would love to meet you in personal to understant more about your requirment. </h6>
            </div>
            <div className='bg-black opacity-60 absolute top-0 left-0 w-full h-[200px]  md:h-[340px] 2xl:text-[35px]  2xl:h-[500px]'>
            </div> 
        </div>

        <div>

        <h6 data-aos="fade-left"  className='text-center md:w-[35%] text-[19px] 2xl:text-[22px] z-20 mt-20 text-[25px] mx-auto font_bold'>Send Us <span className='text-[#027d9b]'>Your </span> Enquiry!</h6>

        <h6 data-aos="fade-top"  className='font_normal text-[13.5px] mt-2 w-[90%] md:w-[70%] 2xl:text-[16.5px] text-center mx-auto'>Your needs are at the heart of everything we do. With a commitment to excellence, our experienced team is dedicated to providing you with unparalleled service and customized solutions, designed to meet your unique requirements. Trust us to be your partner in success.</h6>



        <div className='w-[90%] md:w-[70%] md:flex mx-auto my-[2%]'>

          <div className='md:w-[50%]'>
            <div className='w-[100%]'>
             <h6 data-aos="fade-left" className='font-normal text-[14px] 2xl:text-[17px] text-[#e87818] border-b border-slate-200 mb-2 pb-2 mt-4'>Our Location</h6> 
             <iframe data-aos="fade-right" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15555.204099310882!2d77.5725222!3d12.9205065!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae15d15323916d%3A0xf76614a118f8d2c2!2sFidelitus%20Projects!5e0!3m2!1sen!2sin!4v1714021174172!5m2!1sen!2sin" width="600" className='w-[100%]' height="250"  allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
            </div>
            <div data-aos="fade-left">
            <h6 className='font-normal text-[14px] 2xl:text-[18px] mt-4 text-[#e87818] border-b border-slate-200 mb-2 pb-2'>Our Address</h6> 
            <h6 className='text-[13px] 2xl:text-[16px] font_normal mt-2'>Address</h6>
            <h6 className='text-[13px] 2xl:text-[16px] font_bold text-[#027d9b]'>Fidelitus Projects, Brigade Software Park., No. 42, Ground Floor, B Block, 27th Cross,BSK 2nd Stage, Bangalore - 560070, Karnataka</h6>
            <h6 className='text-[13px] 2xl:text-[16px] font_normal mt-2'>Phone Number</h6>
            <h6 className='text-[13px] 2xl:text-[16px] font_bold text-[#027d9b]'>{mobile}</h6>
            <h6 className='text-[13px] 2xl:text-[16px] font_normal mt-2'>Email Address</h6>
            <h6 className='text-[13px] 2xl:text-[16px] font_bold text-[#027d9b]'>{email}</h6>
            <h6 className='text-[13px] 2xl:text-[16px] font_normal mt-2'>Follow Us</h6>
            <div data-aos="fade-up" className='flex items-center mt-2 -ml-2'>
            {supportUs?.map((s)=>(
                    <span className='hover:bg-white p-[4px] text-gray-300 hover:text-[#027d9b] scale-110 duration-300 rounded mr-[5px]'>
                    <s.icon  size={s?.size}   />
                    </span>
                ))} 
            </div>
          </div>
          </div>


          <div  className='md:pl-[5%] md:w-[50%]'>
            <h6 data-aos="fade-left" className='font-normal text-[14px] 2xl:text-[18px] text-[#e87818] border-b border-slate-200 mb-2 pb-2 mt-4'>Enquiry Form</h6> 

            <div data-aos="fade-up" className='flex items-center'>
              <div className='w-[48%] mr-[2%]'>
                <h6 className='text-[12px] 2xl:text-[15px] font_bold font-[900]'>Name</h6>
                <input value={data?.name} name="name" onChange={handleChange} className='border w-full p-1 mt-1 pl-4 text-[14px] py-1.5  focus:outline-0' />
                {error?.name && <h6 className='flex  items-center text-[10px] text-red-600 mt-0.5'><BiError className='mr-1'/> {error?.name}</h6>}
              </div> 
              <div className='w-[48%] ml-[2%]'>
                <h6 className='text-[12px] 2xl:text-[15px] font_bold font-[900]'>Mobile</h6>
                <input value={data?.mobile} name="mobile" onChange={handleChange} className='border w-full p-1 mt-1 pl-4 text-[14px] py-1.5 2xl:text-[17px]  focus:outline-0' />
                {error?.mobile && <h6 className='flex items-center text-[10px] 2xl:text-[13px] text-red-600 mt-0.5'><BiError className='mr-1'/> {error?.mobile}</h6>}
              </div> 
            </div> 

             <div className='w-[100%] mt-[2%]'>
                <h6 className='text-[12px] 2xl:text-[15px] font_bold font-[900]'>Email</h6>
                <input value={data?.email} name="email" type="email" onChange={handleChange} className='border w-full p-1 mt-1 pl-4 text-[14px] py-1.5 2xl:text-[17px] focus:outline-0' />
                {error?.email && <h6 className='flex items-center text-[10px] 2xl:text-[13px] text-red-600 mt-0.5'><BiError className='mr-1'/> {error?.email}</h6>}
             </div>  

             <div className='w-[100%] mt-[2%]'>
                <h6 className='text-[12px] 2xl:text-[15px] font_bold font-[900]'>Service</h6>
                <div className='border w-full p-1 mt-1  text-[12px] py-1.5  focus:outline-0' >
                <Select value={data?.service} options={steps} onChange={(v)=>setdata({...data,service:v})} bordered={false} className='w-full h-[18px]'/>
                </div>
                {error?.email && <h6 className='flex items-center text-[10px] 2xl:text-[13px] text-red-600 mt-0.5'><BiError className='mr-1'/> {error?.email}</h6>}
             </div>  

             <h6 className='text-[12px] 2xl:text-[15px] font_bold font-[900] mt-1'>Message</h6>
              <textarea value={data?.requirment} name="requirment" onChange={handleChange} className='border w-full p-1 mt-1 pl-4 text-[14px] 2xl:text-[17px] py-1.5  focus:outline-0' />
              {error?.requirment && <h6 className='flex items-center text-[10px] 2xl:text-[13px] text-red-600 mt-0.5'><BiError className='mr-1'/> {error?.requirment}</h6>}

              <h6 onClick={submitForm} className='transition w-max text-center mt-4 delay-300 duration-300 ease-in-out  border border-[#e87818] text-[#e87818] hover:text-[#fff]  py-[5px] px-3 cursor-pointer hover:bg-[#e87818] hover:text-white font-[300] font_thick text-[13px] 2xl:text-[15px]'>CONTACT US</h6>

          </div>
        </div>


        <div className='bg-dots_bg bg-contain bg-no-repeat bg-right bottom'>
        <div className='w-[90%] md:w-[70%] md:flex justify-between mx-auto border-t py-10 mt-5 border-slate-100 my-[2%]'>
          
          <div data-aos="fade-right" className='md:w-[40%] mr-[2%] p-2'>

            <div className='flex items-center'>
              <h6 className='w-[50px] border-b-[2px] rounded-[10px] border-[#027d9b]'></h6>
              <h6 className='text-[10px] 2xl:text-[13px] font_bold text-[#027d9b] ml-2'>LET'S CONNECT</h6>
            </div>
          <h6 className='font_bold text-[20px] 2xl:text-[23px]'>Always happy to hear <span className='text-[#027d9b]'>from you</span></h6>
          <h6 className='font_normal text-[13.5px] 2xl:text-[16.5px] mt-2'>A capable group prepared to address all of your inquiries with ease and without requiring any effort on the part of you</h6>

          </div>

          <div data-aos="fade-top" className='mt-10 bg-white md:mt-0 md:w-[30%] flex flex-col items-center justify-center mr-[2%] border p-4 border-slate-100 shadow-md'>
            
            <img src={vendor_img} className='w-[50px] mb-2' />
            <h6 className='font_bold text-[13.5px] 2xl:text-[16.5px] text-center'>Are you a vendor? register below and on-board with us for some exciting projects to execute.</h6>
            <h6 onClick={()=>openForm(1)} className='transition w-30 text-center mt-4 delay-300 duration-300 ease-in-out  border border-[#e87818] text-[#e87818] hover:text-[#fff]  py-[5px] px-3 cursor-pointer hover:bg-[#e87818] hover:text-white font-[300] font_thick text-[13px] 2xl:text-[16px]' >
              <div className='flex items-center justify-center'>
               <span>CLICK HERE</span>  
               <IoIosArrowRoundForward className='ml-2' />
              </div>
            </h6>

          </div>

          <div data-aos="fade-left"  className='mt-10 bg-white md:mt-0 md:w-[30%] flex flex-col items-center justify-center mr-[2%] border p-4 border-slate-100 shadow-md'>

            <img src={interior_img} className='w-[50px] mb-2' />
            <h6 className='font_bold text-[13.5px] 2xl:text-[16.5px] text-center'>Hello there, looking for design/ architecture ideas for your space, click here to take a quick survey.</h6>
            <h6 onClick={()=>openForm(2)} className='transition w-30 text-center mt-4 delay-300 duration-300 ease-in-out  border border-[#e87818] text-[#e87818] hover:text-[#fff]  py-[5px] px-3 cursor-pointer hover:bg-[#e87818] hover:text-white font-[300] font_thick text-[13px] 2xl:text-[16px]' >
              <div className='flex items-center justify-center'>
               <span>CLICK HERE</span>  
               <IoIosArrowRoundForward className='ml-2' />
              </div>
            </h6>
          </div>

        </div>
        </div>

        </div>

    </div>

  )
}

export default Contact