import React from 'react'
import { otherServiceArr, transaction_website_url } from '../constants/AppConstant'

function RealEstate() {
  return (
    <div className='w-[100%]'>
         <div className='relative flex items-center justify-center bg-real_estate_banner_bg bg-bottom bg-cover h-[200px]  md:h-[340px]'>
            <div className='z-10'>
              <h6 className='text-[30px] text-white text-center tracking-[10px] font_thicker opacity-80'>Real Estate Transaction</h6>
              <h6 className='text-center font_normal text-white opacity-100 text-[14px]'> <span onClick={()=>navigator('/')} className='relative cursor-pointer after:bg-[#fff] after:absolute after:h-[2px] after:w-0 after:bottom-0 after:left-0  text-white opacity-100  hover:after:w-full after:transition-all after:duration-300'>Home</span> / Real Estate Transaction </h6>
              <h6 className='text-[13px] mt-1  text-white text-center font_normal opacity-80'>Would you like have a real estate service provided by us!. </h6>
            </div>
            <div className='bg-black opacity-60 absolute top-0 left-0 w-full h-[200px]  md:h-[340px]'>
            </div> 
         </div>

        
        <div className='w-[90%] md:w-[80%]  mx-auto py-[6%]'>
        <h6 data-aos="fade-right" className='font_bold text-[20px]'>Real Estate Transaction</h6>
        <h6 data-aos="fade-left" className='font_normal  text-[14px] mb-6'>With our expertise in the Indian realty sector, we implement international standards and assure client satisfaction. Our clientele can start their business in innovative business space solutions and primed floors with workstations having state-of-the-art amenities. Strategically located with client-specific interiors, you get the best of the best with us.</h6>

        <h6 data-aos="fade-right" className='font_bold text-[20px]'>Residential services</h6>
        <h6 data-aos="fade-left" className='font_normal text-[14px] mb-6'>Fidelitus corp has a list of villas, apartments, houses for rent and sale which includes high-end villas and homes. Our portfolio can help you filter appropriately with expertise. We also offer transaction guidance further simplifying procedures in this highly competitive market.</h6>

        <h6 data-aos="fade-right" className='font_bold text-[20px]'>Land and investment sales</h6>
        <h6 data-aos="fade-left" className='font_normal text-[14px] mb-6'>The company provides investment advice and we specialize in evaluating land ownership and its sales. For best buyer advice contact us.</h6>

        <h6 data-aos="fade-right" className='font_bold text-[20px]'>Industrial and warehousing</h6>
        <h6 data-aos="fade-left" className='font_normal text-[14px] mb-6'>We have our own procedure of analyzing logistics as a result of our rich experience. As pioneers in supporting E-commerce companies set up distribution in cities, providing data to cut down costs and maximize client returns, we also have experience in dealing with large and expensive stocks. Associated with various government nodal agencies like KIADB, ELCITA, hardware, and aerospace parks for supporting our clients.</h6>

        <h6 data-aos="fade-right" className='font_bold text-[20px]'>Education sector</h6>
        <h6 data-aos="fade-left" className='font_normal text-[14px] mb-6'>Across the globe, everyone wants to focus on upgrading educational institutes resulting in demand for long-term leases, built to suit, well-equipped, economic spaces by schools, universities, etc. We provide the best options to our clients and have successfully closed many major transactions.</h6>

        <h6 data-aos="fade-right" className='font_bold text-[20px]'>Healthcare sector</h6>
        <h6 data-aos="fade-left" className='font_normal text-[14px] mb-6'>Identifying the need in the healthcare sector opting for highly equipped and economical spaces, correctly tapping into the need of medical practitioners and healthcare staff we have closed many such deals in success.</h6>
         
        
        <div className='grid grid-cols-2 mt-10 gap-4 md:grid-cols-4'> 

        {otherServiceArr?.map((o)=>(
            <div data-aos="fade-bottom" data-aos-delay="300" className='p-2 md:p-5 shadow mt-2'>
                 <img src={o?.image} className='w-[100%] h-[60px] object-contain' />
                 <h6 className='font_bold text-center text-[15px] mt-2'>{o?.heading}</h6>
                 <h6 className='text-[13px] text-center'>{o?.description}</h6>
            </div>    
        ))}

        </div>    


        <h6 data-aos="fade-bottom" className='text-[15px] mt-10 font_normal'>If you would like to get more information about the Fidelitus Real Estate Transaction visit us <a href={transaction_website_url} target='_blank' className='underline cursor-pointer font_bold text-[#027d9b]'>www.fidelitustransaction.com</a></h6>

        </div>

    </div>
  )
}

export default RealEstate