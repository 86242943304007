import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import DOMPurify from "dompurify";
import { IoIosArrowRoundBack } from "react-icons/io";
import moment from 'moment';
import { base_url, department, supportUs } from '../constants/AppConstant';
import axios from 'axios';
import {message} from 'antd'
import { GetBlogDetailServices, GetBlogServices } from '../services/BlogServices';
import toast from 'react-hot-toast';

function BlogDetail() {

  const navigator = useNavigate()
 
  const {id} = useParams()

  const [similarBlogs,setsimilarBlogs] = useState([])
  const [data,setdata] = useState({})
  const [email,setemail] = useState('')
  const [loader,setloader] = useState(true)

  useEffect(()=>{
    getDetail()
  },[id])


  async function getDetail(){

    const response1 = await GetBlogServices(1,'')

    let d = response1?.data?.datas

    let newItems = []
    for (var i = 0; i < 3; i++) {
      var idx = Math.floor(Math.random() * d.length);
      newItems.push(d[idx]);
      d.splice(idx, 1);
    }
    setsimilarBlogs(newItems)



    const data =  await GetBlogDetailServices(id)
    setloader(false)
    if(data?.data?.datas?.length > 0){
      setdata(data?.data?.datas[0])
    }else{
      toast.error("We couldn't find the blog based on your search")
      setTimeout(() => {
         navigator('/')
      }, 2000);
    }
  }



  async function openDetail(v){
    navigator(`/blogs/detail/${v?._id}`)
  }

  async function submitForm(){
    
    if(data?.email !== ''){ 
      const sendData = {email:email}
      sendData['department_website'] = department
      await axios.post(`${base_url}clients/create`,sendData)
      .then((res)=>{
        if(res?.status === 201){
          message.success('Blog Subscribed Successfully')
          setemail('')
        }
      }).catch((err)=>{      
      }) 
    }
  }

  return (
    <div>
      <div className='flex bg-[#def9ff] px-[5%] py-[14px]'>
        <div className='flex items-center cursor-pointer' onClick={()=>navigator(-1)}>
        <IoIosArrowRoundBack color='#027d9b' />
        <h6 className='text-[11px] ml-2 2xl:text-[13px] text-[#027d9b] font_bold'>Back to blogs</h6>
        </div>
      </div>

      
      {/* <div className='w-[90%] md:w-[80%] mx-auto my-[5%]'>
      <div className='flex items-center justify-center my-2'>
          <h6 className={`font_bold text-[12px]`} style={{ color: `#${data?.color}`}}>{data?.category}</h6>
          <h6 className={`w-[6px] h-[6px] rounded-full mx-2.5`} style={{ backgroundColor: `#${data?.color}`}}></h6>
          <h6 className='font_bold text-[12px]' style={{ color: `#${data?.color}`}}>{data?.read}</h6>
      </div>

      <h6 className='font_bold text-[24px] text-center md:text-[32px] mt-2'>{data?.heading}</h6>

      <div className='flex items-center justify-center '>
          <div className='flex items-center my-2'>
          <img src={data?.written_image} alt={data?.written_image} className='w-[24px] h-[24px] rounded-full' />
          <h6 className='font_bold text-[11px] ml-2'>{data?.written_by}</h6>
          </div>
          <h6 className='font_bold ml-4 text-gray-400 text-[11px]'>{moment(data?.date)?.format('LL')}</h6>
        </div>

      </div>

      <div className='w-[100%] md:w-[100%] mx-auto'>
        <div className='md:w-[100%] my-[5%] mb-[10%]'>
        <img src={data?.img} className='w-[100%] h-[240px] md:h-full md:object-contain' />

       <div className='w-[90%] md:w-[80%] mx-auto my-[5%]'>
          <h6 className='font_normal text-[15px] mt-5'>PUBLISHED :</h6>
          <h6 className='font_normal text-[14px] my-2 mb-5'>{data?.published}</h6>


          <div dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(data?.data)}}>
          </div>
        </div>
        </div>
      </div>

      <div className='border-t w-[80%] mx-auto border-slate-200 mt-2 pt-10'>


        <h6 data-aos="fade-left" className='text-center text-[20px]  font_bold'>Similar BLogs</h6>
                  <div className='flex justify-center items-center'>
                    <h6 data-aos="fade-right" className='w-[50px] border-b-[2px] rounded-[10px] border-[#027d9b]'></h6>
                    <h6 data-aos="fade-up" data-aos-delay="300" className='text-[10px] font_bold text-[#027d9b] uppercase mx-2'>check out</h6>
                    <h6 data-aos="fade-left"className='w-[50px] border-b-[2px] rounded-[10px] border-[#027d9b]'></h6>
                  </div>
                  <h6 data-aos="fade-right" className='text-[14px] w-[90%] mx-auto font_normal text-center'>We've got the related blogs based on your selection </h6>


       <div className='grid  md:grid-cols-3 gap-4 md:gap-14 mt-14'>
       {data?.similar_blogs?.map((b)=>(
        <div data-aos="fade-right" onClick={()=>openDetail(b)} key={b?.id} className='relative group border-box overflow-hidden mb-10 cursor-pointer'>
              <div className='h-[200px] rounded-[10px] overflow-hidden'>
              <img src={b?.img} className='w-[100%] overflow-hidden group-hover:scale-110 duration-300 h-[200px] object-stretch'  />
              </div>  
              <div className='px-2'>
              <div className='flex items-center my-2'>
                <h6 className={`font_bold text-[12px]`} style={{ color: `#${b?.color}`}}>{b?.category}</h6>
                <h6 className={`w-[6px] h-[6px] rounded-full mx-2.5`} style={{ backgroundColor: `#${b?.color}`}}></h6>
                <h6 className='font_bold text-[12px]' style={{ color: `#${b?.color}`}}>{b?.read}</h6>
              </div> 

              <h6 className='font_bold line-clamp-2 text-[14px] mt-2'>{b?.heading}</h6>
              <h6 className='line-clamp-3 mt-2 text-[12px] font_normal text-gray-500'>{b?.short_description}</h6>
              <div className='flex items-center justify-between'>
                <div className='flex items-center my-2'>
                <img src={b?.written_image} alt={b?.written_image} className='w-[24px] h-[24px] rounded-full' />
                <h6 className='font_bold text-[11px] ml-2'>{b?.written_by}</h6>
                </div>
                <h6 className='font_bold text-gray-400 text-[11px]'>{moment(b?.date)?.format('LL')}</h6>
              </div> 
              </div>
        </div>
       ))}
       </div>


       <div className='bg-[#def9ff] md:flex items-center px-5 md:px-20 py-4 my-[5%] rounded-[10px]'>

        <div className='md:w-[60%]'>
        <h6 className='text-[#027d9b] w-[70%] font_bold text-[19px]'>If you would love to hear about our latest blogs and updates</h6>
        <h6 className='text-gray-400 font_bold text-[13px]'>Get blogs and our latest updates by just subscribe</h6>

        <div className='mt-2'>
          <input type="email" value={data?.email} onChange={(e)=>setdata({...data,email:e.target.value})} className='rounded focus:outline-none pl-4 text-[14px] p-[5px] placeholder:text-[12px]'  placeholder='Enter your mail id' />
          <button onClick={submitForm} className='ml-2 bg-[#027d9b] text-white rounded text-[13px] px-3 py-[5px]'>submit</button>
        </div>
        </div> 
        <div className='mt-10 md:mt-0 w-[40%]'>
        <img src={supportUs} className='animate-bounce w-[100%] h-[200px] object-contain'  />
        </div>
        </div>

      </div> */}

      {!loader && 
      <>
      <div className='w-[90%] md:w-[80%] mx-auto my-[5%]'>
      <div className='flex items-center justify-center my-2'>
          <h6 className={`font_bold text-[12px] 2xl:text-[15px]`} style={{ color: `${data?.color}`}}>{data?.tags?.map((t)=>t.name)}</h6>
          <h6 className={`w-[6px] h-[6px] rounded-full mx-2.5`} style={{ backgroundColor: `${data?.color}`}}></h6>
          <h6 className='font_bold text-[12px] 2xl:text-[15px]' style={{ color: `${data?.color}`}}>{data?.min_read}</h6>
      </div>

      <h6 className='font_bold text-[24px] text-center md:text-[32px] mt-2'>{data?.title}</h6>

      <div className='flex items-center justify-center '>
          <div className='flex items-center my-2'>
          {(data?.written_by?.profile_photo !== null && data?.written_by?.profile_photo !== undefined) ?
            <img src={`${process.env.REACT_APP_AWS_IMAGE_URL}${data?.written_by?.profile_photo}`} className='w-[30px] 2xl:w-[35px] 2xl:h-[35px] h-[30px] object-contain bg-slate-100 rounded-full' /> 
            :
            <h6 className='w-[24px] h-[24px] 2xl:w-[29px] 2xl:h-[29px] flex items-center justify-center text-[11px] font_bold text-white rounded-full' style={{background:data?.color === undefined ? '#fafafa' : data?.color}}>{data?.written_by?.name?.slice(0,1)}</h6>
          }

          {/* <img src={data?.written_image} alt={data?.written_image} className='w-[24px] h-[24px] rounded-full' /> */}
          <h6 className='font_bold text-[11px] 2xl:text-[14px] ml-2'>{data?.written_by?.name}</h6>
          </div>
          <h6 className='font_bold ml-4 text-gray-400 text-[11px] 2xl:text-[14px]'>{moment(data?.date)?.format('LL')}</h6>
        </div>

      </div>

      <div className='w-[100%] md:w-[100%] mx-auto'>
        <div className='md:w-[100%] my-[5%] mb-[10%]'>
        <img src={`${process.env.REACT_APP_AWS_IMAGE_URL}${data?.blog_image}`} className='w-[100%] h-[240px] md:h-full md:object-contain' />

       <div className='w-[90%] md:w-[80%] mx-auto my-[5%]'>
          <h6 className='font_normal text-[15px] 2xl:text-[19px] mt-5'>PUBLISHED :</h6>
          <h6 className='font_normal text-[14px] 2xl:text-[17px] my-2 mb-5'>{data?.motive}</h6>

          <div dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(data?.content)}}>
          </div>
        </div>
        </div>
      </div>

      <div className='border-t w-[80%] mx-auto border-slate-200 mt-2 pt-10'>


        <h6 data-aos="fade-left" className='text-center text-[20px]  font_bold'>Similar BLogs</h6>
                  <div className='flex justify-center items-center'>
                    <h6 data-aos="fade-right" className='w-[50px] border-b-[2px] rounded-[10px] border-[#027d9b]'></h6>
                    <h6 data-aos="fade-up" data-aos-delay="300" className='text-[10px] font_bold text-[#027d9b] uppercase mx-2'>check out</h6>
                    <h6 data-aos="fade-left"className='w-[50px] border-b-[2px] rounded-[10px] border-[#027d9b]'></h6>
                  </div>
                  <h6 data-aos="fade-right" className='text-[14px] w-[90%] mx-auto font_normal text-center'>We've got the related blogs based on your selection </h6>


       <div className='grid  md:grid-cols-3 gap-4 md:gap-14 mt-14'>
       {similarBlogs?.map((b)=>(
        <div data-aos="fade-right" onClick={()=>openDetail(b)} key={b?.id} className='relative group border-box overflow-hidden mb-10 cursor-pointer'>
              <div className='h-[200px] 2xl:h-[250px] rounded-[10px] overflow-hidden'>
              <img src={`${process.env.REACT_APP_AWS_IMAGE_URL}${b?.blog_image}`} className='w-[100%] overflow-hidden group-hover:scale-110 duration-300 h-[200px] 2xl:h-[250px] object-stretch'  />
              </div>  
              <div className='px-2'>
              <div className='flex items-center my-2'>
                <h6 className={`font_bold text-[12px] 2xl:text-[15px]`} style={{ color: `${b?.color}`}}>{b?.tags?.map((t)=>t?.name)}</h6>
                <h6 className={`w-[6px] h-[6px] rounded-full mx-2.5`} style={{ backgroundColor: `${b?.color}`}}></h6>
                <h6 className='font_bold text-[12px] 2xl:text-[15px]' style={{ color: `${b?.color}`}}>{b?.min_read}</h6>
              </div> 

              <h6 className='font_bold line-clamp-2 text-[14px] 2xl:text-[17px] mt-2'>{b?.title}</h6>
              <h6 className='line-clamp-3 mt-2 text-[12px] 2xl:text-[15px] font_normal text-gray-500'>{b?.motive}</h6>
              <div className='flex items-center justify-between'>
                <div className='flex items-center my-2'>
                    {(b?.written_by?.profile_photo !== null && b?.written_by?.profile_photo !== undefined) ?
                      <img src={`${process.env.REACT_APP_AWS_IMAGE_URL}${b?.written_by?.profile_photo}`} className='w-[30px] h-[30px] 2xl:w-[35px] 2xl:h-[35px] rounded-full' /> 
                      :
                      <h6 className='w-[30px] h-[30px] 2xl:w-[29px] 2xl:h-[29px] flex items-center justify-center text-[11px] font_bold text-white rounded-full' style={{background:b?.color === undefined ? '#fafafa' : b?.color}}>{b?.written_by?.name?.slice(0,1)}</h6>
                    }
                <h6 className='font_bold text-[11px] 2xl:text-[14px] ml-2'>{b?.written_by?.name}</h6>
                </div>
                <h6 className='font_bold text-gray-400 text-[11px] 2xl:text-[14px]'>{moment(b?.date)?.format('LL')}</h6>
              </div> 
              </div>
        </div>
       ))}
       </div>


       <div className='bg-[#def9ff] md:flex items-center px-5 md:px-20 py-4 my-[5%] rounded-[10px]'>

        <div className='md:w-[60%]'>
        <h6 className='text-[#027d9b] w-[70%] font_bold text-[19px] 2xl:text-[22px]'>If you would love to hear about our latest blogs and updates</h6>
        <h6 className='text-gray-400 font_bold text-[13px] 2xl:text-[16px]'>Get blogs and our latest updates by just subscribe</h6>

        <div className='mt-2'>
          <input type="email" value={email} onChange={(e)=>setemail(e.target.value)} className='rounded focus:outline-none pl-4 text-[14px] 2xl:text-[17px] p-[5px] placeholder:text-[12px] 2xl:placeholder:text-[15px]'  placeholder='Enter your mail id' />
          <button onClick={submitForm} className='ml-2 bg-[#027d9b] text-white rounded text-[13px] 2xl:text-[16px] px-3 py-[5px]'>submit</button>
        </div>
        </div> 
        <div className='mt-10 md:mt-0 w-[40%]'>
        <img src={supportUs} className='animate-bounce w-[100%] h-[200px] 2xl:h-[250px] object-contain'  />
        </div>
        </div>

      </div>
      </>}




    </div>
  )
}

export default BlogDetail