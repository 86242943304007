import React, { useEffect, useState } from 'react'
import { projectArr } from '../constants/AppConstant'
import { useNavigate } from 'react-router-dom'
import { GetVideoServices } from '../services/VideoServices'
import { Pagination } from 'antd'
import { MasonryGrid,} from "@egjs/react-grid";
import Masonry, {ResponsiveMasonry} from "react-responsive-masonry"
import useEmblaCarousel from 'embla-carousel-react'
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import EmblaCarousel from '../components/carouselAnimate/EmblaCarousel'
// import 'swiper/css/navigation';
// import 'swiper/css/pagination';
// import 'swiper/css/scrollbar';

import './base.css'
import './sandbox.css'
import './embla.css'

function Projects() {

  const [emblaRef] = useEmblaCarousel()
  const navigator = useNavigate()
  const [page,setpage] = useState(1)
  const [search,setsearch] = useState({text:''})

  const [datas,setdatas] = useState({})

  useEffect(()=>{
    getData()
  },[page])

  async function getData(){
    const response = await GetVideoServices(page,search?.text)
    setdatas({...response?.data,datas:response?.data?.datas})
  }


  const OPTIONS = { dragFree: true, loop: true }
  const SLIDE_COUNT = 5
  const SLIDES = Array.from(Array(SLIDE_COUNT).keys())

  
  return (
    <div>
        <div className='relative flex items-center justify-center bg-projects_bg2 bg-center bg-cover h-[200px]  md:h-[340px] 2xl:h-[500px]'>
            <div className='z-10'>
              <h6 className='text-[30px] text-white text-center tracking-[10px] font_thicker opacity-80 2xl:text-[35px]'>Our Projects</h6>
              <h6 className='text-center font_normal text-white opacity-100 text-[14px] 2xl:text-[18px]'> <span onClick={()=>navigator('/')} className='relative cursor-pointer after:bg-[#fff] after:absolute after:h-[2px] after:w-0 after:bottom-0 after:left-0  text-white opacity-100  hover:after:w-full after:transition-all after:duration-300'>Home</span> / Our Projects </h6>
              <h6 className='text-[13px] mt-1  text-white text-center font_normal opacity-80 2xl:text-[16px]'>Take a moment  to explore our completed projects. </h6>
            </div>
            <div className='bg-black opacity-60 absolute top-0 left-0 w-full h-[200px]  md:h-[340px]  2xl:h-[500px]'>
            </div> 
         </div>


         
         <div className='w-[90%] md:w-[80%] mx-auto my-[5%]'>
           <h6 data-aos="fade-left" className='font_normal text-[15px]  2xl:text-[18px] my-10'>We have brought our  <span className='font-[800] font_bold'>client's dream</span> to life through our dedicated efforts and succeesful project completions.   </h6>


            {/* <div className='grid grid-cols-1 md:grid-cols-4 gap-5'>

              {datas?.datas?.map((p)=>(
                  <div className='group cursor-pointer h-[400px] md:h-[400px] overflow-hidden' onClick={()=>navigator(`/projects/detail/${p?._id}`)}>
                    <img src={`${process.env.REACT_APP_AWS_IMAGE_URL}${p?.banner}`} className='h-[400px] md:h-[400px] w-[100%] object-cover' /> 

                    <div className='inline-block bg-black/30 duration-300 transition-all group-hover:-translate-y-[400px] h-[400px] min-w-[100%] min-height-[100%] '>
                      <div className='transition-all duration-300 text-[#fff] '>
                        <div className='p-2 mt-2 '>  
                          <div>
                          <h6 className='font_bold text-[20px] uppercase ml-4 w-[90%]'>{p?.client}</h6>
                          <h6 className='font_normal text-[14px] ml-4 mt-4 w-[90%]'>{p?.project_name}  {p?.total_sqft !== '' &&  `/ Total SQFT - ${p?.total_sqft}`}</h6>
                          </div>
                        </div>
                      </div>
                  </div> 

                    <div>

                    </div>  
                  </div>  
                ))
              }

            </div> */}

            {/* <MasonryGrid
              className="container"
              gap={5}
              defaultDirection={"end"}
              align={"justify"}
            > */}

<ResponsiveMasonry
                columnsCountBreakPoints={{350: 1, 750: 2, 900: 3}}
            >
              
              <Masonry gutter={10} columnsCount={2}>
              {datas?.datas?.slice(0,5)?.map((p)=>(
                  <div className='group overflow-hidden relative cursor-pointer overflow-hidden' onClick={()=>navigator(`/projects/detail/${p?._id}`)}>
                    <img src={`${process.env.REACT_APP_AWS_IMAGE_URL}${p?.banner}`} className='w-[100%] h-[100%] object-cover' /> 
                    {/* <h1 className='text-[20px] bg-white p-4 absolute top-2 left-2'>{p?.sort}</h1> */}
                    <div className='bg-black/30 min-w-[100%] min-height-[100%] hidden absolute group-hover:block bottom-0'>
                      <div className='transition-all duration-300 text-[#fff] '>
                        <div className='p-2 mt-2'>  
                          <div>
                          <h6 className='font_bold text-[15px] uppercase ml-4 w-[90%]'>{p?.client}</h6>
                          <h6 className='font_normal text-[13px] ml-4 mt-2 w-[90%]'>{p?.project_name}  {p?.total_sqft !== '' &&  `/ Total SQFT - ${p?.total_sqft}`}</h6>
                          </div>
                        </div>
                      </div>
                  </div> 

                    <div>

                    </div>  
                  </div>  
                ))
              }
              </Masonry>
              </ResponsiveMasonry>

              {/* <EmblaCarousel slides={SLIDES} options={OPTIONS} /> */}

              {/* <Swiper
                // modules={[Pagination]}
                spaceBetween={50}
                slidesPerView={3}
                navigation
                pagination={{ clickable: true }}
                scrollbar={{ draggable: true }}
                onSwiper={(swiper) => console.log(swiper)}
                onSlideChange={() => console.log('slide change')}
              >

                {datas?.datas?.map((p)=>(
                <SwiperSlide>
                  <div className='group cursor-pointer h-[400px] md:h-[400px] overflow-hidden' onClick={()=>navigator(`/projects/detail/${p?._id}`)}>
                    <img src={`${process.env.REACT_APP_AWS_IMAGE_URL}${p?.banner}`} className='h-[400px] md:h-[400px] w-[100%] object-cover' /> 

                    <div className='inline-block bg-black/30 duration-300 transition-all group-hover:-translate-y-[400px] h-[400px] min-w-[100%] min-height-[100%] '>
                      <div className='transition-all duration-300 text-[#fff] '>
                        <div className='p-2 mt-2 '>  
                          <div>
                          <h6 className='font_bold text-[20px] uppercase ml-4 w-[90%]'>{p?.client}</h6>
                          <h6 className='font_normal text-[14px] ml-4 mt-4 w-[90%]'>{p?.project_name}  {p?.total_sqft !== '' &&  `/ Total SQFT - ${p?.total_sqft}`}</h6>
                          </div>
                        </div>
                      </div>
                  </div> 
                    <div>

                    </div>  
                  </div>  
                  </SwiperSlide>
                ))
              }

              </Swiper>   */}

              
            {/* </MasonryGrid> */}


            
            {(datas?.pagination?.total > datas?.pagination?.limit) &&
            <div className='mx-auto flex items-center justify-center my-[5%] w-full'>
            <Pagination size='small' onChange={(v)=>setpage(v)} total={datas?.pagination?.total} pageSize={datas?.pagination?.limit} />
            </div>}
         </div>
    </div>

  )
}

export default Projects