import React, { useState } from 'react'
import { clientsArr } from '../constants/AppConstant'
import { useEffect } from 'react'

function Clients() {

  let [datas,setdatas] = useState([])

  useEffect(()=>{
    setdatas([...clientsArr.reverse()])
  },[])
  return (
    <div>
      <div className='bg-handshake_bg relative flex items-center justify-center bg-entrance_bg bg-blogs_bg bg-left bg-contain bg-no-repeat h-[200px]  md:h-[340px] 2xl:h-[500px]'>
           <div className='z-10'>
            <h6 className='text-[30px] text-black text-center tracking-[10px] font_thicker opacity-100 2xl:text-[35px]'>Our Clients</h6>
            <h6 className='text-center font_normal text-black opacity-100 text-[14px] 2xl:text-[18px]'> <span onClick={()=>navigator('/')} className='relative cursor-pointer after:bg-[#000] after:absolute after:h-[2px] after:w-0 after:bottom-0 after:left-0  text-black opacity-100  hover:after:w-full after:transition-all after:duration-300'>Home</span> / Contact Us </h6>
            <h6 className='text-[13px] mt-1 text-center font_normal opacity-80 2xl:text-[16px]'>Meet our clients who believed in us and helped us to become what we are. </h6>
           </div>
           <div className='bg-[#027d9b] opacity-30 absolute top-0 left-0 w-full h-[200px]  md:h-[340px] 2xl:h-[500px]'>
           </div> 
        </div>


        <h6 data-aos="fade-left" className='font_normal text-[15px] mx-[5%] md:mx-[10%] mt-10 2xl:text-[18px]'>Our  <span className='font-[800] font_bold'>Served Client's</span> that we have made there dream come true  </h6>
        
        <div className='grid grid-cols-3 md:grid-cols-6 w-[90%] md:w-[80%] 2xl:w-[70%] my-10 mb-20 mx-auto'>
        {datas?.map((c,i)=>(
            <img data-aos="fade-up" src={c} key={c} className=' object-contain border-[1px] hover:scale-110 duration-300 h-[100px] 2xl:h-[150px] w-[100%] p-2 border-slate-100' />
        ))}
        </div>
    </div>
  )
}

export default Clients